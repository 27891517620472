import { Text, VStack } from '@chakra-ui/react'
import { faPlay } from '@fortawesome/pro-regular-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import ModalLayout from 'components/layouts/modal-layout'
import useCampaignActions from '../hooks/use-campaign-actions'

const ActivateCampaignModal = ({ campaign, onClose }) => {
  const { updateCampaign, isUpdating } = useCampaignActions({ campaign })

  return (
    <ModalLayout header="Activate Campaign" isOpen={true} onClose={onClose}>
      <VStack justify="center" gap={4}>
        <Text>Are you sure you want to activate this campaign?</Text>

        <OutlinedButton
          onClick={() => updateCampaign({ status: 'running' })}
          isLoading={isUpdating}
          icon={faPlay}
        >
          Activate
        </OutlinedButton>
      </VStack>
    </ModalLayout>
  )
}

export default ActivateCampaignModal
