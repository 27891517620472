import { Button, ButtonGroup, Icon } from '@chakra-ui/react'
import { faEye, faList } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSignalLeadsProps } from 'context/signal-leads-context'

const ToggleViewButtons = () => {
  const { view, selectView } = useSignalLeadsProps()

  return (
    <ButtonGroup spacing={0}>
      <StyledButton
        borderRadius="8px 0 0 8px"
        onClick={() => selectView('single')}
        isActive={view === 'single'}
        icon={faEye}
      >
        Preview
      </StyledButton>

      <StyledButton
        borderRadius="0 8px 8px 0"
        borderLeft="none"
        onClick={() => selectView('list')}
        isActive={view === 'list'}
        icon={faList}
      >
        List
      </StyledButton>
    </ButtonGroup>
  )
}

const StyledButton = ({ children, isActive, icon, ...props }) => (
  <Button
    variant="outline"
    bg={isActive ? 'gray.100' : 'none'}
    leftIcon={icon ? <Icon icon={icon} as={FontAwesomeIcon} color="#959AA4" /> : null}
    {...props}
  >
    {children}
  </Button>
)

export default ToggleViewButtons
