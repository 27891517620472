import { Flex } from '@chakra-ui/react'
import { faFire, faPaperPlane, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import ColumnButton from 'components/column-table/components/column-button'
import MetaData from 'components/meta-data'
import PageHeader from 'components/page-header'
import SelectMenu from 'components/select-menu'
import { useAuth } from 'context/auth-context'
import { useCurrentOrg } from 'context/current-organization'
import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import Api from 'utils/api'
import Storage from 'utils/storage'
import uid from 'utils/uid'
import InboxesTable from './components/inboxes-table'

const columns = [
  { id: uid(), title: 'Inbox' },
  { id: uid(), title: 'Account' },
  { id: uid(), title: 'Mode' },
  { id: uid(), title: 'Type' },
  { id: uid(), title: 'Connections' },
  { id: uid(), title: 'Age' },
  { id: uid(), title: 'Reply Rate' },
  { id: uid(), title: 'Open Rate' },
  { id: uid(), title: 'Glock Score' },
]

const statusOptions = [
  { title: 'All Status', value: null },
  { value: 'Divider' },
  { title: 'Running', value: 'running' },
  { title: 'Error', value: 'error' },
  { title: 'Downloading', value: 'downloading' },
  { title: 'Disconnected', value: 'disconnected' },
  { title: 'Invalid', value: 'invalid' },
]

const modeOptions = [
  { title: 'All Mode', value: null },
  { value: 'Divider' },
  { title: 'Outreach', value: 'outreach', icon: faPaperPlane, color: '#959AA4' },
  { title: 'Warm', value: 'warm', icon: faFire, color: '#F8C73D' },
  { title: 'Monitor', value: 'monitor', icon: faTriangleExclamation, color: '#F8C73D' },
]

const InboxesPage = () => {
  const { currentOrgId } = useCurrentOrg()
  const { isAdmin, organizationId } = useAuth()

  const [status, setStatus] = useState(() => Storage.get('inbox-table-status') || statusOptions[0])
  const [mode, setMode] = useState(() => Storage.get('inbox-table-mode') || modeOptions[0])

  const selectStatus = useCallback((status) => {
    setStatus(status)
    Storage.set('inbox-table-status', status)
  }, [])

  const selectMode = useCallback((mode) => {
    setMode(mode)
    Storage.set('inbox-table-mode', mode)
  }, [])

  const { data, isFetching } = useQuery(['inboxes', status, mode], () =>
    Api.get('inboxes', {
      status: status?.value ?? null,
      mode: mode?.value ?? null,
      organization_id: currentOrgId ?? (isAdmin ? null : organizationId),
      connected: true,
      limit: 500,
    }),
  )

  return (
    <>
      <MetaData title="Inboxes" />

      <PageHeader title="Inboxes" />

      <InboxesTable
        data={data?.results}
        isLoading={isFetching}
        columns={columns}
        header={
          <Flex gap={3}>
            <ColumnButton isDisabled />
            <SelectMenu selected={status} options={statusOptions} handleSelect={selectStatus} type="status" />
            <SelectMenu selected={mode} options={modeOptions} handleSelect={selectMode} type="icon" />
          </Flex>
        }
      />
    </>
  )
}

export default InboxesPage
