export const normalizedTypes = {
  text: 'Keyword',
  string: 'Keyword',
  tag: 'Tag',
  enum: 'Tag',
  number: 'Number',
  months: 'Months',
  days: 'Days',
}

export const isNumberType = (type) => ['number', 'days', 'months'].includes(type)

export const isEnumOrTagType = (type) => ['tag', 'enum'].includes(type)

export const isMissingOrExists = (action) => ['exists', 'missing'].includes(action)

export const setFilterType = (resource, type) => {
  const normalizedColumnTypes = {
    companies: 'company',
    persons2: 'person',
    positions: 'position',
    fired: 'fired',
    emails2: 'email',
    campaigns: 'campaign',
    inboxes: 'inbox',
  }
  if (resource === type) return null
  return normalizedColumnTypes[resource]
}

export const getQueryFilterOptions = (type) => {
  if (isEnumOrTagType(type)) {
    return [
      { title: 'Any', value: 'includes_any' },
      { title: 'All', value: 'includes_all' },
      { title: 'Exclude', value: 'excludes' },
    ]
  }
  if (['text', 'string'].includes(type)) {
    return [
      { title: 'Any', value: 'includes_any' },
      { title: 'All', value: 'includes_all' },
      { title: 'Exclude', value: 'excludes' },
      { title: 'Exists', value: 'exists' },
      { title: 'Missing', value: 'missing' },
    ]
  }
  return [
    { title: 'Greater Than', value: 'greater_than' },
    { title: 'Less Than', value: 'less_than' },
    { title: 'Equals', value: 'equals' },
    { title: 'Exists', value: 'exists' },
    { title: 'Missing', value: 'missing' },
  ]
}
