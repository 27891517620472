import { Button, HStack, Icon, Input, Td, Text, Tr } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SubPageLayout from 'components/layouts/sub-page-layout'
import TableLayout from 'components/layouts/table-layout'
import { SignalIcon } from 'features/signal/constants/signal-icons'
import useDebounce from 'hooks/use-debounce'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import Api from 'utils/api'
import uid from 'utils/uid'
import TemplateDetailsModal from '../components/template-details-modal'

const columns = [
  { id: uid(), title: 'Name' },
  { id: uid(), title: 'Type' },
]

const SignalTemplate = () => {
  const [templateModal, setTemplateModal] = useState({ isOpen: false, signal: null })

  const [searchTerm, setSearchTerm] = useSearchParams({ input: '' })

  const inputValue = searchTerm.get('input')

  const debouncedSearchterm = useDebounce(inputValue, 250)

  const { data: signals, isLoading } = useQuery(['templates', debouncedSearchterm], () =>
    Api.get('signal/template-search', { search: debouncedSearchterm || null }),
  )

  const onSearch = (e) => {
    setSearchTerm(
      (prev) => {
        prev.set('input', e.target.value)
        return prev
      },
      { replace: true },
    )
  }

  return (
    <>
      <SubPageLayout
        title="Signal Templates"
        description="Start with one of our pre-built templates to get going quickly."
      >
        <TableLayout
          columns={columns}
          isLoading={isLoading}
          minW={{ base: '100%', md: 800 }}
          header={
            <Input
              type="text"
              placeholder="Search Templates"
              aria-label="Search Templates Input"
              value={inputValue}
              onChange={onSearch}
            />
          }
        >
          {signals?.results?.length === 0 ? (
            <Tr>
              <Td>
                <Text color="muted">No results found...</Text>
              </Td>
            </Tr>
          ) : (
            signals?.results.map((signal) => (
              <Tr key={signal.id}>
                <Td>
                  <Button
                    variant="link"
                    color="blue.500"
                    textDecoration="underline"
                    fontWeight={400}
                    _hover={{ textDecoration: 'underline', color: 'blue.600' }}
                    onClick={() => setTemplateModal({ isOpen: true, signal })}
                  >
                    {signal?.name}
                  </Button>
                </Td>
                <Td w={60}>
                  <HStack>
                    <Icon icon={SignalIcon[signal.query_type]} as={FontAwesomeIcon} h={5} w={6} />
                    <Text color="muted" fontSize={16} fontWeight={600}>
                      {signal.query_type_display}
                    </Text>
                  </HStack>
                </Td>
              </Tr>
            ))
          )}
        </TableLayout>
      </SubPageLayout>

      {templateModal.isOpen && (
        <TemplateDetailsModal
          signal={templateModal.signal}
          isOpen={templateModal.isOpen}
          onClose={() => setTemplateModal({ isOpen: false, signal: null })}
        />
      )}
    </>
  )
}

export default SignalTemplate
