import { FormControl, FormLabel, Input, Text, Textarea } from '@chakra-ui/react'
import StandardButton from 'components/buttons/standard-button'
import SubPageLayout from 'components/layouts/sub-page-layout'
import WhiteSurface from 'components/white-surface'
import { useState } from 'react'
import useCreateSignal from '../hooks/use-create-signal'
import useImportDomains from '../hooks/use-import-domains'

const ImportDomains = () => {
  const [signalName, setName] = useState('')
  const [domains, setDomains] = useState('')

  const { importDomains } = useImportDomains()

  const { createSignal, isLoading } = useCreateSignal()

  const onSubmit = async (e) => {
    e.preventDefault()
    const data = await createSignal({ signalName, queryType: 'import_domains', prospecting: false })
    if (domains) {
      importDomains({ signal_id: data?.id, text: domains })
    }
  }

  return (
    <SubPageLayout
      title="Import Domains"
      description="Import a list of domains that can be enriched and prospected for contacts."
    >
      <WhiteSurface p={4} minW={{ base: '100%', md: 557 }}>
        <form onSubmit={onSubmit}>
          <FormControl isRequired>
            <FormLabel> Signal Name</FormLabel>
            <Input
              type="text"
              placeholder="Custom Import List"
              value={signalName}
              onChange={(e) => setName(e.target.value)}
              mb="34px"
              autoFocus
            />
          </FormControl>

          <FormLabel>Domains</FormLabel>
          <Textarea
            placeholder="hellooutbound.com&#10;google.com&#10;hubspot.com&#10;"
            value={domains}
            onChange={(e) => setDomains(e.target.value)}
            color="#4A5568"
            h={123}
          />
          <Text mb="34px" fontSize={14} mt={1} color="muted">
            Copy / Paste your domains, one per a line, into this text box.
          </Text>

          <StandardButton type="submit" w="100%" tabIndex="2" isLoading={isLoading}>
            Create Signal
          </StandardButton>
        </form>
      </WhiteSurface>
    </SubPageLayout>
  )
}

export default ImportDomains
