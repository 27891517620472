import PageHeader from 'components/page-header'
import CampaignLeadsTable from '../components/campaign-leads-table'

const BookedLeads = () => {
  return (
    <>
      <PageHeader title="Booked Leads" description="Leads that have booked a meeting." />
      <CampaignLeadsTable type="booked" />
    </>
  )
}

export default BookedLeads
