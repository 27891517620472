import useInvalidateQuery from 'hooks/use-invalidate-query'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

const useUpdateCampaignLead = () => {
  const { leadId } = useParams()

  const invalidate = useInvalidateQuery()

  const { mutate: updateLead, isLoading: isUpdatingLead } = useMutation(
    (payload) => Api.post(`campaign-leads/${leadId}`, payload),
    { onSuccess: () => invalidate('campaign-lead') },
  )

  return { updateLead, isUpdatingLead }
}

export default useUpdateCampaignLead
