import { Text, VStack } from '@chakra-ui/react'
import DeleteButton from 'components/buttons/delete-button'
import ModalLayout from 'components/layouts/modal-layout'
import useCampaignActions from '../hooks/use-campaign-actions'

const DeleteCampaignModal = ({ campaign, onClose }) => {
  const { deleteCampaign, isDeleting } = useCampaignActions({ campaign })

  return (
    <ModalLayout header="Delete Campaign" isOpen={true} onClose={onClose}>
      <VStack justify="center" gap={4}>
        <Text>Are you sure you want to delete this campaign?</Text>

        <DeleteButton onClick={deleteCampaign} isLoading={isDeleting}>
          Delete
        </DeleteButton>
      </VStack>
    </ModalLayout>
  )
}

export default DeleteCampaignModal
