import { Link } from '@chakra-ui/react'
import { Link as ReactLink } from 'react-router-dom'

const UnderlineLink = ({ path, text, ...props }) => (
  <Link as={ReactLink} to={path} color="blue.500" textDecoration="underline" {...props}>
    {text}
  </Link>
)

export default UnderlineLink
