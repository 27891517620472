import { Button, Checkbox, HStack, Icon, Image, Link, Td, Text, Wrap } from '@chakra-ui/react'
import { faBuilding } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from 'context/auth-context'
import { useState } from 'react'
import { Link as ReactLink, useLocation } from 'react-router-dom'
import getCompanyImage from 'utils/get-company-image'
import { checkAndFormatString, displayCustomCompanyCell, displayLinkCell } from '../utils'
import EditableTableCell from './editable-table-cell'

const RenderTableCell = ({ col, lead, type, selectItem, selectedItems }) => {
  const { isAdmin } = useAuth()
  const { pathname } = useLocation()

  const { filterType, display } = col
  const value = lead?.[filterType] ? lead?.[filterType]?.[display] : lead[display]

  if (display === 'checkbox') {
    return (
      <Td w={0} onClick={(e) => e.stopPropagation()}>
        <HStack>
          <Button
            onClick={(e) => selectItem([lead], false, e)}
            p={0}
            minW={0}
            bg="none"
            _hover={{ bg: 'none' }}
            _focus={{ boxShadow: 'none' }}
          >
            <Checkbox
              isChecked={selectedItems.some(({ id }) => id === lead.id)}
              size="lg"
              iconColor="white"
              isDisabled
              sx={{
                'label:disabled:checked ~ &': {
                  cursor: 'pointer',
                },
              }}
            />
          </Button>
        </HStack>
      </Td>
    )
  }

  if (Array.isArray(value)) {
    return (
      <Td>
        <Wrap gap={3} minW={value.length > 4 && 300}>
          {value?.map((item, index) => (
            <Text color="muted" key={index}>
              {item}
              {index < value.length - 1 ? ',' : ''}
            </Text>
          ))}
        </Wrap>
      </Td>
    )
  }

  if (display?.startsWith('variable')) {
    return <EditableTableCell value={value} display={display} endpoint={`signal-leads/${lead.id}`} />
  }

  if ((display === 'fname' || display === 'lname') && lead.person_id) {
    return <EditableTableCell value={value} display={display} endpoint={`persons2/${lead.person_id}`} />
  }

  if (display === 'name' && isAdmin && !pathname.includes('database')) {
    const company = type === 'signal' ? lead.company : lead
    return <EditableTableCell value={company.name} display="name" endpoint={`companies/${company.id}`} />
  }

  if (displayLinkCell(col, value)) {
    return (
      <Td textAlign="start">
        <Link
          href={`https://${value}`}
          color="#3182CE"
          isExternal
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          {value}
        </Link>
      </Td>
    )
  }

  if (col.display === 'linkedin') {
    return (
      <Td textAlign="start">
        <Link
          href={`https://www.linkedin.com/${value}`}
          color="#3182CE"
          isExternal
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          {value}
        </Link>
      </Td>
    )
  }

  if (col.display === 'github') {
    return (
      <Td textAlign="start">
        <Link
          href={`https://github.com/${value}`}
          color="#3182CE"
          isExternal
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          {value}
        </Link>
      </Td>
    )
  }

  if (displayCustomCompanyCell(type, col.display)) {
    const value = type === 'signal' ? lead.company : lead

    const [isImageLoaded, setIsImageLoaded] = useState(true)

    return (
      <Td minW={300}>
        <HStack spacing={3}>
          {isImageLoaded ? (
            <Image
              src={getCompanyImage(value.domain)}
              width={5}
              borderRadius={4}
              alt="Company logo"
              onLoad={() => setIsImageLoaded(true)}
              onError={() => setIsImageLoaded(false)}
            />
          ) : (
            <Icon icon={faBuilding} as={FontAwesomeIcon} w={5} h={5} />
          )}
          <Link
            as={ReactLink}
            to={`/companies/${value?.id}`}
            fontWeight={500}
            whiteSpace="pre-line"
            target="_blank"
            onClick={(e) => e.stopPropagation()}
          >
            {value.name}
          </Link>
        </HStack>
      </Td>
    )
  }

  return (
    <Td textAlign="start">
      <Text color="muted" whiteSpace="pre-line">
        {checkAndFormatString(value)}
      </Text>
    </Td>
  )
}

export default RenderTableCell
