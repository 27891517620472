import {
  Avatar,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faClose, faEnvelope, faEnvelopes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OutlinedButton from 'components/buttons/outlined-button'
import ColorBox from 'components/colorful-box'
import TableLayout from 'components/layouts/table-layout'
import SelectMenu from 'components/select-menu'
import StatusDot from 'components/status-dot'
import useChannelOptions, { channelIcons } from 'hooks/dropdown-filters/use-channel-options'
import useUserOptions from 'hooks/dropdown-filters/use-user-options'
import useDebounce from 'hooks/use-debounce'
import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import capitalize from 'utils/capitalize'
import Storage from 'utils/storage'
import CampaignActionDropdown from './campaign-action-dropdown'
import SyncCampaignsButton from './sync-campaigns-button'

const types = {
  default: faEnvelope,
  quickmail: faEnvelopes,
  skylead: faLinkedin,
}

const statusOptions = [
  { title: 'All Status', value: null },
  { value: 'Divider' },
  { title: 'Active', value: 'running' },
  { title: 'Archived', value: 'archived' },
]

const typeOptions = [
  { title: 'All Types', value: null },
  { value: 'Divider' },
  { title: 'Email', value: 'default', icon: types.default },
  { title: 'Skylead', value: 'skylead', icon: types.skylead },
  { title: 'Quickmail', value: 'quickmail', icon: types.quickmail },
]

const CampaignsTable = ({ columns, onModal, selectCampaign, selectedCampaign }) => {
  let fetchLimit = 50
  let fetchStatus = null
  let saveSelectedFilter = true
  let tableStyle = null

  if (onModal) {
    fetchLimit = 10
    saveSelectedFilter = false
    fetchStatus = 'running'
    tableStyle = { border: '1px solid #E2E8F0', boxShadow: 'none' }
  }

  const { accountId } = useParams()

  const [status, setStatus] = useState(
    () => (saveSelectedFilter && Storage.get('campaigns-table-status')) || statusOptions[0],
  )

  const [type, setType] = useState(
    () => (saveSelectedFilter && Storage.get('campaigns-table-type')) || typeOptions[0],
  )

  const [searchInputValue, setSearchInputValue] = useState('')
  const dbSearchTerm = useDebounce(searchInputValue, 250)

  const [inputFocus, setInputFocus] = useState(false)

  const { user, userOptions, selectUser } = useUserOptions()

  const { channel, channelOptions, selectChannel } = useChannelOptions({
    saveKey: saveSelectedFilter && 'campaigns-table-channel',
  })

  const { data, isFetching } = useQuery(
    ['campaigns', accountId, status, type, channel, user, dbSearchTerm],
    () =>
      Api.get('campaigns', {
        account_id: accountId,
        status: fetchStatus ?? (inputFocus ? null : status?.value),
        type: inputFocus ? null : type?.value,
        channel: inputFocus ? null : channel?.value,
        user_id: inputFocus ? null : user?.userId,
        name: dbSearchTerm || null,
        limit: fetchLimit,
      }),
  )

  const selectSatus = useCallback((status) => {
    setStatus(status)
    saveSelectedFilter && Storage.set('campaigns-table-status', status)
  }, [])

  const selectType = useCallback((type) => {
    setType(type)
    saveSelectedFilter && Storage.set('campaigns-table-type', type)
  }, [])

  const clearFilters = useCallback(() => {
    selectSatus(statusOptions[0])
    selectType(typeOptions[0])
    selectChannel(channelOptions[0])
    selectUser(userOptions[0])
    setSearchInputValue('')
  }, [])

  return (
    <TableLayout
      data={data}
      columns={columns}
      isLoading={isFetching}
      style={tableStyle}
      width="100%"
      header={
        <>
          <Flex gap={3}>
            {inputFocus || (
              <>
                {onModal ? (
                  <Input
                    placeholder="Search Campaigns"
                    aria-label="Search Campaigns Input"
                    type="text"
                    value={searchInputValue}
                    onChange={(e) => setSearchInputValue(e.target.value)}
                    w={321}
                  />
                ) : (
                  <>
                    <SelectMenu
                      selected={status}
                      options={statusOptions}
                      handleSelect={selectSatus}
                      type="status"
                    />
                    <SelectMenu selected={type} options={typeOptions} handleSelect={selectType} type="icon" />
                  </>
                )}
                <SelectMenu
                  selected={channel}
                  options={channelOptions}
                  handleSelect={selectChannel}
                  type="icon"
                />
                <SelectMenu selected={user} options={userOptions} handleSelect={selectUser} type="avatar" />
                {onModal || <OutlinedButton onClick={clearFilters}>Clear</OutlinedButton>}
              </>
            )}
          </Flex>

          {onModal ? (
            <SyncCampaignsButton />
          ) : (
            <InputGroup w={inputFocus ? '100%' : 321}>
              <Input
                placeholder="Search Campaigns"
                aria-label="Search Campaigns Input"
                type="text"
                value={searchInputValue}
                onChange={(e) => setSearchInputValue(e.target.value)}
                onFocus={() => setInputFocus(true)}
              />
              <InputRightElement>
                <IconButton
                  size="sm"
                  onClick={() => {
                    setInputFocus(false)
                    setSearchInputValue('')
                  }}
                >
                  <Icon as={FontAwesomeIcon} icon={faClose} />
                </IconButton>
              </InputRightElement>
            </InputGroup>
          )}
        </>
      }
    >
      {data?.results?.map((campaign) => (
        <Tr
          key={campaign?.id}
          role="group"
          h="60px"
          _hover={{ backgroundColor: 'gray.50' }}
          transition="background-color 0.3s ease"
          cursor={selectCampaign && 'pointer'}
          onClick={() => selectCampaign && selectCampaign(campaign)}
          bg={selectedCampaign?.id === campaign.id && 'gray.50'}
        >
          {columns?.map((col) => (
            <RenderTableCell key={col.id} column={col} campaign={campaign} onModal={onModal} />
          ))}
        </Tr>
      ))}
    </TableLayout>
  )
}

const RenderTableCell = ({ column, campaign, onModal }) => {
  const { title } = column ?? {}

  if (title === 'Name') {
    return (
      <Td>
        <HStack color="#3182CE">
          <StatusDot status={campaign.status} />
          {onModal ? (
            <Text>{campaign.name}</Text>
          ) : (
            <Link
              href={campaign?.skylead_link ?? campaign?.quickmail_link}
              isExternal
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              {campaign.name}
            </Link>
          )}
        </HStack>
      </Td>
    )
  }

  if (title === '') {
    return (
      <Td p={0} minW={160}>
        <CampaignActionDropdown campaign={campaign} />
      </Td>
    )
  }

  if (title === 'Channel') {
    return (
      <Td>
        {channelIcons[campaign.channel] ? (
          <HStack color="muted">
            <Icon icon={channelIcons[campaign.channel]} h={5} w={5} as={FontAwesomeIcon} />
            <Text>{capitalize(campaign?.channel)}</Text>
          </HStack>
        ) : null}
      </Td>
    )
  }

  if (title === 'Queue') {
    return (
      <Td>
        {campaign?.outreach_report?.queue ? (
          <ColorBox color="yellow" value={campaign?.outreach_report?.queue} />
        ) : null}
      </Td>
    )
  }

  if (title === 'Contacted') {
    return (
      <Td>
        {campaign?.outreach_report?.contacted ? (
          <ColorBox color="gray" value={campaign?.outreach_report?.contacted} />
        ) : null}
      </Td>
    )
  }

  if (title === 'Opens') {
    return (
      <Td>
        {campaign?.outreach_report?.email_open_rate ? (
          <Text color="muted">{campaign?.outreach_report?.email_open_rate}%</Text>
        ) : null}
      </Td>
    )
  }

  if (title === 'Replied') {
    return (
      <Td>
        {campaign?.outreach_report?.replied ? (
          <HStack>
            <ColorBox color="gray" value={campaign?.outreach_report?.replied} />
            <Text color="muted">{campaign?.outreach_report?.reply_rate}%</Text>
          </HStack>
        ) : null}
      </Td>
    )
  }

  if (title === 'Warm') {
    return (
      <Td>
        {campaign?.outreach_report?.warm ? (
          <HStack>
            <ColorBox color="green" value={campaign?.outreach_report?.warm} />
            <Text color="muted">{campaign?.outreach_report?.reply_rate_warm}%</Text>
          </HStack>
        ) : null}
      </Td>
    )
  }

  if (title === 'Meetings') {
    return (
      <Td>
        {campaign?.outreach_report?.meetings ? (
          <HStack>
            <ColorBox color="green" value={campaign?.outreach_report?.meetings} />
            <Text color="muted">{campaign?.outreach_report?.reply_rate_meeting}%</Text>
          </HStack>
        ) : null}
      </Td>
    )
  }

  if (title === 'Unsubs') {
    return (
      <Td>
        {campaign?.outreach_report?.unsubscribes ? (
          <HStack>
            <ColorBox color="red" value={campaign?.outreach_report?.unsubscribes} />
            <Text color="muted">{campaign?.outreach_report?.unsubscribe_rate}%</Text>
          </HStack>
        ) : null}
      </Td>
    )
  }

  if (title === 'Errors') {
    return (
      <Td>
        {campaign?.outreach_report?.errors ? (
          <ColorBox color="red" value={campaign?.outreach_report?.errors} />
        ) : null}
      </Td>
    )
  }

  if (title === 'User') {
    return (
      <Td>
        {campaign?.user?.fname ? (
          <HStack>
            <Avatar bg="blue.500" color="white" src={campaign?.user?.profile_image} h={6} w={6} />
            <Text color="muted">{campaign?.user?.fname}</Text>
          </HStack>
        ) : null}
      </Td>
    )
  }
}

export default CampaignsTable
