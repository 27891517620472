import {
  Box,
  Circle,
  Flex,
  HStack,
  Icon,
  Link,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  Text,
  VStack,
  useSteps,
} from '@chakra-ui/react'
import { faRocketLaunch, faWarning } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalLayout from 'components/layouts/modal-layout'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import { useState } from 'react'
import uid from 'utils/uid'
import CampaignsTable from '../../campaigns/components/campaigns-table'
import CheckConflicts from '../../campaigns/components/check-conflicts'

const steps = [
  { title: 'Select', description: 'Campaign' },
  { title: 'Check', description: 'Conflicts' },
  { title: 'Move', description: 'Leads' },
]

const columns = [
  { id: uid(), title: 'Name' },
  { id: uid(), title: 'Channel' },
  { id: uid(), title: 'Queue' },
  { id: uid(), title: 'Contacted' },
  { id: uid(), title: 'Replied' },
  { id: uid(), title: 'Warm' },
  { id: uid(), title: 'Meetings' },
  { id: uid(), title: 'User' },
]

const MoveToCampaignModal = ({ onClose, selectedItems }) => {
  const invalidate = useInvalidateQuery()
  const { activeStep, setActiveStep } = useSteps({ index: 0 })

  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const [wasLaunched, setWasLaunched] = useState(false)

  const noLeadsSelected = selectedItems.length === 0

  const selectStep = (index) => {
    if (!selectedCampaign) return
    if (index === 2 && !wasLaunched) return
    setActiveStep(index)
  }

  const selectCampaign = (campaign) => {
    setSelectedCampaign(campaign)
    setActiveStep(1)
  }

  const onLaunchSuccess = () => {
    setWasLaunched(true)
    setActiveStep(2)
    invalidate('campaigns')
  }

  return (
    <ModalLayout
      header="Move to Campaign"
      isOpen={true}
      onClose={onClose}
      width={noLeadsSelected ? 600 : activeStep === 0 ? 1300 : 760}
    >
      {noLeadsSelected ? (
        <Stack gap={4} align="center" mt={4} mb={12}>
          <VStack>
            <Icon icon={faWarning} h={12} w={12} color="#F8C73D" as={FontAwesomeIcon} />
            <Text fontSize={24} fontWeight={700} lineHeight="28px" color="gray.700">
              No Leads Selected
            </Text>
          </VStack>
          <Text color="gray.700" textAlign="center">
            Please select at least one lead to move to a campaign.
          </Text>
        </Stack>
      ) : (
        <HStack gap={9}>
          <Stepper index={activeStep} orientation="vertical" height="300px" gap="0" cursor="pointer">
            {steps.map((step, index) => (
              <Step key={index} onClick={() => selectStep(index)}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<Circle bg="border" size="3" />}
                    active={<Circle bg="accent" size="3" />}
                  />
                </StepIndicator>
                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                  <StepDescription>{step.description}</StepDescription>
                </Box>
                <StepSeparator />
              </Step>
            ))}
          </Stepper>

          <Flex justify="center" minH={515} w="100%" overflow="auto" p={2}>
            {activeStep === 0 && (
              <CampaignsTable
                onModal
                columns={columns}
                selectedCampaign={selectedCampaign}
                selectCampaign={selectCampaign}
              />
            )}

            {activeStep === 1 && (
              <CheckConflicts
                selectedCampaign={selectedCampaign}
                selectedLeads={selectedItems}
                handleSuccess={onLaunchSuccess}
              />
            )}

            {activeStep === 2 && (
              <VStack gap={5} justifyContent="center">
                <Icon icon={faRocketLaunch} h={12} w={12} color="#51BA72" as={FontAwesomeIcon} />
                <Text fontSize={24} fontWeight={700} lineHeight="28px" color="gray.700">
                  Leads Are Being Launched!
                </Text>

                <Text color="muted" w={390} textAlign="center">
                  Leads will be moved into your campaign shortly. This process can take a few minutes to
                  completely sync with remote systems.
                </Text>

                <Link
                  href={selectedCampaign?.skylead_link ?? selectedCampaign?.quickmail_link}
                  color="#3182CE"
                  isExternal
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {selectedCampaign.name}
                </Link>
              </VStack>
            )}
          </Flex>
        </HStack>
      )}
    </ModalLayout>
  )
}

export default MoveToCampaignModal
