import { Container, Flex } from '@chakra-ui/react'
import NavAdmin from 'components/navigation/nav-admin'
import NavAgency from 'components/navigation/nav-agency'
import NavBar from 'components/navigation/navbar'
import { Outlet } from 'react-router-dom'

const PageLayout = () => {
  return (
    <>
      <NavAdmin />
      <NavAgency />
      <NavBar />
      <Container as="main" p={{ base: 4, md: '32px 32px 64px 32px' }} maxW="100%">
        <Flex direction="column" position="relative">
          <Outlet />
        </Flex>
      </Container>
    </>
  )
}

export default PageLayout
