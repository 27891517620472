import { Box } from '@chakra-ui/react'

const options = {
  active: '#51BA72',
  archived: '#ADADADE5',
  onboarding: '#F8C73DE5',
  setup: '#EA6565E5',
  running: '#51BA72',
  error: '#EA6565',
  downloading: '#F8C73DE5',
  disconnected: '#D3D3D3',
  review: '#F8C73DE5',
  approved: '#51BA72',
  invalid: '#EA6565',
}

const StatusDot = ({ status, size = 4 }) => {
  if (!status) return null

  return <Box bg={options[status]} borderRadius="full" width={size} height={size} />
}

export default StatusDot
