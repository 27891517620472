import PageLayout from 'components/layouts/page-layout'
import MetaData from 'components/meta-data'
import { AccountProvider } from 'context/account-context'
import { useAuth } from 'context/auth-context'
import { ColumnFieldsProvider } from 'context/column-fields-context'
import { CurrentOrgProvider } from 'context/current-organization'
import { FilterTagsProvider } from 'context/filter-tags-context'
import AccountSettingsPageLayout from 'features/account-settings/account-settings-page-layout'
import AgencySettings from 'features/account-settings/pages/agency'
import BillingSettings from 'features/account-settings/pages/billing'
import DomainDetails from 'features/account-settings/pages/domain-details'
import DomainSettings from 'features/account-settings/pages/domains'
import InboxDetails from 'features/account-settings/pages/inbox-details'
import ImboxSettings from 'features/account-settings/pages/inboxes'
import SubscriptionSettings from 'features/account-settings/pages/subscription'
import TeamSettings from 'features/account-settings/pages/team'
import UserSettings from 'features/account-settings/pages/user'
import SignalsBlacklist from 'features/account-signals/signals-blacklist'
import SignalsOverview from 'features/account-signals/signals-overview'
import SignalsPerformance from 'features/account-signals/signals-performance'
import AccountsPage from 'features/accounts/accounts-page'
import ChangePassword from 'features/authentication/pages/change-password'
import Login from 'features/authentication/pages/login'
import Register from 'features/authentication/pages/register'
import ResetPassword from 'features/authentication/pages/reset-password'
import CampaignLeadDetailsPage from 'features/campaign-leads/campaign-lead-details-page'
import CampaignLeadsPageLayout from 'features/campaign-leads/campaign-leads-page-layout'
import AllLeads from 'features/campaign-leads/pages/all-leads'
import BookedLeads from 'features/campaign-leads/pages/booked-leads'
import ClickLeads from 'features/campaign-leads/pages/clicks'
import ConnectionLeads from 'features/campaign-leads/pages/connections'
import OpenLeads from 'features/campaign-leads/pages/opens'
import RepliedLeads from 'features/campaign-leads/pages/replied-leads'
import SearchLeads from 'features/campaign-leads/pages/search-leads'
import WarmLeads from 'features/campaign-leads/pages/warm-leads'
import CampaignsPage from 'features/campaigns/campaigns-page'
import DashboardPage from 'features/campaigns/dashboard-page'
import LeadsFromCampaign from 'features/campaigns/leads-from-campaign-page'
import CompanyPage from 'features/companies/company-page'
import DatabasePageLayout from 'features/database/database-page-layout'
import Agencies from 'features/database/pages/agencies'
import Companies from 'features/database/pages/companies'
import Ecommerce from 'features/database/pages/ecommerce'
import Events from 'features/database/pages/events'
import Fired from 'features/database/pages/fired'
import Fundings from 'features/database/pages/fundings'
import JobPostings from 'features/database/pages/job-postings'
import People from 'features/database/pages/people'
import Topics from 'features/database/pages/topics'
import DomainsPage from 'features/domains/domains-page'
import InboxesPage from 'features/inboxes/inboxes-page'
import OrganizationsPage from 'features/organizations/organizations-page'
import CreateSignalPage from 'features/signal-create/create-signal-page'
import BlankSignal from 'features/signal-create/pages/blank-signal'
import ImportDomains from 'features/signal-create/pages/import-domains'
import SignalTemplate from 'features/signal-create/pages/template'
import SignalLeadsPage from 'features/signal-leads/signal-leads-page'
import SignalScoring from 'features/signal-scoring/signal-scoring-page'
import SignalAdminSettings from 'features/signal-settings/signal-admin-settings-page'
import SignalSettings from 'features/signal-settings/signal-settings-page'
import SignalComments from 'features/signal/pages/comments'
import SignalQueryCompany from 'features/signal/pages/query-company'
import SignalQueryPeople from 'features/signal/pages/query-people'
import SignalQueryTrigger from 'features/signal/pages/query-signal'
import SignalAdminTraining from 'features/signal/pages/query-training'
import SignalPageLayout from 'features/signal/signal-page-layout'
import TeamPage from 'features/team/team-page'
import TemplatesPage from 'features/templates/templates-page'
import TrainingPage from 'features/training/training-page'

import { Navigate, Route, Routes } from 'react-router-dom'

const App = () => {
  const { user, isAgency, isAdmin, isAuthenticated } = useAuth()

  const homepage = `/account/${user?.account_id}/dashboard`

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path="login" element={<Login />} />,
        <Route path="register" element={<Register />} />,
        <Route path="reset-password" element={<ResetPassword />} />,
        <Route path="change-password/:token" element={<ChangePassword />} />
        <Route path="*" element={<Navigate to="login" />} />
      </Routes>
    )
  }

  return (
    <ColumnFieldsProvider>
      <FilterTagsProvider>
        <CurrentOrgProvider>
          <MetaData title="Hello Outbound" />

          <Routes>
            {isAdmin && (
              <Route element={<PageLayout />}>
                <Route path="organizations" element={<OrganizationsPage />} />
                <Route path="templates" element={<TemplatesPage />} />

                <Route path="training" element={<TrainingPage />} />

                <Route path="org/:organizationId">
                  <Route path="accounts" element={<AccountsPage />} />
                  <Route path="team" element={<TeamPage />} />
                  <Route path="domains" element={<DomainsPage />} />
                  <Route path="inboxes" element={<InboxesPage />} />
                </Route>

                <Route path="database" element={<DatabasePageLayout />}>
                  <Route path="companies" element={<Companies />} />
                  <Route path="jobs" element={<JobPostings />} />
                  <Route path="people" element={<People />} />
                  <Route path="ecommerce" element={<Ecommerce />} />
                  <Route path="agencies" element={<Agencies />} />
                  <Route path="fired" element={<Fired />} />
                  <Route path="events" element={<Events />} />
                  <Route path="fundings" element={<Fundings />} />
                  <Route path="topics" element={<Topics />} />
                </Route>

                {signalRoutes()}
              </Route>
            )}

            {(isAdmin || isAgency) && (
              <Route element={<PageLayout />}>
                <Route path="accounts" element={<AccountsPage />} />
                <Route path="team" element={<TeamPage />} />
                <Route path="domains" element={<DomainsPage />} />
                <Route path="inboxes" element={<InboxesPage />} />
              </Route>
            )}

            <Route element={<PageLayout />}>
              <Route path="companies/:companyId" element={<CompanyPage />} />
            </Route>

            <Route
              path="account/:accountId"
              element={
                <AccountProvider>
                  <PageLayout />
                </AccountProvider>
              }
            >
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="campaigns" element={<CampaignsPage />} />
              <Route path="campaigns/:campaignId/leads" element={<LeadsFromCampaign />} />
              <Route path="campaign-lead/:leadId" element={<CampaignLeadDetailsPage />} />

              <Route path="leads" element={<CampaignLeadsPageLayout />}>
                <Route path="warm" element={<WarmLeads />} />
                <Route path="booked" element={<BookedLeads />} />
                <Route path="replied" element={<RepliedLeads />} />
                <Route path="opens" element={<OpenLeads />} />
                <Route path="clicks" element={<ClickLeads />} />
                <Route path="connections" element={<ConnectionLeads />} />
                <Route path="all" element={<AllLeads />} />
                <Route path="search" element={<SearchLeads />} />
              </Route>

              <Route path="signals/overview" element={<SignalsOverview />} />
              <Route path="signals/blacklist" element={<SignalsBlacklist />} />
              <Route path="signals/performance" element={<SignalsPerformance />} />
              <Route path="signals/create" element={<CreateSignalPage />} />
              <Route path="signals/create/blank" element={<BlankSignal />} />
              <Route path="signals/create/template" element={<SignalTemplate />} />
              <Route path="signals/create/import-domains" element={<ImportDomains />} />

              <Route path="settings" element={<AccountSettingsPageLayout />}>
                <Route path="user" element={<UserSettings />} />
                <Route path="team" element={<TeamSettings />} />
                <Route path="billing" element={<BillingSettings />} />
                <Route path="subscription" element={<SubscriptionSettings />} />
                <Route path="inboxes" element={<ImboxSettings />} />
                <Route path="inboxes/:inboxId" element={<InboxDetails />} />
                <Route path="domains" element={<DomainSettings />} />
                <Route path="domains/:domainId" element={<DomainDetails />} />
                <Route path="agency-account" element={<AgencySettings />} />
              </Route>

              {signalRoutes()}
            </Route>

            <Route path="*" element={<Navigate to={homepage} />} />
          </Routes>
        </CurrentOrgProvider>
      </FilterTagsProvider>
    </ColumnFieldsProvider>
  )
}

const signalRoutes = () => (
  <Route element={<SignalPageLayout />}>
    <Route path="signal/:signalId/review" element={<SignalLeadsPage stage="review" />} />
    <Route path="signal/:signalId/approved" element={<SignalLeadsPage stage="complete" />} />
    <Route path="signal/:signalId/archived" element={<SignalLeadsPage stage="archived" />} />
    <Route path="signal/:signalId/declined" element={<SignalLeadsPage stage="declined" />} />
    <Route path="signal/:signalId/hopper" element={<SignalLeadsPage stage="hopper" />} />
    <Route path="signal/:signalId/prospect" element={<SignalLeadsPage stage="prospect" />} />
    <Route path="signal/:signalId/generic" element={<SignalLeadsPage stage="generic" />} />
    <Route path="signal/:signalId/query-company" element={<SignalQueryCompany />} />
    <Route path="signal/:signalId/query-people" element={<SignalQueryPeople />} />
    <Route path="signal/:signalId/query-signal" element={<SignalQueryTrigger />} />
    <Route path="signal/:signalId/comments" element={<SignalComments />} />
    <Route path="signal/:signalId/settings" element={<SignalSettings />} />
    <Route path="signal/:signalId/scoring" element={<SignalScoring />} />
    <Route path="signal/:signalId/admin-settings" element={<SignalAdminSettings />} />
    <Route path="signal/:signalId/admin-training" element={<SignalAdminTraining />} />
  </Route>
)

export default App
