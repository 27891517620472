import { Link } from '@chakra-ui/react'
import { faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

const ConnectButton = ({ data }) => {
  const { accountId } = useParams()

  if (data.type === 'google') {
    const { data: googleAuthLink, isLoading: isGoogleLoading } = useQuery(
      ['google-auth-link', accountId],
      async () =>
        await Api.post(`gapi-auth/generate-inbox-link`, { account_id: accountId, hint: data?.email }),
    )

    return (
      <OutlinedButton
        icon={faGoogle}
        href={googleAuthLink?.url}
        isLoading={isGoogleLoading}
        as={Link}
        bg="transparent"
        isExternal
      >
        Connect
      </OutlinedButton>
    )
  }

  if (data.type === 'microsoft') {
    const { data: microsoftAuthLink, isLoading: isMicrosoftLoading } = useQuery(
      ['microsoft-auth-link', accountId],
      async () =>
        await Api.post(`microsoft-auth/generate-inbox-link`, { account_id: accountId, hint: data?.email }),
    )

    return (
      <OutlinedButton
        icon={faMicrosoft}
        href={microsoftAuthLink?.url}
        isLoading={isMicrosoftLoading}
        bg="transparent"
        as={Link}
        isExternal
      >
        Connect
      </OutlinedButton>
    )
  }

  return null
}

export default ConnectButton
