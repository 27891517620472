import { Box, Grid, Heading, Link, Text, useDisclosure } from '@chakra-ui/react'
import { faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import StandardButton from 'components/buttons/standard-button'
import ModalLayout from 'components/layouts/modal-layout'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

const AddInboxButtonModal = ({ outlined }) => {
  const { accountId } = useParams()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data: googleInboxLink, isLoading: isGoogleInboxLinkLoading } = useQuery(
    ['generate-google-inbox-auth-account', accountId],
    async () => await Api.post(`gapi-auth/generate-inbox-link`, { account_id: accountId }),
    { enabled: isOpen },
  )

  const { data: microsoftInboxLink, isLoading: isMicrosoftInboxLinkLoading } = useQuery(
    ['generate-microsoft-inbox-auth-account', accountId],
    async () => await Api.post(`microsoft-auth/generate-inbox-link`, { account_id: accountId }),
    { enabled: isOpen },
  )
  return (
    <>
      {outlined ? (
        <OutlinedButton icon={faPlus} onClick={onOpen}>
          Add Inbox
        </OutlinedButton>
      ) : (
        <StandardButton icon={faPlus} onClick={onOpen}>
          Add Inbox
        </StandardButton>
      )}

      {isOpen && (
        <ModalLayout header="Add Inbox" isOpen={isOpen} onClose={onClose} size="xl">
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Box>
              <Heading fontSize={22} lineHeight="32px" color="muted" mb={2}>
                Google / Gmail
              </Heading>
              <Text fontSize={14} lineHeight="20px" color="muted" mb={4}>
                Connect your Google account to sync your inboxes.{' '}
                <Link color="blue.500" href="https://support.hellooutbound.com/help/whitelisting">
                  Whitelist Instructions
                </Link>
              </Text>
              <OutlinedButton
                icon={faGoogle}
                target="_blank"
                isLoading={isGoogleInboxLinkLoading}
                as={Link}
                href={googleInboxLink?.url}
                isExternal
              >
                Connect Google
              </OutlinedButton>
            </Box>

            <Box>
              <Heading fontSize={22} lineHeight="32px" color="muted" mb={2}>
                Microsoft
              </Heading>
              <Text fontSize={14} lineHeight="20px" color="muted" mb={4}>
                Connect your Microsoft / Outlook account to sync your inboxes.
              </Text>
              <OutlinedButton
                icon={faMicrosoft}
                target="_blank"
                isLoading={isMicrosoftInboxLinkLoading}
                as={Link}
                href={microsoftInboxLink?.url}
                isExternal
              >
                Connect Microsoft
              </OutlinedButton>
            </Box>
          </Grid>
        </ModalLayout>
      )}
    </>
  )
}

export default AddInboxButtonModal
