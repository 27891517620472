import {
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { faChevronRight, faGripDotsVertical, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StyledIconButton from 'components/buttons/styled-icon-button'
import { useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import camelCaseToTitleCase from 'utils/camel-to-title-case'

const SelectModalColumns = ({
  isOpen,
  onClose,
  columns,
  activeColumns,
  addColumn,
  removeColumn,
  dragColumn,
}) => {
  const [inputValue, setInputValue] = useState('')

  const filteredColumns = useMemo(
    () =>
      Object.entries(columns)
        .flatMap(([key, value]) => value.map((item) => ({ ...item, key })))
        .filter((item) => item.title.toLowerCase().includes(inputValue.toLowerCase())),
    [columns, inputValue],
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
      <ModalOverlay />

      <ModalContent>
        <Grid gridTemplateColumns="2fr 1fr" overflow="hidden">
          <GridItem>
            <Stack borderBottom="1px" borderColor="gray.200" p="10px">
              <Input
                type="text"
                placeholder="Search"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                aria-label="Search Column Input"
              />
            </Stack>

            {inputValue.length > 0 ? (
              <Stack h={420} p={4} className="hover-scroll">
                {filteredColumns.length === 0 ? (
                  <Text fontSize={14}>No column found...</Text>
                ) : (
                  filteredColumns.map((col, idx) => (
                    <Button
                      key={idx}
                      variant="ghost"
                      display="flex"
                      justifyContent="start"
                      fontSize={14}
                      color="gray.700"
                      fontWeight={500}
                      _hover={{ bg: 'gray.50' }}
                      onClick={() => addColumn(col)}
                      flexShrink={0}
                    >
                      <Text color="gray.700" opacity={0.6} mr={2}>
                        {camelCaseToTitleCase(col.key)}
                      </Text>
                      <Icon icon={faChevronRight} as={FontAwesomeIcon} color="muted" h={4} mr={2} />
                      <Text color="gray.700">{col.title}</Text>
                    </Button>
                  ))
                )}
              </Stack>
            ) : (
              <Tabs variant="unstyled" display="flex" h="100%">
                <TabList p="10px" gap={1} flexDirection="column" borderRight="1px" borderColor="gray.200">
                  {Object.keys(columns)?.map((key, index) => (
                    <Tab
                      px="10px"
                      key={index}
                      w={200}
                      borderRadius="8px"
                      justifyContent="space-between"
                      _hover={{ bg: 'gray.50' }}
                      _selected={{ bg: 'gray.100' }}
                    >
                      <Text color="gray.700">{camelCaseToTitleCase(key)}</Text>
                      <Icon icon={faChevronRight} as={FontAwesomeIcon} color="muted" h={4} />
                    </Tab>
                  ))}
                </TabList>

                <TabPanels h={420} py={2} className="hover-scroll">
                  {Object.values(columns)?.map((fields, index) => (
                    <TabPanel key={index} display="flex" flexDirection="column" p={0}>
                      {fields?.map((field, index) => (
                        <Button
                          key={index}
                          variant="ghost"
                          display="flex"
                          justifyContent="start"
                          fontSize={14}
                          color="gray.700"
                          fontWeight={500}
                          borderRadius={0}
                          _hover={{ bg: 'gray.50' }}
                          onClick={() => addColumn(field)}
                        >
                          {field.title}
                        </Button>
                      ))}
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            )}
          </GridItem>

          <GridItem borderLeft="1px" borderColor="gray.200" p="20px 10px 0 20px">
            <Stack>
              <Text fontWeight={600} color="muted">
                Active Columns
              </Text>

              <DragDropContext onDragEnd={dragColumn}>
                <Droppable droppableId="root">
                  {(provided) => (
                    <Stack
                      h={410}
                      className="scroll-div"
                      spacing={0}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {activeColumns.map(
                        (column, index) =>
                          !column.isHidden && (
                            <Draggable
                              key={column.id}
                              draggableId={column.id}
                              index={index}
                              isDragDisabled={column.isFixed}
                            >
                              {(provided) => (
                                <Flex
                                  key={column.id}
                                  role="group"
                                  align="center"
                                  minH={10}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  {!column.isFixed && (
                                    <StyledIconButton onClick={() => removeColumn(column)} mr={1} mt="2px">
                                      <Icon icon={faXmark} as={FontAwesomeIcon} color="#EA6565" />
                                    </StyledIconButton>
                                  )}

                                  <Text fontSize={14} fontWeight={500} color="gray.700" mr={1}>
                                    {column.title}
                                  </Text>

                                  {!column.isFixed && (
                                    <Icon icon={faGripDotsVertical} as={FontAwesomeIcon} color="gray.500" />
                                  )}
                                </Flex>
                              )}
                            </Draggable>
                          ),
                      )}
                      {provided.placeholder}
                    </Stack>
                  )}
                </Droppable>
              </DragDropContext>
            </Stack>
          </GridItem>
        </Grid>
      </ModalContent>
    </Modal>
  )
}

export default SelectModalColumns
