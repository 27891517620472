import { Flex, Tr } from '@chakra-ui/react'
import ColumnButton from 'components/column-table/components/column-button'
import useSelectItems from 'components/column-table/hooks/use-select-items'
import useSortItems from 'components/column-table/hooks/use-sort-items'
import useTableColumns from 'components/column-table/hooks/use-table-columns'
import TableLayout from 'components/layouts/table-layout'
import Pagination from 'components/pagination'
import useHandleBuildQuery from 'components/query-builder/hooks/use-handle-build-query'
import SelectMenu from 'components/select-menu'
import { useColumnFields } from 'context/column-fields-context'
import QueryFilterComponent from 'features/signal-leads/components/query-filter-component'
import useChannelOptions from 'hooks/dropdown-filters/use-channel-options'
import useUserOptions from 'hooks/dropdown-filters/use-user-options'
import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import FiltersDropdown from './filters-dropdown'
import LeadActionsDropdown from './lead-actions-dropdown'
import RenderTableCell from './render-table-cell'

const FETCHING_LIMIT = 100

const tableSets = {
  all: { sortColumn: 'date_created' },
  warm: { sortColumn: 'date_replied' },
  replied: { sortColumn: 'date_replied' },
  booked: { sortColumn: 'date_meeting' },
  opened: { sortColumn: 'date_last_open' },
  clicked: { sortColumn: 'click_count' },
  connected: { sortColumn: 'date_linkedin_connection_accept' },
  inside_campaign: { sortColumn: 'date_replied' },
}

const CampaignLeadsTable = ({ type, searchTerm }) => {
  const { accountId, campaignId } = useParams()

  const fields = useColumnFields('campaign-leads')
  const { activeColumns, addColumn, removeColumn, dragColumn } = useTableColumns('campaign_leads')

  const [page, setPage] = useState(0)

  const { sortConfig, handleSort } = useSortItems({ col: tableSets[type].sortColumn })

  const { user, userOptions, selectUser } = useUserOptions()

  const { channel, channelOptions, selectChannel } = useChannelOptions({
    saveKey: `${type}-leads-table-channel`,
  })

  const queryBuilder = useHandleBuildQuery({ shouldNotSave: true })
  const { query, triggerSearch } = queryBuilder ?? {}

  const { data, isFetching } = useQuery(
    ['campaign-leads', accountId, page, user, channel, sortConfig, campaignId, triggerSearch],
    () =>
      Api.get('campaign-leads', {
        account_id: accountId,
        user_id: user?.userId,
        channel: channel?.value,
        offset: page * FETCHING_LIMIT,
        limit: FETCHING_LIMIT,
        interest: type === 'warm' ? 'warm' : undefined,
        status: type === 'replied' ? 'REPLIED' : undefined,
        sort: sortConfig.direction,
        order: sortConfig.column,
        is_booked: type === 'booked',
        is_opened: type === 'opened',
        is_clicked: type === 'clicked',
        is_connected: type === 'connected',
        campaign_id: campaignId,
        query,
      }),
    { enabled: !searchTerm },
  )

  const { data: searchData, isFetching: isSearching } = useQuery(
    ['campaign-leads-search', accountId, searchTerm],
    () =>
      Api.get('campaign-leads-search', {
        account_id: accountId,
        search_query: searchTerm,
      }),
    { enabled: Boolean(searchTerm) },
  )

  const { selectedItems, selectItem } = useSelectItems({ data })

  const nextPage = useCallback(() => {
    setPage((prevPage) => prevPage + 1)
  }, [page])

  const previousPage = useCallback(() => {
    setPage((prevPage) => prevPage - 1)
  }, [page])

  const disablePrev = page === 0
  const disableNext = !data?.results || data?.results?.length < FETCHING_LIMIT

  const dataToDisplay = searchData ?? data

  const isLoading = searchTerm ? isSearching : isFetching

  const displayColumns = searchTerm ? activeColumns?.slice(1) : activeColumns

  return (
    <TableLayout
      columns={displayColumns}
      isLoading={isLoading}
      selectedItems={selectedItems}
      selectItem={selectItem}
      data={dataToDisplay}
      removeColumn={removeColumn}
      handleSort={handleSort}
      sortConfig={sortConfig}
      header={
        <>
          <Flex gap={3}>
            <ColumnButton
              columns={fields}
              activeColumns={displayColumns}
              addColumn={addColumn}
              removeColumn={removeColumn}
              dragColumn={dragColumn}
            />
            {!searchTerm && (
              <>
                <SelectMenu
                  selected={channel}
                  options={channelOptions}
                  handleSelect={selectChannel}
                  type="icon"
                />
                <SelectMenu selected={user} options={userOptions} handleSelect={selectUser} type="avatar" />
                <LeadActionsDropdown
                  tableType={type}
                  selectedItems={selectedItems}
                  query={queryBuilder.query}
                  user={user}
                />
                <FiltersDropdown queryBuilder={queryBuilder} />
              </>
            )}
          </Flex>
          <Pagination data={{ nextPage, previousPage, disablePrev, disableNext, paginationEnabled: true }} />
        </>
      }
      queryFilter={
        query?.base.length > 0 && (
          <QueryFilterComponent queryBuilder={queryBuilder} filterKey="campaign-leads-filter" />
        )
      }
    >
      {dataToDisplay?.results?.map((lead) => (
        <Tr
          key={lead?.id}
          cursor="pointer"
          _hover={{ backgroundColor: 'gray.50' }}
          transition="background-color 0.3s ease"
          onClick={() => window.open(`/account/${accountId}/campaign-lead/${lead?.id}`, '_blank')}
        >
          {displayColumns?.map((col) => (
            <RenderTableCell
              key={col.id}
              column={col}
              lead={lead}
              selectedItems={selectedItems}
              selectItem={selectItem}
            />
          ))}
        </Tr>
      ))}
    </TableLayout>
  )
}

export default CampaignLeadsTable
