import { Button, Input } from '@chakra-ui/react'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRef } from 'react'
import { isEnumOrTagType, isNumberType } from '../utils'
import MultiTagSelector from './multi-tag-selector'

const RenderInputAndButton = ({ filter, traitIndex, fieldIndex, addKeywordValue, addFilterValue }) => {
  const inputRef = useRef()

  const handleSubmit = (e) => {
    e.preventDefault()
    const inputValue = inputRef.current.value
    addKeywordValue(e, filter, inputValue, traitIndex, fieldIndex)
    inputRef.current.value = ''
  }

  if (isNumberType(filter.type) && filter.value?.length) {
    return null
  }

  if (isEnumOrTagType(filter.type)) {
    return (
      <MultiTagSelector
        filter={filter}
        fieldIndex={fieldIndex}
        traitIndex={traitIndex}
        addFilterValue={addFilterValue}
        data-testid="multi-tag-selector"
      />
    )
  }

  return (
    <form onSubmit={handleSubmit} style={{ display: 'flex', gap: 8 }}>
      <Input
        size="sm"
        width="222px"
        placeholder={isNumberType(filter.type) ? 'Number' : 'Keyword'}
        _focus={{ boxShadow: 'none' }}
        type={isNumberType(filter.type) ? 'number' : 'text'}
        ref={inputRef}
        aria-label="Param Input"
      />

      <Button
        type="submit"
        variant="outline"
        leftIcon={<FontAwesomeIcon icon={faPlus} />}
        h={8}
        w="70px"
        fontSize={14}
        color="gray.300"
        _focus={{ boxShadow: 'none' }}
      >
        Add
      </Button>
    </form>
  )
}

export default RenderInputAndButton
