import { HStack, Input, Td, Text, Tr, VStack } from '@chakra-ui/react'
import TableLayout from 'components/layouts/table-layout'
import UnderlineLink from 'components/links/underline-link'
import MetaData from 'components/meta-data'
import PageHeader from 'components/page-header'
import useDebounce from 'hooks/use-debounce'
import { useState } from 'react'
import { useQuery } from 'react-query'
import Api from 'utils/api'
import uid from 'utils/uid'
import CreateTrainingButtonModal from './components/create-training-button-modal'

const columns = [
  { id: uid(), title: 'Name' },
  { id: uid(), title: 'Approved' },
  { id: uid(), title: 'Declined' },
  { id: uid(), title: 'Accuracy' },
  { id: uid(), title: 'Precision' },
]

const TemplatesPage = () => {
  const [searchInput, setSearchInput] = useState('')

  const debouncedSearchterm = useDebounce(searchInput, 250)

  const { data: trainingSignals, isLoading } = useQuery(['training-signals', debouncedSearchterm], () =>
    Api.get('signals', { type: 'training' }),
  )

  const onSearch = (e) => {
    setSearchInput(e.target.value)
  }

  return (
    <>
      <MetaData title="Training Signals" />

      <VStack mx="auto">
        <PageHeader
          title="Training Signals"
          description="sigals used to train models"
          button={<CreateTrainingButtonModal />}
        />

        <TableLayout
          columns={columns}
          isLoading={isLoading}
          minW={{ base: '100%', md: 624 }}
          header={
            <Input
              type="text"
              placeholder="Search Templates"
              aria-label="Search Templates Input"
              value={searchInput}
              onChange={onSearch}
            />
          }
        >
          {trainingSignals?.results?.length === 0 ? (
            <Tr>
              <Td>
                <Text color="muted">No results found...</Text>
              </Td>
            </Tr>
          ) : (
            trainingSignals?.results.map((signal) => (
              <Tr key={signal.id}>
                <Td>
                  <UnderlineLink text={signal?.name} path={`/signal/${signal.id}/review`} fontSize={16} />
                </Td>
                <Td w={60}>
                  <HStack>
                    <Text color="muted" fontSize={16} fontWeight={600}>
                      {signal.stage_counts.complete}
                    </Text>
                  </HStack>
                </Td>
                <Td w={60}>
                  <Text color="muted" fontSize={16} fontWeight={600}>
                    {signal.stage_counts.declined}
                  </Text>
                </Td>
                <Td w={60}>
                  <Text color="muted" fontSize={16} fontWeight={600}>
                    {signal.training_report?.accuracy ? signal.training_report.accuracy + '%' : ''}
                  </Text>
                </Td>
                <Td w={60}>
                  <Text color="muted" fontSize={16} fontWeight={600}>
                    {signal.training_report?.precision ? signal.training_report.precision + '%' : ''}
                  </Text>
                </Td>
              </Tr>
            ))
          )}
        </TableLayout>
      </VStack>
    </>
  )
}

export default TemplatesPage
