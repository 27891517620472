import { Button, Stack, Tag, Text } from '@chakra-ui/react'
import ModalLayout from 'components/layouts/modal-layout'
import LoadingSpinner from 'components/loading/loading-spinner'
import SelectMenu from 'components/select-menu'
import { useAuth } from 'context/auth-context'
import { useColumnFields } from 'context/column-fields-context'
import { useSignalLeadsProps } from 'context/signal-leads-context'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

const AnalyzeStageModal = ({ onClose, addQueryFilter }) => {
  const { isAdmin } = useAuth()
  const { stage } = useSignalLeadsProps()
  const { accountId, signalId } = useParams()

  const metricOptions = [
    { title: 'Technologies', value: 'technologies' },
    { title: 'Business Model', value: 'business_model' },
    { title: 'LinkedIn Industries', value: 'linkedin_industry' },
    { title: 'Google Classify', value: 'google_classify' },
    { title: 'Personas', value: 'personas' },
    { title: 'Employee Range', value: 'employee_range' },
    { title: 'Funding Stage', value: 'funding_stage' },
    isAdmin ? { title: 'Country', value: 'country' } : null,
    { title: 'Regions', value: 'regions' },
    { title: 'Founded', value: 'founded' },
    { title: 'Networks', value: 'networks' },
    { title: 'Google Rank', value: 'google_rank' },
  ].filter(Boolean)

  const [metric, setMetric] = useState(metricOptions[0])

  const { data, isFetching } = useQuery(['stage-report', metric], () =>
    Api.post('signal/stage-report', {
      account_id: accountId,
      signal_id: signalId,
      stage: stage,
      metric: metric?.value,
    }),
  )

  const fields = useColumnFields('companies')
  const flattenedFields = [...fields.company, ...(fields['company admin'] ?? [])]

  const handleClick = (value) => {
    const fieldObject = flattenedFields?.find((field) => field?.column === metric?.value)
    addQueryFilter({ ...fieldObject, action: 'includes_any', value: [value] })
    onClose()
  }

  return (
    <ModalLayout header="Signal Report" isOpen={true} onClose={onClose} size="xl" isCentered={false}>
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        <>
          <SelectMenu
            options={metricOptions}
            selected={metric}
            handleSelect={(metric) => setMetric(metric)}
            mb={5}
          />

          {data?.results?.length > 0 ? (
            <Stack
              border="1px"
              borderColor="gray.200"
              borderRadius="6px"
              spacing={0}
              overflow="hidden"
              mb={4}
              _last={{ mb: 0 }}
            >
              {data.results.map(({ tag, count }, idx) => (
                <Button
                  key={idx}
                  h="100%"
                  borderRadius={0}
                  px={3}
                  py={2}
                  borderBottom="1px"
                  borderColor="gray.200"
                  bg="transparent"
                  display="flex"
                  justifyContent="space-between"
                  fontSize={14}
                  fontWeight={400}
                  _hover={{ bg: 'gray.50' }}
                  _last={{ borderBottom: 'none' }}
                  onClick={() => handleClick(tag)}
                >
                  <Text>{tag}</Text>
                  <Tag>{count}</Tag>
                </Button>
              ))}
            </Stack>
          ) : (
            <Text fontSize={14} color="muted">
              Nothing found...
            </Text>
          )}
        </>
      )}
    </ModalLayout>
  )
}

export default AnalyzeStageModal
