import { Grid, GridItem } from '@chakra-ui/react'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import {
  faCalendar,
  faComputerMouse,
  faEyes,
  faFire,
  faReply,
  faSearch,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons'
import ColorBox from 'components/colorful-box'
import NavLink from 'components/links/nav-link'
import MetaData from 'components/meta-data'
import SideMenuDivider from 'components/side-menu-divider'
import WhiteSurface from 'components/white-surface'
import { useQuery } from 'react-query'
import { Outlet, useParams } from 'react-router-dom'
import Api from 'utils/api'

const CampaignLeadsPageLayout = () => {
  const { accountId } = useParams()

  const basePath = `/account/${accountId}/leads`

  const { data: warmCount } = useQuery(['campaign-leads-warm-count', accountId], () =>
    Api.get('campaign-leads', {
      account_id: accountId,
      count_only: true,
      interest: 'warm',
    }),
  )

  const { data: bookingCount } = useQuery(['campaign-leads-booking-count', accountId], () =>
    Api.get('campaign-leads', {
      account_id: accountId,
      count_only: true,
      is_booked: true,
    }),
  )

  const { data: repliedCount } = useQuery(['campaign-leads-replied-count', accountId], () =>
    Api.get('campaign-leads', {
      account_id: accountId,
      count_only: true,
      status: 'REPLIED',
    }),
  )

  const navLinks = {
    replies: [
      { label: 'Warm', icon: faFire, path: `${basePath}/warm`, count: warmCount?.total_count },
      { label: 'Bookings', icon: faCalendar, path: `${basePath}/booked`, count: bookingCount?.total_count },
      { label: 'Replied', icon: faReply, path: `${basePath}/replied`, count: repliedCount?.total_count },
    ],
    activity: [
      { label: 'Clicks', icon: faComputerMouse, path: `${basePath}/clicks` },
      { label: 'Opens', icon: faEyes, path: `${basePath}/opens` },
      { label: 'Connections', icon: faLinkedin, path: `${basePath}/connections` },
    ],
    all: [
      { label: 'All Leads', icon: faUsers, path: `${basePath}/all` },
      { label: 'Search', icon: faSearch, path: `${basePath}/search` },
    ],
  }

  return (
    <>
      <MetaData title="Campaign Leads" />

      <Grid gap="30px" gridTemplateColumns={{ base: '1fr', md: '235px 1fr' }}>
        <GridItem w="100%">
          <SideMenuDivider label="Replies" />

          <WhiteSurface as="nav" br={16} p={2} mb={4} gap={1}>
            {navLinks.replies.map((link, idx) => (
              <NavLink key={idx} {...link}>
                {link.label}
                <ColorBox
                  color={link.label === 'Replied' ? 'gray' : 'green'}
                  value={link.count}
                  justifyContent="end"
                  w="100%"
                />
              </NavLink>
            ))}
          </WhiteSurface>

          <SideMenuDivider label="Activity" />

          <WhiteSurface as="nav" br={16} p={2} mb={4} gap={1}>
            {navLinks.activity.map((link, idx) => (
              <NavLink key={idx} {...link}>
                {link.label}
              </NavLink>
            ))}
          </WhiteSurface>

          <SideMenuDivider label="All Leads" />

          <WhiteSurface as="nav" br={16} p={2} gap={1}>
            {navLinks.all.map((link, idx) => (
              <NavLink key={idx} {...link}>
                {link.label}
              </NavLink>
            ))}
          </WhiteSurface>
        </GridItem>

        <GridItem p={1} overflow="hidden">
          <Outlet />
        </GridItem>
      </Grid>
    </>
  )
}
export default CampaignLeadsPageLayout
