import { Flex, FormLabel, Input, Stack, Text } from '@chakra-ui/react'
import OutlinedButton from 'components/buttons/outlined-button'
import WhiteSurface from 'components/white-surface'
import { useAuth } from 'context/auth-context'
import useNotification from 'hooks/use-notification'
import { useState } from 'react'
import { useMutation } from 'react-query'
import Api from 'utils/api'
import useUpdateUserSettings from '../hooks/use-update-user-settings'

const UserSettings = () => {
  const { user } = useAuth()
  const { showSuccessMessage } = useNotification()

  const [fname, setFirstName] = useState(() => user?.fname ?? '')
  const [lname, setLastName] = useState(() => user?.lname ?? '')
  const [email, setEmail] = useState(() => user?.email ?? '')
  const [handle, setHandle] = useState(() => user?.handle ?? '')
  const [phone_number, setPhoneNumber] = useState(() => user?.phone_number ?? '')
  const [password_1, setPassword1] = useState('')
  const [password_2, setPassword2] = useState('')

  const { updateSettings, isLoading } = useUpdateUserSettings()

  const { mutate: updatePassword, isLoading: isUpdating } = useMutation(
    (payload) => Api.post('authenticate/update-password', payload),
    { onSuccess: () => showSuccessMessage('Password updated successfully') },
  )

  const handleUpdateUserInfo = (e) => {
    e.preventDefault()
    updateSettings({ fname, lname, email, handle, phone_number })
  }

  const handleUpdatePassword = (e) => {
    e.preventDefault()
    updatePassword({ password_1, password_2, user_id: user.id })
    setPassword1('')
    setPassword2('')
  }

  return (
    <Stack mx="auto" maxW={651} spacing={3}>
      <Text fontSize={22} lineHeight="32px">
        User Information
      </Text>
      <WhiteSurface br={4} p={4}>
        <form onSubmit={handleUpdateUserInfo}>
          <Flex gap={3}>
            <Stack spacing={0} w="50%">
              <RenderInput
                label="First Name"
                value={fname}
                onChange={setFirstName}
                placeholder="First Name"
                mt={0}
              />
            </Stack>
            <Stack spacing={0} w="50%">
              <RenderInput
                label="Last Name"
                value={lname}
                onChange={setLastName}
                placeholder="Last Name"
                mt={0}
              />
            </Stack>
          </Flex>
          <RenderInput
            label="Email Address"
            value={email}
            onChange={setEmail}
            placeholder="email@example.com"
          />
          <RenderInput label="Handle" value={handle} onChange={setHandle} placeholder="handle" />
          <RenderInput
            label="Phone Number"
            value={phone_number}
            onChange={setPhoneNumber}
            type="tel"
            placeholder="1 555-555-5555"
          />
          <OutlinedButton mt={4} type="submit" isLoading={isLoading}>
            Save
          </OutlinedButton>
        </form>
      </WhiteSurface>

      <Text fontSize={22} lineHeight="32px" mt={10}>
        Update Password
      </Text>
      <WhiteSurface br={4} p={4}>
        <form onSubmit={handleUpdatePassword}>
          <Flex gap={3}>
            <Stack spacing={0} w="50%">
              <RenderInput
                label="Password"
                value={password_1}
                onChange={setPassword1}
                placeholder="******"
                mt={0}
              />
            </Stack>
            <Stack spacing={0} w="50%">
              <RenderInput
                label="Confirm Password"
                value={password_2}
                onChange={setPassword2}
                placeholder="******"
                mt={0}
              />
            </Stack>
          </Flex>
          <OutlinedButton mt={4} type="submit" isLoading={isUpdating}>
            Update
          </OutlinedButton>
        </form>
      </WhiteSurface>
    </Stack>
  )
}

const RenderInput = ({ label, value, onChange, placeholder, mt = 4, type = 'text', ...props }) => (
  <>
    <FormLabel mt={mt}>{label}</FormLabel>
    <Input
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      {...props}
    />
  </>
)

export default UserSettings
