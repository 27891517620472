import { Text, VStack } from '@chakra-ui/react'
import ModalLayout from 'components/layouts/modal-layout'
import SelectMenu from 'components/select-menu'
import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import useCampaignActions from '../hooks/use-campaign-actions'

const SetUserModal = ({ campaign, onClose }) => {
  const { accountId } = useParams()

  const [user, setUser] = useState()
  const [userOptions, setUserOptions] = useState([])

  const { updateCampaign } = useCampaignActions({ campaign })

  useQuery(['users', accountId], () => Api.get('users', { account_id: accountId, is_outreach: true }), {
    onSuccess: (users) => {
      const userSelectOptions = users.results.map((user) => ({
        userId: user?.id,
        title: user?.fname,
        profile_image: user?.profile_image,
      }))
      setUserOptions(userSelectOptions)
      setUser(userSelectOptions?.find(({ userId }) => userId === campaign?.user_id))
    },
  })

  const selectUser = useCallback(
    (item) => {
      if (item.userId === campaign?.user_id) return
      setUser(item)
      updateCampaign({ user_id: item.userId })
    },
    [campaign],
  )

  return (
    <ModalLayout header="Set User" isOpen={true} onClose={onClose}>
      <VStack justify="center" gap={4}>
        <Text>Select the user that outreach will come from.</Text>

        <SelectMenu
          selected={user ?? { title: 'Select User' }}
          options={userOptions}
          handleSelect={selectUser}
          type="avatar"
          bg="transparent"
        />
      </VStack>
    </ModalLayout>
  )
}

export default SetUserModal
