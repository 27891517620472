import { useAccount } from 'context/account-context'
import { useCallback, useMemo, useState } from 'react'

const initialUserOptions = [{ title: 'All Users', value: null }, { value: 'Divider' }]

const useUserOptions = () => {
  const { accountUsers } = useAccount()

  const [user, setUser] = useState(initialUserOptions[0])

  const userSelectOptions = accountUsers?.results.map((user) => ({
    userId: user?.id,
    title: user?.fname,
    profile_image: user?.profile_image,
  }))

  const userOptions = useMemo(
    () =>
      Array.isArray(userSelectOptions) ? [...initialUserOptions, ...userSelectOptions] : initialUserOptions,
    [userSelectOptions],
  )

  const selectUser = useCallback((user) => {
    setUser(user)
  }, [])

  return { user, userOptions, selectUser }
}

export default useUserOptions
