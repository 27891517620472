import { useDisclosure } from '@chakra-ui/react'
import { faColumns3 } from '@fortawesome/pro-regular-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import SelectModalColumns from 'components/column-table/components/select-column-modal'
import isEqual from 'lodash.isequal'
import { memo } from 'react'

const ColumnButton = ({ columns, activeColumns, addColumn, removeColumn, dragColumn, ...props }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <OutlinedButton icon={faColumns3} onClick={onOpen} {...props}>
        Columns
      </OutlinedButton>

      {isOpen && (
        <SelectModalColumns
          isOpen={isOpen}
          onClose={onClose}
          columns={columns}
          activeColumns={activeColumns}
          addColumn={addColumn}
          removeColumn={removeColumn}
          dragColumn={dragColumn}
        />
      )}
    </>
  )
}

export default memo(ColumnButton, (prevProps, nextProps) => isEqual(prevProps, nextProps))
