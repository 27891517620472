import { HStack, Icon, Link, Stack, Text, VStack } from '@chakra-ui/react'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StandardButton from 'components/buttons/standard-button'
import LoadingSpinner from 'components/loading/loading-spinner'
import SelectMenu from 'components/select-menu'
import { useSignal } from 'context/signal-context'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import useNotification from 'hooks/use-notification'
import { useState } from 'react'
import { useMutation } from 'react-query'
import Api from 'utils/api'
import stringify from 'utils/stringify'

const addLeadOptions = [
  { title: '50', value: 50 },
  { title: '100', value: 100 },
  { title: '250', value: 250 },
  { title: '500', value: 500 },
  { title: '1,000', value: 1000 },
  { title: '2,500', value: 2500 },
  { title: '5,000', value: 5000 },
  { title: '10,000', value: 10000 },
]

const AddLeadModalContent = () => {
  const { signal } = useSignal()
  const invalidate = useInvalidateQuery()
  const { showSuccessMessage } = useNotification()

  const [completed, setCompleted] = useState(false)
  const [potentialLeads, setPotentialLeads] = useState(null)
  const [amountOfLeads, setAmountOfLeads] = useState(addLeadOptions[0])

  const { mutate: estimateSignal, isLoading: estimationLoading } = useMutation(
    () =>
      Api.post('create-leads/signal-estimate', {
        signal_id: signal?.id,
      }),
    { onSuccess: (data) => setPotentialLeads(data.estimate) },
  )

  const { mutateAsync: handleAddLeads, isLoading } = useMutation(
    (limit) =>
      Api.post('create-leads/add-to-signal', {
        signal_id: signal?.id,
        limit: limit,
      }),
    {
      onSuccess: (_, limit) => {
        showSuccessMessage(`${stringify(limit)} leads are being created`)
        setCompleted(true)
        setTimeout(() => invalidate('stages-count'), 300)
        setPotentialLeads(null)
      },
    },
  )

  if (completed) {
    return (
      <Stack gap="18px" align="center" my={4}>
        <VStack>
          <Icon icon={faCheck} h={12} w={12} color="#51BA72" as={FontAwesomeIcon} />
          <Text fontSize={24} fontWeight={700} lineHeight="28px" color="gray.700">
            Leads Added!
          </Text>
        </VStack>
        <Text color="gray.700" textAlign="center" maxW={400}>
          Leads will be added to the Review stage as they are prospected and verified in real time. This
          process can take 1-10+ minutes.
        </Text>
        <Link style={{ color: '#3182CE', textDecoration: 'underline' }} textAlign="center">
          Learn More
        </Link>
      </Stack>
    )
  }

  return (
    <Stack gap="30px" align="center" mt={4}>
      <Text color="gray.700" textAlign="center" maxW={370}>
        Add leads to your signal that will kickoff the prospecting and verification tasks.
      </Text>

      <HStack justify="center">
        <SelectMenu
          selected={addLeadOptions[0]}
          options={addLeadOptions}
          handleSelect={(value) => setAmountOfLeads(value)}
          w={174}
          listWidth={174}
        />
        <StandardButton w={115} onClick={() => handleAddLeads(amountOfLeads.value)} isLoading={isLoading}>
          Add Leads
        </StandardButton>
      </HStack>

      <Stack mb={8}>
        <Link
          style={{ color: '#3182CE', textDecoration: 'underline' }}
          textAlign="center"
          onClick={estimateSignal}
        >
          Estimate Available Leads
        </Link>

        {estimationLoading ? (
          <LoadingSpinner size="md" />
        ) : potentialLeads ? (
          <Text color="muted" fontSize={14} fontWeight={500} textAlign="center">
            There are {stringify(potentialLeads)} potential leads available
          </Text>
        ) : null}
      </Stack>
    </Stack>
  )
}

export default AddLeadModalContent
