import { Tag, TagCloseButton, TagLabel } from '@chakra-ui/react'

const ClosableTag = ({
  fs = 14,
  fw = 600,
  bg = 'gray.100',
  bc = 'gray.300',
  onClick,
  isExclusion,
  children,
  ...props
}) => (
  <Tag
    h={8}
    bg={isExclusion ? 'red.100' : bg}
    p="6px 12px"
    border="1px"
    borderColor={isExclusion ? '#E5A4A4' : bc}
    borderRadius="6px"
    justifyContent="space-between"
    {...props}
  >
    <TagLabel fontSize={fs} fontWeight={fw} color={isExclusion ? '#822727' : 'gray.700'}>
      {children}
    </TagLabel>

    <TagCloseButton
      onClick={onClick}
      opacity={1}
      color={isExclusion ? '#E5A4A4' : 'gray.300'}
      _hover={{ color: '#822727' }}
    />
  </Tag>
)

export default ClosableTag
