import { useDisclosure } from '@chakra-ui/react'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import QueryBuilderFilterModal from './query-builder-filter-modal'

const AddFilterTraitButton = ({ isTrait, filterFields, addFilter }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const selectFilter = (filter) => {
    addFilter(filter)
    onClose()
  }

  return (
    <>
      <OutlinedButton icon={faPlus} onClick={onOpen} w="max-content" data-testid="add-filter-trait-button">
        Add {isTrait ? 'Trait' : 'Filter'}
      </OutlinedButton>

      {isOpen && (
        <QueryBuilderFilterModal
          isOpen={isOpen}
          onClose={onClose}
          filterFields={filterFields}
          selectFilter={selectFilter}
        />
      )}
    </>
  )
}

export default AddFilterTraitButton
