import { Button, Flex, FormControl, FormLabel, Input, Link, Text } from '@chakra-ui/react'
import StandardButton from 'components/buttons/standard-button'
import CustomLink from 'components/links/custom-link'
import useNotification from 'hooks/use-notification'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Api from 'utils/api'
import AuthPageLayout from '../layout/auth-page-layout'

const ChangePassword = () => {
  const navigate = useNavigate()
  const { token } = useParams()
  const { showSuccessMessage, showNotification } = useNotification()

  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')

  const { data: tokenIsValid } = useQuery(['tokenIsValid', token], () =>
    Api.post('authenticate/verify-reset-token', { token }),
  )

  const { mutate, isLoading } = useMutation(
    () =>
      Api.post('authenticate/new-password', {
        token,
        password_1: password1,
        password_2: password2,
      }),
    {
      onSuccess: () => {
        showSuccessMessage('Your password has been successfully reset.')
        navigate('/login')
      },
    },
  )

  const onSubmit = (e) => {
    e.preventDefault()
    if (password1 !== password2) {
      return showNotification({ message: 'Passwords do not match', status: 'error' })
    }
    mutate()
  }

  return (
    <AuthPageLayout onSubmit={onSubmit} title="Change password">
      {!tokenIsValid ? (
        <Text align="center">
          Looks like that token is invalid!
          <Link href="/reset-password">
            <Button variant="link" colorScheme="blue" size="sm">
              Please try resetting your password again
            </Button>
          </Link>
        </Text>
      ) : (
        <>
          <FormControl id="password" isRequired>
            <FormLabel>New Password</FormLabel>
            <Input
              type="text"
              name="password"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              placeholder="*********"
            />
          </FormControl>
          <FormControl id="password2" isRequired>
            <FormLabel>Confirm Password</FormLabel>
            <Input
              type="text"
              name="password2"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              placeholder="*********"
            />
          </FormControl>

          <StandardButton type="submit" isLoading={isLoading}>
            Change Password
          </StandardButton>
        </>
      )}

      <Flex justifyContent="center">
        <CustomLink path="/login" text="Back to login" size="sm" />
      </Flex>
    </AuthPageLayout>
  )
}

export default ChangePassword
