import { Stack, Text } from '@chakra-ui/react'
import DeclineButton from 'components/buttons/decline-button'
import ModalLayout from 'components/layouts/modal-layout'
import { useSignalLeadsProps } from 'context/signal-leads-context'
import pluralize from 'utils/pluralize'
import useLeadActions from '../hooks/use-lead-actions'

const DeclineLeadModal = ({ onClose }) => {
  const { declineLeads, isDeclining } = useLeadActions()

  const { lead, inSingleView, selectedItems, clearSelectedItems } = useSignalLeadsProps()

  const amountSelected = inSingleView ? 1 : selectedItems.length

  const handleDecline = () => {
    const leadIds = inSingleView ? [lead?.id] : selectedItems.map((item) => item.id)
    declineLeads(leadIds, {
      onSuccess: () => {
        clearSelectedItems()
        onClose()
      },
    })
  }

  return (
    <ModalLayout header="Decline Leads" isOpen={true} onClose={onClose} size="lg">
      <Stack align="center" spacing={6} mb={4}>
        <Text textAlign="center">
          {amountSelected > 0
            ? `You are declining ${amountSelected} ${pluralize('lead', amountSelected)}.`
            : 'Please select at least one lead to decline.'}
        </Text>

        <DeclineButton
          onClick={handleDecline}
          isLoading={isDeclining}
          isDisabled={!inSingleView && amountSelected === 0}
        >
          Decline
        </DeclineButton>
      </Stack>
    </ModalLayout>
  )
}

export default DeclineLeadModal
