import { Button, Flex, Text } from '@chakra-ui/react'
import ModalLayout from 'components/layouts/modal-layout'

const ChangeSignalTypeModal = ({ isOpen, dismissChange, approveChange }) => {
  return (
    <ModalLayout header="Change Signal Type" isOpen={isOpen} onClose={dismissChange}>
      <Text textAlign="center">Are you sure you want to change the signal type?</Text>
      <Flex justify="center" gap={2} mt={6}>
        <Button colorScheme="green" onClick={approveChange}>
          Yes
        </Button>
        <Button colorScheme="red" onClick={dismissChange}>
          No
        </Button>
      </Flex>
    </ModalLayout>
  )
}

export default ChangeSignalTypeModal
