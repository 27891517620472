import { Box, Grid, Link, Text } from '@chakra-ui/react'
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faPowerOff } from '@fortawesome/pro-regular-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import PageHeader from 'components/page-header'
import WhiteSurface from 'components/white-surface'
import { useAccount } from 'context/account-context'
import DomainsTable from 'features/domains/components/domains-table'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import uid from 'utils/uid'
import AddDomainButtonModal from '../components/add-domain-button-modal'

const columns = [
  { id: uid(), title: 'Domain' },
  { id: uid(), title: 'MX' },
  { id: uid(), title: 'Age' },
  { id: uid(), title: 'Outreach' },
  { id: uid(), title: 'Inboxes' },
  { id: uid(), title: 'Reply' },
  { id: uid(), title: 'Opens' },
  { id: uid(), title: 'Glock' },
  { id: uid(), title: 'DNS' },
]

const DomainSettings = () => {
  const { account } = useAccount()
  const { accountId } = useParams()
  const invalidate = useInvalidateQuery()

  const { data, isLoading } = useQuery(
    ['account-inbox-domains'],
    async () => await Api.get('inbox-domains', { account_id: accountId }),
  )

  const { data: googleAuthLink, isLoading: isGoogleLoading } = useQuery(
    ['google-directory-link', accountId],
    async () => await Api.post(`gapi-auth/generate-directory-link`, { account_id: accountId }),
  )

  const { mutate: googleDisconnect, isLoading: isDisconnecting } = useMutation(
    () =>
      Api.post(`gapi-auth/disconnect-directory-access`, {
        account_id: accountId,
      }),
    { onSuccess: () => invalidate('account') },
  )

  return (
    <>
      <PageHeader title="Domains" description="Track domain configuration, health, and deliverability." />

      <Box mb={10}>
        <DomainsTable data={data} isLoading={isLoading} columns={columns} header={<AddDomainButtonModal />} />
      </Box>

      <Grid gap="38px" gridTemplateColumns={{ base: '1fr', sm: '1fr 1fr' }}>
        <Box>
          <Text fontSize={22} lineHeight="32px" color="muted" mb={2}>
            Google Admin
          </Text>

          <WhiteSurface br={4} p={4} gap={4}>
            {account?.google_directory_access_token ? (
              <>
                <Text fontSize={14} color="gray.600">
                  Disconnect your Google Admin account to stop syncing users and groups.
                </Text>
                <OutlinedButton
                  icon={faPowerOff}
                  isLoading={isDisconnecting}
                  onClick={googleDisconnect}
                  w="max-content"
                >
                  Disconnect
                </OutlinedButton>
              </>
            ) : (
              <>
                <Text fontSize={14} color="gray.600">
                  Connect your Google Admin account to sync users and groups.
                </Text>
                <OutlinedButton
                  icon={faGoogle}
                  isLoading={isGoogleLoading}
                  as={Link}
                  href={googleAuthLink?.url}
                  target="_blank"
                  isExternal
                  w="max-content"
                >
                  Connect
                </OutlinedButton>
              </>
            )}
          </WhiteSurface>
        </Box>
        <Box>
          <Text fontSize={22} lineHeight="32px" color="muted" mb={2}>
            Microsoft Admin
          </Text>
          <WhiteSurface br={4} p={4}></WhiteSurface>
        </Box>
      </Grid>
    </>
  )
}

export default DomainSettings
