import { Stack, Text } from '@chakra-ui/react'
import { faBackward } from '@fortawesome/pro-regular-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import ModalLayout from 'components/layouts/modal-layout'
import { useSignalLeadsProps } from 'context/signal-leads-context'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import ModalToggleButtons from '../components/modal-toggle-buttons'

const RebuildLeadModal = ({ onClose }) => {
  const invalidate = useInvalidateQuery()
  const { accountId, signalId } = useParams()

  const { stage, lead, inReview, inSingleView, selectedItems, clearSelectedItems } = useSignalLeadsProps()

  const amountSelected = inSingleView && inReview ? 1 : selectedItems.length

  const [value, setValue] = useState(amountSelected > 0 ? 'selected' : 'all')

  const { mutate, isLoading } = useMutation(
    ({ leadIds }) =>
      Api.post('signal-actions/rebuild', {
        account_id: accountId,
        signal_id: signalId,
        stage: stage,
        ...(leadIds && { lead_ids: leadIds }),
      }),
    {
      onSuccess: () => {
        setTimeout(() => {
          invalidate('signal-leads')
          invalidate('stages-count')
          clearSelectedItems()
          onClose()
        }, 300)
      },
    },
  )

  const handleRebuild = () => {
    const leadIds = inSingleView && inReview ? [lead?.id] : selectedItems.map((item) => item.id)
    mutate({ leadIds: value === 'selected' ? leadIds : null })
  }

  return (
    <ModalLayout header="Rebuild Leads" isOpen={true} onClose={onClose} size="lg">
      <Stack align="center" spacing={6} mb={4}>
        <Text textAlign="center" maxW={411}>
          Rebuild leads to verify and prospect again. This is usually done after you change your Search
          Queries.
        </Text>

        <ModalToggleButtons value={value} setValue={setValue} />

        <OutlinedButton
          onClick={handleRebuild}
          isLoading={isLoading}
          isDisabled={amountSelected === 0 && value === 'selected'}
          icon={faBackward}
        >
          Rebuild Leads
        </OutlinedButton>
      </Stack>
    </ModalLayout>
  )
}

export default RebuildLeadModal
