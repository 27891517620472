import { Grid, GridItem } from '@chakra-ui/react'
import MetaData from 'components/meta-data'
import PageHeader from 'components/page-header'
import CampaignsGraph from './components/campaigns-graph'
import SendingQueueTable from './components/sending-queue-table'
import SyncCampaignsButton from './components/sync-campaigns-button'

const DashboardPage = () => {
  return (
    <>
      <MetaData title="Dashboard" />

      <PageHeader
        title="Dashboard"
        description="Birds eye view of campaign performance and sending queues"
        button={<SyncCampaignsButton />}
      />

      <Grid templateColumns="repeat(4, 1fr)" gap={4}>
        <GridItem colSpan={3}>
          <CampaignsGraph />
        </GridItem>

        <GridItem colSpan={1}>
          <SendingQueueTable />
        </GridItem>
      </Grid>
    </>
  )
}

export default DashboardPage
