import { IconButton } from '@chakra-ui/react'

const StyledIconButton = ({ h = 2, color = 'gray.300', children, ...props }) => {
  return (
    <IconButton
      h={h}
      color={color}
      bg="none"
      _hover={{ bg: 'none' }}
      minWidth="none"
      border="none"
      {...props}
    >
      {children}
    </IconButton>
  )
}

export default StyledIconButton
