import { Box, Flex, Wrap } from '@chakra-ui/react'
import SelectMenu from 'components/select-menu'
import { getQueryFilterOptions, isMissingOrExists } from '../utils'
import ClosableTag from './closable-tag'
import RenderInputAndButton from './render-input-and-button'
import RenderTags from './render-tags'

const normalizedActions = {
  includes_any: { title: 'Any' },
  includes_all: { title: 'All' },
  excludes: { title: 'Exclude' },
  exists: { title: 'Exists' },
  greater_than: { title: 'Greater Than' },
  less_than: { title: 'Less Than' },
  equals: { title: 'Equals' },
  missing: { title: 'Missing' },
}

const RenderFilterRow = ({
  traitIndex,
  fieldIndex,
  filter,
  removeFilter,
  addFilterValue,
  addKeywordValue,
  removeFilterValue,
  selectAction,
}) => {
  return (
    <Flex gap="10px" direction={{ base: 'column', md: 'row' }}>
      <Box w={302}>
        <ClosableTag
          w={302}
          bg="none"
          bc="gray.200"
          fw={400}
          onClick={() => removeFilter(traitIndex, fieldIndex)}
        >
          {filter.title}
        </ClosableTag>
      </Box>

      <Box w={40}>
        <SelectMenu
          selected={normalizedActions?.[filter?.action]}
          options={getQueryFilterOptions(filter.type)}
          handleSelect={(value) => selectAction(value, traitIndex, fieldIndex)}
          h={8}
          w={40}
          fs={14}
          fw={400}
          lh="20px"
          color="gray.700"
          isQueryBuilder
        />
      </Box>

      {!isMissingOrExists(filter.action) && (
        <Wrap>
          <RenderTags
            filter={filter}
            fieldIndex={fieldIndex}
            traitIndex={traitIndex}
            removeFilterValue={removeFilterValue}
          />
          <RenderInputAndButton
            filter={filter}
            fieldIndex={fieldIndex}
            traitIndex={traitIndex}
            addKeywordValue={addKeywordValue}
            addFilterValue={addFilterValue}
          />
        </Wrap>
      )}
    </Flex>
  )
}

export default RenderFilterRow
