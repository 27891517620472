import { Flex, FormControl, FormLabel, Icon, Input, Stack, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StandardButton from 'components/buttons/standard-button'
import { RadioCard, RadioCardGroup } from 'components/radio-card'
import { SignalIcon } from 'features/signal/constants/signal-icons'
import { useState } from 'react'
import uid from 'utils/uid'
import useCreateSignal from '../hooks/use-create-signal'

const queryTypeCards = [
  {
    id: uid(),
    title: 'Companies',
    description: 'Search for companies based on categories and industries',
    value: 'companies',
    icon: SignalIcon.companies,
  },
  {
    id: uid(),
    title: 'Contacts',
    description: 'Search for companies based on categories and industries',
    value: 'positions',
    icon: SignalIcon.positions,
  },
  {
    id: uid(),
    title: 'Topics',
    description: 'Use OpenAI Embeddings to search for topics on websites',
    value: 'companies_pinecone',
    icon: SignalIcon.companies_pinecone,
  },
  {
    id: uid(),
    title: 'Job Postings',
    description: 'Search for companies that have active job postings',
    value: 'job_postings',
    icon: SignalIcon.job_postings,
  },
  {
    id: uid(),
    title: 'Fundings',
    description: 'Search for companies with recent funding events',
    value: 'fundings',
    icon: SignalIcon.fundings,
  },
  {
    id: uid(),
    title: 'Demographics',
    description: 'Search for companies with specific employee types',
    value: 'demographics',
    icon: SignalIcon.demographics,
  },
  {
    id: uid(),
    title: 'Events',
    description: 'Custom event tracking for companies',
    value: 'events',
    icon: SignalIcon.events,
  },
]

const CreateBlankSignalForm = ({ isTemplate }) => {
  const [signalName, setName] = useState('')
  const [queryType, setQueryType] = useState('companies')

  const { createSignal, isLoading } = useCreateSignal()

  const onSubmit = (e) => {
    e.preventDefault()
    createSignal({ signalName, queryType, isTemplate })
  }

  return (
    <form onSubmit={onSubmit}>
      <FormControl isRequired>
        <FormLabel>Name</FormLabel>
        <Input
          type="text"
          placeholder="Signal Name"
          value={signalName}
          onChange={(e) => setName(e.target.value)}
          mb="34px"
          autoFocus
        />
      </FormControl>

      <FormLabel>Query Type</FormLabel>
      <RadioCardGroup spacing="3" mb="34px" onChange={setQueryType} defaultValue="companies">
        {queryTypeCards.map((type) => (
          <RadioCard key={type.id} value={type.value}>
            <Flex align="center" gap={3}>
              <Icon icon={type.icon} as={FontAwesomeIcon} w={50} h={10} />
              <Stack gap={0}>
                <Text color="fg.emphasized" fontWeight="medium" fontSize="sm">
                  {type.title}
                </Text>
                <Text color="fg.muted" textStyle="sm">
                  {type.description}
                </Text>
              </Stack>
            </Flex>
          </RadioCard>
        ))}
      </RadioCardGroup>

      <StandardButton type="submit" w="100%" tabIndex="2" isLoading={isLoading}>
        Create Signal
      </StandardButton>
    </form>
  )
}

export default CreateBlankSignalForm
