import { useCallback, useState } from 'react'

const useSortItems = ({ col }) => {
  const [sortConfig, setSortConfig] = useState({ column: col, direction: 'DESC' })

  const handleSort = useCallback(
    (column, direction) => {
      if (column.isFixed || column.isHidden) return
      const { filterType, display } = column
      setSortConfig({
        column: filterType ? `${filterType}.${display}` : display,
        direction: direction,
      })
    },
    [sortConfig],
  )

  return { sortConfig, handleSort }
}

export default useSortItems
