import { Button, Icon } from '@chakra-ui/react'
import { faThumbsUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ApproveButton = ({ children, onClick, ...props }) => (
  <Button
    variant="outline"
    onClick={onClick}
    h={10}
    lineHeight="24px"
    color="#51BA72"
    // borderColor="#51BA72"
    // _hover={{ bg: '#CBFFDB' }}
    leftIcon={<Icon icon={faThumbsUp} as={FontAwesomeIcon} w="18px" h="18px" color="#51BA72" />}
    {...props}
  >
    {children}
  </Button>
)

export default ApproveButton
