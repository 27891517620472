import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

const SyncCampaignsButton = () => {
  const { accountId } = useParams()
  const invalidate = useInvalidateQuery()

  const [isLoading, setIsLoading] = useState(false)

  const { mutateAsync: syncQuickMail } = useMutation(() =>
    Api.post('quickmail/sync-campaigns', { account_id: accountId }),
  )

  const { mutateAsync: syncSkyLead } = useMutation(() =>
    Api.post('skylead/sync-campaigns', { account_id: accountId }),
  )

  const syncCampaigns = async () => {
    setIsLoading(true)
    try {
      await Promise.all([syncQuickMail(), syncSkyLead()])
      await invalidate('campaigns')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <OutlinedButton icon={faArrowsRotate} isLoading={isLoading} onClick={syncCampaigns} bg="tranparent">
      Sync
    </OutlinedButton>
  )
}

export default SyncCampaignsButton
