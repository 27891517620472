import { Avatar, Flex, Icon, Tag, Td, Text, Tr } from '@chakra-ui/react'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TableLayout from 'components/layouts/table-layout'
import uid from 'utils/uid'
import ResendTableCell from './resend-table-cell'

const columns = [
  { id: uid(), title: 'Name' },
  { id: uid(), title: 'Email' },
  { id: uid(), title: 'Handle' },
  { id: uid(), title: 'Activated' },
]

const TeamTable = ({ data, isLoading, header }) => {
  if (data?.results?.length === 0) {
    return (
      <Text color="muted" fontSize={14}>
        Nothing here
      </Text>
    )
  }

  return (
    <TableLayout columns={columns} isLoading={isLoading} header={header}>
      {data?.results?.map((user) => (
        <Tr key={user?.id}>
          <Td>
            <Flex align="center" gap={2}>
              <Avatar
                size="xs"
                bg="blue.600"
                color="white"
                name={user?.full_name}
                src={user?.profile_image}
              />
              <Text color="muted">{user.full_name}</Text>
              {user?.is_outreach && (
                <Tag size="sm" colorScheme="green">
                  Outreach Seat
                </Tag>
              )}
              {user?.is_primary_contact && (
                <Tag size="sm" colorScheme="red">
                  Primary Contact
                </Tag>
              )}
            </Flex>
          </Td>
          <Td>
            <Text color="muted">{user.email}</Text>
          </Td>
          <Td>{user.handle && <Text color="muted">@{user.handle}</Text>}</Td>

          <Td>
            {user?.activated ? (
              <Icon icon={faCheck} as={FontAwesomeIcon} color="muted" />
            ) : user?.invite_token ? (
              <ResendTableCell account={user} />
            ) : null}
          </Td>
        </Tr>
      ))}
    </TableLayout>
  )
}

export default TeamTable
