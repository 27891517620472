import { Flex, Icon, Stack, Text } from '@chakra-ui/react'
import { faBooks, faFile, faPalette } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StandardButton from 'components/buttons/standard-button'
import SubPageLayout from 'components/layouts/sub-page-layout'
import WhiteSurface from 'components/white-surface'
import { SignalIcon } from 'features/signal/constants/signal-icons'
import { useNavigate } from 'react-router-dom'

const CreateSignalPage = () => {
  const navigate = useNavigate()

  const createSignalOptions = [
    {
      title: 'Blank Signal',
      description: 'Start with a blank signal to build your ideal list of prospects.',
      icon: faFile,
      buttonText: 'Create',
      onClick: () => navigate(`blank`),
    },
    {
      title: 'Import Domains ',
      description: 'Provide a list of company domains that can be prospected.',
      icon: SignalIcon.import_domains,
      buttonText: 'Import',
      onClick: () => navigate(`import-domains`),
    },
    {
      title: 'Template Library',
      description: 'Select a template from our library of signals as a starting point.',
      icon: faBooks,
      buttonText: 'Browse',
      onClick: () => navigate(`template`),
    },
    {
      title: 'Custom Request',
      description: 'Share requirements with our team, let us build custom signals.',
      icon: faPalette,
      buttonText: 'Submit',
      onClick: () => navigate(`import`),
      isDisabled: true,
    },
  ]

  return (
    <SubPageLayout
      title="Create Signal"
      description="Create a Data Signal that will run daily and find new leads."
    >
      <Stack spacing="21px">
        {createSignalOptions.map((option, idx) => (
          <WhiteSurface
            key={idx}
            p={6}
            gap={6}
            minW={{ lg: 837 }}
            direction={{ base: 'column', md: 'row' }}
            justify="space-between"
          >
            <Flex align="center" gap={3}>
              <Icon icon={option.icon} as={FontAwesomeIcon} w="50px" h="40px" />
              <Stack spacing="1">
                <Text textStyle="lg" fontWeight="medium">
                  {option.title}
                </Text>
                <Text textStyle="sm" color="fg.muted">
                  {option.description}
                </Text>
              </Stack>
            </Flex>

            <StandardButton onClick={option.onClick} isDisabled={option.isDisabled}>
              {option.buttonText}
            </StandardButton>
          </WhiteSurface>
        ))}
      </Stack>
    </SubPageLayout>
  )
}

export default CreateSignalPage
