import {
  Button,
  Icon,
  Input,
  Modal,
  ModalContent,
  ModalOverlay,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo, useState } from 'react'
import camelCaseToTitleCase from 'utils/camel-to-title-case'

const QueryBuilderFilterModal = ({ isOpen, onClose, filterFields, selectFilter }) => {
  const [inputValue, setInputValue] = useState('')

  const filteredColumns = useMemo(
    () =>
      Object.entries(filterFields)
        .flatMap(([key, value]) => value.map((item) => ({ ...item, key })))
        .filter((item) => item.title.toLowerCase().includes(inputValue.toLowerCase())),
    [filterFields, inputValue],
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />

      <ModalContent>
        <Stack borderBottom="1px" borderColor="gray.200" p="10px">
          <Input
            type="text"
            placeholder="Search"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            aria-label="Search Column Input"
          />
        </Stack>

        {inputValue.length > 0 ? (
          <Stack h={420} p={4} className="hover-scroll">
            {filteredColumns.length === 0 ? (
              <Text fontSize={14}>No column found...</Text>
            ) : (
              filteredColumns.map((col, idx) => (
                <Button
                  key={idx}
                  variant="ghost"
                  display="flex"
                  justifyContent="start"
                  fontSize={14}
                  color="gray.700"
                  fontWeight={500}
                  _hover={{ bg: 'gray.50' }}
                  onClick={() => selectFilter(col)}
                  flexShrink={0}
                >
                  <Text color="gray.700" opacity={0.6} mr={2}>
                    {camelCaseToTitleCase(col.key)}
                  </Text>
                  <Icon icon={faChevronRight} as={FontAwesomeIcon} color="muted" h={4} mr={2} />
                  <Text color="gray.700">{col.title}</Text>
                </Button>
              ))
            )}
          </Stack>
        ) : (
          <Tabs variant="unstyled" display="flex" h="100%">
            <TabList p="10px" gap={1} flexDirection="column" borderRight="1px" borderColor="gray.200">
              {Object.keys(filterFields)?.map((key, index) => (
                <Tab
                  px="10px"
                  key={index}
                  w={200}
                  borderRadius="8px"
                  justifyContent="space-between"
                  _hover={{ bg: 'gray.50' }}
                  _selected={{ bg: 'gray.100' }}
                >
                  <Text color="gray.700">{camelCaseToTitleCase(key)}</Text>
                  <Icon icon={faChevronRight} as={FontAwesomeIcon} color="muted" h={4} />
                </Tab>
              ))}
            </TabList>

            <TabPanels h={420} py={2} overflowY="auto">
              {Object.values(filterFields)?.map((fields, index) => (
                <TabPanel key={index} display="flex" flexDirection="column" p={0}>
                  {fields?.map((field, index) => (
                    <Button
                      key={index}
                      variant="ghost"
                      display="flex"
                      justifyContent="start"
                      fontSize={14}
                      color="gray.700"
                      fontWeight={500}
                      borderRadius={0}
                      _hover={{ bg: 'gray.50' }}
                      onClick={() => selectFilter(field)}
                    >
                      {field.title}
                    </Button>
                  ))}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        )}
      </ModalContent>
    </Modal>
  )
}

export default QueryBuilderFilterModal
