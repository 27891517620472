import { Stack, Text } from '@chakra-ui/react'
import { faTrumpet } from '@fortawesome/pro-duotone-svg-icons'
import DeleteButton from 'components/buttons/delete-button'
import ModalLayout from 'components/layouts/modal-layout'
import { useSignalLeadsProps } from 'context/signal-leads-context'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import ModalToggleButtons from '../components/modal-toggle-buttons'

const DeleteLeadModal = ({ onClose }) => {
  const invalidate = useInvalidateQuery()
  const { accountId, signalId } = useParams()

  const { stage, lead, inReview, inSingleView, selectedItems, clearSelectedItems } = useSignalLeadsProps()

  const amountSelected = inReview && inSingleView ? 1 : selectedItems.length

  const [value, setValue] = useState(amountSelected > 0 ? 'selected' : 'all')

  const { mutate, isLoading } = useMutation(
    ({ leadIds }) =>
      Api.post('signal/delete-leads', {
        account_id: accountId,
        signal_id: signalId,
        stage: stage,
        ...(leadIds && { lead_ids: leadIds }),
      }),
    {
      onSuccess: () => {
        setTimeout(() => {
          invalidate('signal-leads')
          invalidate('stages-count')
          clearSelectedItems()
          onClose()
        }, 300)
      },
    },
  )

  const handleDelete = () => {
    const leadIds = inReview && inSingleView ? [lead?.id] : selectedItems.map((item) => item.id)
    mutate({ leadIds: value === 'selected' ? leadIds : null })
  }

  return (
    <ModalLayout header="Delete Leads" isOpen={faTrumpet} onClose={onClose} size="lg">
      <Stack align="center" spacing={6} mb={4}>
        <Text textAlign="center" maxW={320}>
          Deleting leads is a permanent action that cannot be undone.
        </Text>

        <ModalToggleButtons value={value} setValue={setValue} />

        <DeleteButton
          onClick={handleDelete}
          isLoading={isLoading}
          isDisabled={amountSelected === 0 && value === 'selected'}
        >
          Delete Leads
        </DeleteButton>
      </Stack>
    </ModalLayout>
  )
}

export default DeleteLeadModal
