import { faChartPie, faDownload } from '@fortawesome/pro-regular-svg-icons'
import SelectMenu from 'components/select-menu'
import { useCallback, useState } from 'react'
import AnalyzeCampaignModal from '../action-modals/analyze-campaign-modal'
import DownloadLeadModal from '../action-modals/download-lead-modal'

const LeadActionsDropdown = ({ tableType, selectedItems, query, user }) => {
  const actionOptions = [
    { title: 'Download', value: 'download', icon: faDownload, color: '#959AA4' },
    ...(tableType === 'all'
      ? [{ value: 'Divider' }, { title: 'Analyze', value: 'analyze', icon: faChartPie, color: '#959AA4' }]
      : []),
  ]

  const [modal, setModal] = useState({ isOpen: false, action: null })

  const onClose = () => setModal({ isOpen: false, action: null })

  const handleSelect = useCallback((option) => {
    setModal({ isOpen: true, action: option.value })
  }, [])

  return (
    <>
      <SelectMenu
        label={`Actions ${selectedItems?.length ? `(${selectedItems?.length})` : ''}`}
        options={actionOptions}
        handleSelect={handleSelect}
        type="icon"
        keepLabel
      />

      {modal.isOpen &&
        (() => {
          switch (modal.action) {
            case 'download':
              return <DownloadLeadModal query={query} onClose={onClose} />

            case 'analyze':
              return <AnalyzeCampaignModal user={user} onClose={onClose} />

            default:
              return null
          }
        })()}
    </>
  )
}

export default LeadActionsDropdown
