import { Icon } from '@chakra-ui/react'
import { faCheck, faClose } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DomainAuthIcon = ({ value, size = 4 }) => {
  return (
    <Icon
      icon={value ? faCheck : faClose}
      color={value ? '#51BA72' : '#F35D52'}
      h={size}
      w={size}
      as={FontAwesomeIcon}
    />
  )
}

export default DomainAuthIcon
