import { FormControl, FormLabel, HStack, Input } from '@chakra-ui/react'
import StandardButton from 'components/buttons/standard-button'
import CustomLink from 'components/links/custom-link'
import { useAuth } from 'context/auth-context'
import { useState } from 'react'
import { useMutation } from 'react-query'
import Api from 'utils/api'
import AuthPageLayout from '../layout/auth-page-layout'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { authenticateUser } = useAuth()

  const { mutate, isLoading } = useMutation(() => Api.post('authenticate/login', { email, password }), {
    onSuccess: (data) => {
      if (data && data.token) {
        authenticateUser(data.token)
      }
    },
  })

  const onSubmit = (e) => {
    e.preventDefault()
    mutate()
  }

  const subHeader = {
    cta: "Don't have an account?",
    linkText: 'Sign up',
    path: '/register',
  }

  return (
    <AuthPageLayout onSubmit={onSubmit} title="Log in to your account" subHeader={subHeader}>
      <FormControl id="email" isRequired>
        <FormLabel htmlFor="email">Email</FormLabel>
        <Input
          type="email"
          name="email"
          autoComplete="username"
          placeholder="email@example.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </FormControl>
      <FormControl id="password" isRequired>
        <FormLabel htmlFor="password">Password</FormLabel>
        <Input
          type="password"
          name="password"
          autoComplete="current-password"
          placeholder="**********"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </FormControl>

      <HStack justify="end">
        <CustomLink path="/reset-password" text="Forgot password?" size="sm" />
      </HStack>

      <StandardButton type="submit" isLoading={isLoading}>
        Login
      </StandardButton>
    </AuthPageLayout>
  )
}

export default Login
