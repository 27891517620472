import { Stack } from '@chakra-ui/react'
import ArchiveSignal from './components/archive'
import BasicSettings from './components/basic'
import ChangeSignalType from './components/change-type'
import CloneSignal from './components/clone'
import DeleteSignal from './components/delete'
import Prospecting from './components/prospecting'

const SignalSettings = () => (
  <Stack mx="auto" maxW={651} spacing="44px">
    <BasicSettings />
    <ChangeSignalType />
    <Prospecting />
    <ArchiveSignal />
    <CloneSignal />
    <DeleteSignal />
  </Stack>
)

export default SignalSettings
