import { Text, Tr } from '@chakra-ui/react'
import ColumnButton from 'components/column-table/components/column-button'
import useTableColumns from 'components/column-table/hooks/use-table-columns'
import TableLayout from 'components/layouts/table-layout'
import LeadDetailsModal from 'features/signal-leads/components/lead-details-modal'
import { useState } from 'react'
import RenderTableCell from './components/render-table-cell'

const ColumnTable = ({ type, isLoading, data, columnFields, header }) => {
  const [leadModal, setLeadModal] = useState({ lead: null, isOpen: false })

  const { activeColumns, addColumn, removeColumn, dragColumn } = useTableColumns(type)

  if (data == null) {
    return null
  }

  if (data?.results?.length === 0) {
    return <Text>No results found</Text>
  }

  return (
    <>
      <TableLayout
        data={data}
        isLoading={isLoading}
        columns={activeColumns}
        removeColumn={removeColumn}
        header={
          <>
            <ColumnButton
              columns={columnFields}
              activeColumns={activeColumns}
              addColumn={addColumn}
              removeColumn={removeColumn}
              dragColumn={dragColumn}
            />
            {header}
          </>
        }
      >
        {data?.results?.map((result) => (
          <Tr
            key={result.id}
            cursor="pointer"
            onClick={() => setLeadModal({ lead: result, isOpen: true })}
            _hover={{ backgroundColor: 'gray.50' }}
            transition="background-color 0.3s ease"
          >
            {activeColumns?.map((col) => (
              <RenderTableCell key={col.id} col={col} lead={result} type={type} />
            ))}
          </Tr>
        ))}
      </TableLayout>

      {leadModal.isOpen && (
        <LeadDetailsModal
          lead={leadModal.lead}
          isOpen={leadModal.isOpen}
          onClose={() => setLeadModal({ ...leadModal, isOpen: false })}
        />
      )}
    </>
  )
}

export default ColumnTable
