import {
  Box,
  Circle,
  createIcon,
  Icon,
  Stack,
  useId,
  useRadio,
  useRadioGroup,
  useStyleConfig,
} from '@chakra-ui/react'
import { Children, cloneElement, isValidElement, useMemo } from 'react'

export const RadioCardGroup = (props) => {
  const { children, name, defaultValue, value, onChange, ...rest } = props
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  })
  const cards = useMemo(
    () =>
      Children.toArray(children)
        .filter(isValidElement)
        .map((card) => {
          return cloneElement(card, {
            radioProps: getRadioProps({
              value: card.props.value,
            }),
          })
        }),
    [children, getRadioProps],
  )
  return <Stack {...getRootProps(rest)}>{cards}</Stack>
}

export const RadioCard = (props) => {
  const { radioProps, children, ...rest } = props
  const { getInputProps, getRadioProps, getLabelProps, state } = useRadio(radioProps)
  const id = useId(undefined, 'radio-button')
  const styles = useStyleConfig('RadioCard', props)
  const inputProps = getInputProps()
  const checkboxProps = getRadioProps()
  const labelProps = getLabelProps()

  return (
    <Box as="label" cursor="pointer" {...labelProps}>
      <input {...inputProps} aria-labelledby={id} />
      <Box tabIndex="1" sx={styles} {...checkboxProps} {...rest}>
        <Stack direction="row">
          <Box flex="1">{children}</Box>
          {state.isChecked ? (
            <Circle bg="accent" size="4">
              <Icon as={CheckIcon} boxSize="2.5" color="fg.inverted" />
            </Circle>
          ) : (
            <Circle borderWidth="2px" size="4" />
          )}
        </Stack>
      </Box>
    </Box>
  )
}

export const CheckIcon = createIcon({
  displayName: 'CheckIcon',
  viewBox: '0 0 12 10',
  path: (
    <polyline
      fill="none"
      strokeWidth="2px"
      stroke="white"
      strokeDasharray="16px"
      points="1.5 6 4.5 9 10.5 1"
    />
  ),
})
