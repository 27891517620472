import { Divider, Flex, Grid, Icon, Image, Link, Stack, Tag, Text, Wrap } from '@chakra-ui/react'
import { faFacebook, faGithub, faGoogle, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faBuilding } from '@fortawesome/pro-duotone-svg-icons'
import {
  faBrowser,
  faCalendar,
  faDollarSign,
  faEarthAmericas,
  faLink,
  faLocationDot,
  faMoneyBillAlt,
  faUsers,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DeclineButton from 'components/buttons/decline-button'
import { useState } from 'react'
import { Link as ReactLink } from 'react-router-dom'
import getCompanyImage from 'utils/get-company-image'
import stringify from 'utils/stringify'
import useLeadActions from '../hooks/use-lead-actions'
import { RenderData, RenderLink } from '../lead-single-view'

const SingleCompanyData = ({ lead, stage, onClose }) => {
  const { declineLeads, isDeclining } = useLeadActions()

  const [isImageLoaded, setIsImageLoaded] = useState(true)

  const company = lead?.company ?? lead
  const leadId = lead?.id

  if (!lead) return null

  return (
    <Stack spacing={7}>
      <Flex justify="space-between" gap={5}>
        <Flex gap={4}>
          {isImageLoaded ? (
            <Image
              src={getCompanyImage(company.domain)}
              w={16}
              h={16}
              objectFit="contain"
              borderRadius={4}
              border="1px"
              borderColor="#EDEDED"
              alt="Company logo"
              onLoad={() => setIsImageLoaded(true)}
              onError={() => setIsImageLoaded(false)}
            />
          ) : (
            <Icon icon={faBuilding} as={FontAwesomeIcon} w="auto" h={16} />
          )}
          <Stack spacing={0}>
            <Link as={ReactLink} to={`/companies/${company.id}`} fontSize={28} lineHeight="32px">
              {company.name}
            </Link>
            <Link href={company.url} color="#3182CE" isExternal textDecoration="underline">
              {company.domain}
            </Link>
          </Stack>
        </Flex>

        {['review', 'complete', 'archived'].includes(stage) && (
          <DeclineButton
            isLoading={isDeclining}
            onClick={() => declineLeads([leadId], { onSuccess: onClose && onClose() })}
            minW={114}
          >
            Decline
          </DeclineButton>
        )}
      </Flex>

      <Text fontSize={14} lineHeight="24px" color="muted">
        {company.description}
      </Text>

      <Grid templateColumns="repeat(2, 1fr)">
        <Stack spacing="13px">
          <RenderData icon={faBuilding} data={company.type === 'private'} text="Business" />
          <RenderData
            icon={faUsers}
            data={company.employees}
            text={`${stringify(company?.employees)} Employees`}
          />
          <RenderData icon={faCalendar} data={company.founded} text={`Founded in ${company.founded}`} />
          <RenderData icon={faEarthAmericas} data={company.country} />
          <RenderData icon={faLocationDot} data={company.location} />
          <RenderData icon={faGoogle} data={company.google_rank} text={`${company.google_rank} Page Rank`} />
          <RenderData
            icon={faMoneyBillAlt}
            data={company.raised}
            text={`$${Number(company.raised)?.toLocaleString()} in Total Funding`}
          />
          <RenderData icon={faDollarSign} data={company.funding_stage} />
        </Stack>

        <Stack spacing="13px">
          <RenderLink name="LinkedIn" link={company.linkedin_link} icon={faLinkedin} />
          <RenderLink name="Facebook" link={company.facebook_link} icon={faFacebook} />
          <RenderLink name="Twitter" link={company.twitter_link} icon={faTwitter} />
          <RenderLink name="Github" link={company.github_link} icon={faGithub} />
          <RenderLink name="Clutch" link={company.clutch_link} icon={faLink} />
          <RenderLink name="B Corportation" link={company.bcorp_link} icon={faLink} />
          <RenderLink link={company.resolved_doamin} icon={faBrowser} />
        </Stack>
      </Grid>

      <RenderTags title="Categories" data={company.personas} />
      <RenderTags title="Business Model" data={company.business_model} />
      <RenderTags title="Topics" data={company.google_classify} />
      <RenderTags title="Technologies" data={company.technologies} />
      <RenderTags title="LinkedIn Industry" data={company.linkedin_industry} />
      <RenderTags title="Regions" data={company.regions} />
      <RenderTags title="Tags" data={company.tags} />
      <RenderTags title="ML Tags" data={company.tags2} />

      <RenderVariables lead={lead} />
    </Stack>
  )
}

const CustomTag = ({ children }) => (
  <Tag
    h={8}
    px={2}
    w="max-content"
    bg="gray.100"
    border="1px"
    borderColor="gray.300"
    borderRadius="8px"
    color="gray.700"
    fontWeight={400}
  >
    {children}
  </Tag>
)

const RenderTags = ({ title, data }) => {
  if (!data || !data.length) return null

  return (
    <Flex gap={2}>
      <Text fontSize={14} fontWeight={700} color="muted" minW={145}>
        {title}
      </Text>
      <Wrap>
        {Array.isArray(data) ? (
          data.map((value, idx) => <CustomTag key={idx}>{value}</CustomTag>)
        ) : (
          <CustomTag>{data}</CustomTag>
        )}
      </Wrap>
    </Flex>
  )
}

const RenderVariables = ({ lead }) => {
  const {
    variable_1,
    variable_2,
    variable_3,
    variable_4,
    variable_intro,
    variable_postscript,
    variable_linkedin_intro,
  } = lead

  const hasVariable = Object.keys(lead).some((key) => key.startsWith('variable') && lead[key])

  return (
    <>
      {hasVariable && <Divider />}

      {variable_1 && (
        <Flex gap={8} fontSize={14}>
          <Text fontWeight={700} color="muted" minW={155}>
            Variable 1
          </Text>
          <Text>{variable_1}</Text>
        </Flex>
      )}
      {variable_2 && (
        <Flex gap={8} fontSize={14}>
          <Text fontWeight={700} color="muted" minW={155}>
            Variable 2
          </Text>
          <Text>{variable_2}</Text>
        </Flex>
      )}
      {variable_3 && (
        <Flex gap={8} fontSize={14}>
          <Text fontWeight={700} color="muted" minW={155}>
            Variable 3
          </Text>
          <Text>{variable_3}</Text>
        </Flex>
      )}
      {variable_4 && (
        <Flex gap={8} fontSize={14}>
          <Text fontWeight={700} color="muted" minW={155}>
            Variable 4
          </Text>
          <Text>{variable_4}</Text>
        </Flex>
      )}
      {variable_intro && (
        <Flex gap={8} fontSize={14}>
          <Text fontWeight={700} color="muted" minW={155}>
            Variable Intro
          </Text>
          <Text>{variable_intro}</Text>
        </Flex>
      )}
      {variable_postscript && (
        <Flex gap={8} fontSize={14}>
          <Text fontWeight={700} color="muted" minW={155}>
            Variable Postscript
          </Text>
          <Text>{variable_postscript}</Text>
        </Flex>
      )}
      {variable_linkedin_intro && (
        <Flex gap={8} fontSize={14}>
          <Text fontWeight={700} color="muted" minW={155}>
            Variable LinkedIn Intro
          </Text>
          <Text>{variable_linkedin_intro}</Text>
        </Flex>
      )}
    </>
  )
}

export default SingleCompanyData
