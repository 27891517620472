import { Divider, FormControl, FormLabel, Switch, Textarea } from '@chakra-ui/react'
import SelectMenu from 'components/select-menu'
import WhiteSurface from 'components/white-surface'
import { useSignal } from 'context/signal-context'
import useUpdateSignal from 'features/signal/hooks/use-update-signal'
import { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import Api from 'utils/api'

const CreatePrompt = () => {
  const { signal } = useSignal()
  const { updateSignal } = useUpdateSignal()

  const [isScorable, setIsScorable] = useState(signal?.is_scorable ?? false)
  const [promptText, setPromptText] = useState(signal?.score_prompt ?? '')

  const textAreaRef = useRef(null)

  const { data: tokens } = useQuery(['tokens'], async () => {
    const data = await Api.get('signal-lead-prompts-get-tokens')
    return data?.results?.map((token) => ({ title: token, value: token }))
  })

  const handleToggleScoring = () => {
    const updatedScorable = !isScorable
    setIsScorable(updatedScorable)
    updateSignal({ is_scorable: updatedScorable })
  }

  const handleSelectToken = (token) => {
    if (token && textAreaRef.current) {
      const { selectionStart, selectionEnd } = textAreaRef.current
      const beforeText = textAreaRef.current.value.substring(0, selectionStart)
      const afterText = textAreaRef.current.value.substring(selectionEnd)
      textAreaRef.current.value = `${beforeText}{{${token}}}${afterText}`
      const newPos = selectionStart + `{{${token}}}`.length
      textAreaRef.current.setSelectionRange(newPos, newPos)
      setPromptText(textAreaRef.current.value)
    }
  }

  useEffect(() => {
    const autosave = setTimeout(() => updateSignal({ score_prompt: promptText }), 1000)
    return () => clearTimeout(autosave)
  }, [promptText])

  return (
    <WhiteSurface gap={3} p={4}>
      <FormControl display="flex" alignItems="center">
        <Switch id="enable-lead-scoring" isChecked={isScorable} onChange={handleToggleScoring} mr={4} />
        <FormLabel htmlFor="enable-lead-scoring" mb="0">
          Enable Lead Scoring
        </FormLabel>
        <SelectMenu
          label="Insert Tokens"
          options={tokens ?? []}
          handleSelect={(token) => handleSelectToken(token.value)}
          keepLabel
        />
      </FormControl>

      <Divider my={4} />

      <Textarea
        placeholder="Does this agency provide a service that we can work with?"
        value={promptText}
        onChange={(e) => setPromptText(e.target.value)}
        ref={textAreaRef}
        rows={20}
      />
    </WhiteSurface>
  )
}

export default CreatePrompt
