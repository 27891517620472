import { FormLabel, Stack, Switch, Text } from '@chakra-ui/react'
import { faPlay } from '@fortawesome/pro-regular-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import ModalLayout from 'components/layouts/modal-layout'
import SelectMenu from 'components/select-menu'
import { useSignalLeadsProps } from 'context/signal-leads-context'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import useNotification from 'hooks/use-notification'
import { useCallback, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import pluralize from 'utils/pluralize'
import ModalToggleButtons from '../components/modal-toggle-buttons'

const RunAdminPromptModal = ({ onClose }) => {
  const { signalId } = useParams()

  const invalidate = useInvalidateQuery()
  const { showSuccessMessage } = useNotification()
  const { stage, inReview, inSingleView, lead, selectedItems } = useSignalLeadsProps()

  const amountSelected = inSingleView && inReview ? 1 : selectedItems.length

  const [overwrite, setOverwrite] = useState(false)
  const [selectedPrompt, setSelectedPrompt] = useState(null)
  const [value, setValue] = useState(amountSelected > 0 ? 'selected' : 'all')

  const { data: prompts } = useQuery(['adminPrompts'], async () => {
    const response = await Api.get('prompts', {
      is_admin_template: true,
      resource_type: 'companies',
    })

    return response?.results?.map((prompt) => ({ title: prompt.name, ...prompt })) || []
  })

  const handleSelectPrompt = useCallback((prompt) => {
    invalidate('prompts')
    setSelectedPrompt(prompt)
  }, [])

  const { mutate, isLoading: isRunningPrompt } = useMutation(
    ({ leadIds, promptId, override }) =>
      Api.post('signal-actions/run-admin-prompt', {
        signal_id: signalId,
        stage: stage,
        prompt_id: promptId,
        override: override,
        ...(leadIds && { lead_ids: leadIds }),
      }),
    {
      onSuccess: (data) =>
        showSuccessMessage(`${data} ${pluralize('prompt', data)} have been queued up for processing`),
    },
  )

  const runPrompt = () => {
    const leadIds = inReview && inSingleView ? [lead?.id] : selectedItems.map((item) => item.id)
    mutate({
      leadIds: value === 'selected' ? leadIds : null,
      promptId: selectedPrompt?.id,
      override: overwrite,
    })
  }

  return (
    <ModalLayout header="Admin Prompt" isOpen={true} onClose={onClose} size="xl">
      <ModalToggleButtons value={value} setValue={setValue} />

      <Stack my={6} gap={6}>
        <Stack gap={6}>
          <Stack gap={0}>
            <FormLabel>Prompt</FormLabel>
            <SelectMenu
              options={prompts ?? []}
              selected={selectedPrompt}
              handleSelect={handleSelectPrompt}
              fw={400}
            />
          </Stack>

          <OutlinedButton
            onClick={runPrompt}
            isLoading={isRunningPrompt}
            isDisabled={amountSelected === 0 && value === 'selected'}
            icon={faPlay}
          >
            Run
          </OutlinedButton>

          <Stack direction="row" gap={2} alignItems="center">
            <Switch size="md" isChecked={overwrite} onChange={() => setOverwrite(!overwrite)} />
            <Text fontSize={14} color="muted">
              Overwrite existing data
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </ModalLayout>
  )
}

export default RunAdminPromptModal
