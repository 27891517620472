import { Textarea } from '@chakra-ui/react'
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import StandardButton from 'components/buttons/standard-button'
import ColumnTable from 'components/column-table/column-table-component'
import PageHeader from 'components/page-header'
import Pagination from 'components/pagination'
import useHandleSearchQuery from 'components/query-builder/hooks/use-handle-search-query'
import QueryBuilder from 'components/query-builder/query-builder-component'
import WhiteSurface from 'components/white-surface'
import { useColumnFields } from 'context/column-fields-context'
import { SignalIcon } from 'features/signal/constants/signal-icons'
import { useState } from 'react'

const Topics = () => {
  const type = 'companies_pinecone'

  const fields = useColumnFields(type)

  const [textAreaValue, setTextAreaValue] = useState('')

  const { isLoading, searchResults, handleSearchQuery, paginationData } = useHandleSearchQuery({
    queryType: type,
    overrideQuery: { keyword_query: textAreaValue },
  })

  return (
    <>
      <PageHeader title="Topics" icon={SignalIcon.topics} />

      <WhiteSurface br={8} mb={4} p={5} gap={5} border>
        <Textarea
          rows={4}
          value={textAreaValue}
          onChange={(e) => setTextAreaValue(e.target.value)}
          placeholder="Describe the types of companies that you want to target. Use language and keywords that can be found on the homepage of the company’s website."
        />

        <StandardButton
          icon={faMagnifyingGlass}
          onClick={handleSearchQuery}
          isLoading={isLoading}
          isDisabled={textAreaValue.length === 0}
          w="max-content"
        >
          Preview
        </StandardButton>
      </WhiteSurface>

      <QueryBuilder
        type={type}
        isLoading={isLoading}
        handleSearchQuery={handleSearchQuery}
        filterFields={fields}
        showSearchButton={false}
      />

      <ColumnTable
        type={type}
        isLoading={isLoading}
        data={searchResults}
        columnFields={fields}
        header={<Pagination data={paginationData} />}
      />
    </>
  )
}

export default Topics
