import PageHeader from 'components/page-header'
import CampaignLeadsTable from '../components/campaign-leads-table'

const WarmLeads = () => {
  return (
    <>
      <PageHeader
        title="Warm Leads"
        description="Leads that have responded to a campaign with interest or a qualifying question."
      />

      <CampaignLeadsTable type="warm" />
    </>
  )
}

export default WarmLeads
