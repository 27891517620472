import { format, parseISO } from 'date-fns'

function isISODateString(string) {
  // Regular expression to check if the string matches the ISO 8601 format
  const regex = /\d{4}(.\d{2}){2}(\s|T)(\d{2}.){2}\d{2}/g
  return regex.test(string)
}

export function checkAndFormatString(value) {
  if (value == null || value === 0) {
    return null
  }
  if (!isISODateString(value)) {
    return value.toLocaleString('en-US')
  }
  const date = parseISO(value)
  return format(date, 'MMM do, yyyy')
}

export function displayLinkCell(col, value) {
  return col.display === 'domain' || (typeof value === 'string' && value.startsWith('http'))
}

export function displayCustomCompanyCell(type, display) {
  return (
    ['companies', 'signal', 'query_companies', 'companies_pinecone'].includes(type) && display === 'company'
  )
}

export function getSortingClass(column, sortConfig) {
  if (!sortConfig?.column) return null

  const { filterType, display } = column ?? {}
  const selectedColumn = filterType ? `${filterType}.${display}` : display

  if (selectedColumn === sortConfig?.column) {
    return {
      content: `'${sortConfig?.direction === 'ASC' ? '▲' : '▼'}'`,
      ml: '6px',
      fontSize: '12px',
    }
  }
  return null
}
