import { Flex, Heading, Icon, Stack, Text } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PageHeader = ({ title, description, button, icon }) => {
  return (
    <Flex justify="space-between" mb={5} w="100%">
      <Flex gap={4} align="center">
        {icon && (
          <Icon
            icon={icon}
            as={FontAwesomeIcon}
            color="gray.600"
            style={{ height: description ? 55 : 30, width: 'auto' }}
          />
        )}

        <Stack spacing={1}>
          <Heading fontSize={30} fontWeight={500} lineHeight={'38px'} minH={10}>
            {title}
          </Heading>
          {description && (
            <Text color="gray.600" style={truncate}>
              {description}
            </Text>
          )}
        </Stack>
      </Flex>

      {button}
    </Flex>
  )
}

export default PageHeader

const truncate = {
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  maxWidth: '50dvw',
}
