import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { useCallback, useState } from 'react'
import Storage from 'utils/storage'

export const channelIcons = {
  email: faEnvelope,
  linkedin: faLinkedin,
}

const channelOptions = [
  { title: 'All Channels', value: null },
  { value: 'Divider' },
  { title: 'Email', value: 'email', icon: channelIcons.email },
  { title: 'LinkedIn', value: 'linkedin', icon: channelIcons.linkedin },
]

const useChannelOptions = ({ saveKey }) => {
  const [channel, setChannel] = useState(() => (saveKey && Storage.get(saveKey)) || channelOptions[0])

  const selectChannel = useCallback((channel) => {
    setChannel(channel)
    saveKey && Storage.set(saveKey, channel)
  }, [])

  return { channel, channelOptions, selectChannel }
}

export default useChannelOptions
