import useInvalidateQuery from 'hooks/use-invalidate-query'
import useNotification from 'hooks/use-notification'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

const useUpdateInboxSettings = () => {
  const { inboxId } = useParams()

  const invalidate = useInvalidateQuery()

  const { showSuccessMessage } = useNotification()

  const { mutate: updateInbox, isLoading } = useMutation(
    (payload) => Api.post(`inboxes/${inboxId}`, payload),
    {
      onSuccess: () => {
        showSuccessMessage('Inbox information updated successfully')
        invalidate('inbox-detail')
      },
    },
  )

  return { updateInbox, isLoading }
}

export default useUpdateInboxSettings
