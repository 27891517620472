import { Avatar, HStack, Icon, Tag, Td, Text, Tr } from '@chakra-ui/react'
import { faFire, faPaperPlane, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TableLayout from 'components/layouts/table-layout'
import UnderlineLink from 'components/links/underline-link'
import StatusDot from 'components/status-dot'
import { providersIcons } from 'features/domains/components/domains-table'
import capitalize from 'utils/capitalize'
import { getDiffDays } from 'utils/date'
import pluralize from 'utils/pluralize'
import PasswordTableCell from './password-table-cell'

export const inboxModeIcons = {
  outreach: { svg: faPaperPlane, color: '#959AA4' },
  monitor: { svg: faTriangleExclamation, color: '#F8C73D' },
  warm: { svg: faFire, color: '#F8C73D' },
}

const InboxesTable = ({ data, isLoading, columns, header, ...props }) => {
  return (
    <TableLayout columns={columns} isLoading={isLoading} header={header} {...props}>
      {data?.length === 0 ? (
        <Tr>
          <Td>
            <Text color="muted">No results found...</Text>
          </Td>
        </Tr>
      ) : (
        data?.map((data) => (
          <Tr key={data.id}>
            {columns?.map((col) => (
              <Td key={col.id}>
                <RenderTableCell data={data} title={col.title} />
              </Td>
            ))}
          </Tr>
        ))
      )}
    </TableLayout>
  )
}

const RenderTableCell = ({ data, title }) => {
  if (title === 'Inbox') {
    return (
      <HStack gap={2}>
        <StatusDot status={data.status} size={5} />
        <UnderlineLink
          text={data?.email}
          path={`/account/${data?.account_id}/settings/inboxes/${data?.id}`}
        />
      </HStack>
    )
  }

  if (title === 'Account') {
    return <UnderlineLink text={data?.account?.name} path={`/account/${data?.account_id}/settings/inboxes`} />
  }

  if (title === 'Status' && data?.status) {
    return (
      <HStack gap={3}>
        <StatusDot status={data.status} size={5} />
        <Text color="muted" fontSize={16} fontWeight={600}>
          {capitalize(data.status)}
        </Text>
      </HStack>
    )
  }

  if (title === 'Mode') {
    return (
      <HStack gap={3}>
        {inboxModeIcons[data?.mode].svg && (
          <Icon
            icon={inboxModeIcons[data?.mode].svg}
            as={FontAwesomeIcon}
            h={4}
            w={4}
            color={inboxModeIcons[data?.mode].color}
          />
        )}
        <Text color="muted" fontSize={16} fontWeight={600}>
          {capitalize(data.mode)}
        </Text>
      </HStack>
    )
  }

  if (title === 'Type') {
    return (
      <HStack gap={3}>
        {providersIcons[data?.type] && (
          <Icon icon={providersIcons[data?.type]} h={5} w={5} color="#828282" as={FontAwesomeIcon} />
        )}
        {/* 
        <Text color="muted" fontSize={16} fontWeight={600}>
          {capitalize(data?.type)}
        </Text>
        */}
      </HStack>
    )
  }

  if (title === 'User') {
    return (
      <HStack gap={3}>
        <Avatar bg="blue.500" color="white" src={data?.user?.profile_image} h={5} w={5} />
        <Text color="muted" fontSize={16} fontWeight={600}>
          {capitalize(data?.user?.fname)}
        </Text>
      </HStack>
    )
  }

  if (title === 'Age') {
    return (
      <Text color="muted">
        {getDiffDays(data?.date_created) > 0
          ? `${getDiffDays(data?.date_created)} ${pluralize('Day', getDiffDays(data?.date_created))}`
          : ''}
      </Text>
    )
  }

  if (title === 'Reply Rate' || title === 'Reply') {
    return <Text color="muted">{data?.reply_rate ? `${data?.reply_rate}%` : ''}</Text>
  }

  if (title === 'Open Rate' || title === 'Open') {
    return <Text color="muted">{data?.statistics?.open_rate ? `${data?.statistics?.open_rate}%` : ''}</Text>
  }

  if (title === 'Glock Score' || title === 'Glock') {
    return <Text color="muted">{data?.statistics?.inbox_rate ? `${data?.statistics?.inbox_rate}%` : ''}</Text>
  }

  if (title === 'SmartLead') {
    return <Text color="muted">{data?.smartlead_score ? data?.smartlead_score : ''}</Text>
  }

  if (title === 'Password') {
    return <PasswordTableCell password={data?.password} />
  }

  if (title === 'Connection') {
    // return <ConnectInboxTableCell id={data?.id} />
  }

  if (title === 'Connections') {
    return (
      <HStack gap={1}>
        {data?.quickmail_id && (
          <Tag color="#FBFBFB" fontSize={10} borderRadius={16} px={3} minH="20px" bg="#545ff5">
            Quickmail
          </Tag>
        )}
        {data?.replyio_id && (
          <Tag color="#FBFBFB" fontSize={10} borderRadius={16} px={3} minH="20px" bg="#005AFF">
            Reply
          </Tag>
        )}
        {data?.front_channel_id && (
          <Tag color="#FBFBFB" fontSize={10} borderRadius={16} px={3} minH="20px" bg="#CC0049">
            Front
          </Tag>
        )}
        {data?.mailflow_link && (
          <Tag color="#FBFBFB" fontSize={10} borderRadius={16} px={3} minH="20px" bg="#F8C73D">
            Warm
          </Tag>
        )}
        {data?.smartlead_link && (
          <Tag color="#FBFBFB" fontSize={10} borderRadius={16} px={3} minH="20px" bg="#5e4dcc">
            Smart
          </Tag>
        )}
      </HStack>
    )
  }

  return null
}

export default InboxesTable
