import { Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

const CustomLink = ({ path, text, size = 'md' }) => (
  <Button to={path} as={Link} variant="link" colorScheme="blue" size={size}>
    {text}
  </Button>
)

export default CustomLink
