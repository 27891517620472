import { Grid } from '@chakra-ui/react'
import CreatePrompt from './components/prompt'

const SignalSettings = () => (
  <Grid gap={10} gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr' }}>
    <CreatePrompt />
  </Grid>
)

export default SignalSettings
