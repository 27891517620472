import { useQuery } from 'react-query'
import Api from 'utils/api'

const useLeadWebsiteData = (domain) => {
  const { data: websiteData, isFetching: screenshotLoading } = useQuery(['website-data', domain], () =>
    Api.get('websites', { domain }),
  )
  const screenshot = websiteData?.results?.[0]?.screenshot_link

  return { screenshot, screenshotLoading }
}

export default useLeadWebsiteData
