import { useAuth } from 'context/auth-context'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import useNotification from 'hooks/use-notification'
import { useMutation } from 'react-query'
import Api from 'utils/api'

const useUpdateUserSettings = () => {
  const { user } = useAuth()

  const invalidate = useInvalidateQuery()

  const { showSuccessMessage } = useNotification()

  const { mutate: updateSettings, isLoading } = useMutation(
    (payload) => Api.post(`users/${user?.id}`, payload),
    {
      onSuccess: () => {
        showSuccessMessage('User information updated successfully')
        invalidate('user-detail')
      },
    },
  )

  return { updateSettings, isLoading }
}

export default useUpdateUserSettings
