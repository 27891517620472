import { Flex, Tr } from '@chakra-ui/react'
import ColumnButton from 'components/column-table/components/column-button'
import RenderTableCell from 'components/column-table/components/render-table-cell'
import useTableColumns from 'components/column-table/hooks/use-table-columns'
import TableLayout from 'components/layouts/table-layout'
import Pagination from 'components/pagination'
import { useColumnFields } from 'context/column-fields-context'
import { useSignalLeadsProps } from 'context/signal-leads-context'
import { useState } from 'react'
import FiltersDropdown from './components/filters-dropdown'
import LeadActionsDropdown from './components/lead-actions-dropdown'
import LeadDetailsModal from './components/lead-details-modal'
import QueryFilterComponent from './components/query-filter-component'
import ToggleViewButtons from './components/toggle-view-buttons'

const LeadTableView = () => {
  const type = 'signal'

  const filterKey = 'signal-leads'
  const fields = useColumnFields(filterKey)

  const {
    stage,
    approvedLeads,
    declinedLeads,
    isFetching,
    signalLeads,
    selectedItems,
    selectItem,
    sortConfig,
    handleSort,
    paginationData,
    queryBuilder,
    inReview,
  } = useSignalLeadsProps()

  const { activeColumns, addColumn, removeColumn, dragColumn } = useTableColumns(type)

  const [leadModal, setLeadModal] = useState({ lead: null, isOpen: false })

  const { paginationText } = paginationData ?? {}
  const { query, addFilter } = queryBuilder ?? {}

  const getRowColor = (lead) => {
    if (approvedLeads?.includes(lead.id)) return 'green.50'
    if (declinedLeads?.includes(lead.id)) return 'red.50'
    return 'white'
  }

  return (
    <>
      <TableLayout
        data={signalLeads}
        isLoading={isFetching}
        columns={activeColumns}
        removeColumn={removeColumn}
        selectItem={selectItem}
        selectedItems={selectedItems}
        handleSort={handleSort}
        sortConfig={sortConfig}
        paginationText={paginationText}
        header={
          <>
            <Flex gap={3}>
              <ColumnButton
                columns={fields}
                activeColumns={activeColumns}
                addColumn={addColumn}
                removeColumn={removeColumn}
                dragColumn={dragColumn}
              />
              <LeadActionsDropdown addQueryFilter={addFilter} />
              <FiltersDropdown />
            </Flex>

            <Flex gap={3}>
              <Pagination data={paginationData} onlyButton />
              {inReview && <ToggleViewButtons />}
            </Flex>
          </>
        }
        queryFilter={
          query?.base.length > 0 && <QueryFilterComponent queryBuilder={queryBuilder} filterKey={filterKey} />
        }
      >
        {signalLeads?.results?.map((lead) => (
          <Tr
            key={lead.id}
            cursor="pointer"
            onClick={() => setLeadModal({ lead: lead, isOpen: true })}
            _hover={{ backgroundColor: 'gray.50' }}
            transition="background-color 0.3s ease"
            bg={getRowColor(lead)}
            pointerEvents={[...approvedLeads, ...declinedLeads]?.includes(lead.id) && 'none'}
          >
            {activeColumns?.map((col) => (
              <RenderTableCell
                key={col.id}
                col={col}
                lead={lead}
                type={type}
                selectItem={selectItem}
                selectedItems={selectedItems}
              />
            ))}
          </Tr>
        ))}
      </TableLayout>

      {leadModal.isOpen && (
        <LeadDetailsModal
          stage={stage}
          lead={leadModal.lead}
          isOpen={leadModal.isOpen}
          onClose={() => setLeadModal({ ...leadModal, isOpen: false })}
        />
      )}
    </>
  )
}

export default LeadTableView
