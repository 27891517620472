import { Button, Icon } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { NavLink as Link, useLocation } from 'react-router-dom'

const NavLink = ({ path, children, icon, isActive, iconSize, ...props }) => {
  const { pathname } = useLocation()

  return (
    <Button
      to={path}
      as={Link}
      variant="ghost"
      leftIcon={
        icon ? (
          <Icon
            icon={icon}
            as={FontAwesomeIcon}
            style={{ width: iconSize ?? 18, height: iconSize ?? 18 }}
            color="#959AA4"
          />
        ) : null
      }
      lineHeight="24px"
      justifyContent="start"
      bg={isActive && isActive(pathname) && '#EDF2F7'}
      {...props}
    >
      {children}
    </Button>
  )
}

export default NavLink
