import useInvalidateQuery from 'hooks/use-invalidate-query'
import useNotification from 'hooks/use-notification'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

const useUpdateAccountSettings = () => {
  const { accountId } = useParams()

  const invalidate = useInvalidateQuery()

  const { showSuccessMessage } = useNotification()

  const { mutate: updateAccount, isLoading } = useMutation(
    (payload) => Api.post(`accounts/${accountId}`, payload),
    {
      onSuccess: () => {
        showSuccessMessage('Account information updated successfully')
        invalidate('account')
      },
    },
  )

  return { updateAccount, isLoading }
}

export default useUpdateAccountSettings
