import PageHeader from 'components/page-header'

const SignalsPerformance = () => {
  return (
    <>
      <PageHeader title="Performance" />
    </>
  )
}

export default SignalsPerformance
