import { Stack, Text } from '@chakra-ui/react'
import OutlinedButton from 'components/buttons/outlined-button'
import WhiteSurface from 'components/white-surface'
import useNotification from 'hooks/use-notification'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Api from 'utils/api'

const CloneSignal = () => {
  const { accountId, signalId } = useParams()

  if (!accountId) return null

  const navigate = useNavigate()
  const { showSuccessMessage } = useNotification()

  const { mutate: cloneSignal, isLoading } = useMutation(
    () => Api.post('signal/clone', { signal_id: signalId, account_id: accountId }),
    {
      onSuccess: (data) => {
        showSuccessMessage('Signal cloned successfully')
        navigate(`/account/${accountId}/signal/${data.id}/review`)
      },
    },
  )

  return (
    <Stack spacing={3}>
      <Text fontSize={22} lineHeight="32px">
        Clone
      </Text>
      <WhiteSurface br={4} p={4}>
        <Text fontSize={14} color="gray.600">
          Create a new signal by cloning this ones configuration and queries.
        </Text>
        <OutlinedButton mt={4} onClick={cloneSignal} isLoading={isLoading} w="max-content">
          Clone
        </OutlinedButton>
      </WhiteSurface>
    </Stack>
  )
}

export default CloneSignal
