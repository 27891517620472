import { Avatar, Box, HStack, Menu, MenuButton, MenuItem, MenuList, Stack, Text } from '@chakra-ui/react'
import { useAccount } from 'context/account-context'
import { useAuth } from 'context/auth-context'

const LogoutComponent = () => {
  const { account } = useAccount()
  const { user, logOut } = useAuth()

  return (
    <HStack spacing={4} minW={200} justify="end">
      <Stack align="end" spacing={0}>
        <Text color="black" fontWeight={500}>
          {user?.fname}
        </Text>
        <Text color="gray.500" fontSize={12} fontWeight={500}>
          {account?.name}
        </Text>
      </Stack>

      <Box>
        <Menu isLazy>
          <MenuButton>
            <Avatar bg="blue.500" color="white" src={user?.profile_image} />
          </MenuButton>
          <MenuList shadow="lg" p={3}>
            <MenuItem borderRadius="md" onClick={logOut}>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </HStack>
  )
}

export default LogoutComponent
