import { Button, Icon } from '@chakra-ui/react'
import { faThumbsDown } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DeclineButton = ({ children, onClick, ...props }) => (
  <Button
    variant="outline"
    onClick={onClick}
    h={10}
    lineHeight="24px"
    color="#EA6565"
    // borderColor="#EA6565"
    // _hover={{ bg: 'red.50' }}
    leftIcon={<Icon icon={faThumbsDown} as={FontAwesomeIcon} w="18px" h="18px" color="#EA6565" />}
    {...props}
  >
    {children}
  </Button>
)

export default DeclineButton
