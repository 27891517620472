import { Grid, GridItem } from '@chakra-ui/react'
import {
  faBuilding,
  faCalendarDays,
  faComments,
  faIdCardClip,
  faSackDollar,
  faUserTie,
} from '@fortawesome/pro-regular-svg-icons'
import NavLink from 'components/links/nav-link'
import MetaData from 'components/meta-data'
import WhiteSurface from 'components/white-surface'
import { Outlet } from 'react-router-dom'

const SignalIcon = {
  companies: faBuilding,
  positions: faUserTie,
  job_postings: faIdCardClip,
  events: faCalendarDays,
  fundings: faSackDollar,
  topics: faComments,
}

const navLinks = [
  { label: 'Companies', icon: SignalIcon.companies, path: '/database/companies' },
  { label: 'Job Postings', icon: SignalIcon.job_postings, path: '/database/jobs' },
  { label: 'People', icon: SignalIcon.positions, path: '/database/people' },
  { label: 'Fired', icon: SignalIcon.positions, path: '/database/fired' },
  { label: 'Events', icon: SignalIcon.events, path: '/database/events' },
  { label: 'Fundings', icon: SignalIcon.fundings, path: '/database/fundings' },
  { label: 'Topics', icon: SignalIcon.topics, path: '/database/topics' },
]

const DatabasePageLayout = () => {
  return (
    <>
      <MetaData title="Database" />

      <Grid gap="30px" mt={17} gridTemplateColumns={{ base: '1fr', md: '235px 1fr' }}>
        <GridItem w="100%">
          <WhiteSurface as="nav" br={16} p={2} gap={1}>
            {navLinks.map((link, idx) => (
              <NavLink key={idx} justifyContent="start" {...link}>
                {link.label}
              </NavLink>
            ))}
          </WhiteSurface>
        </GridItem>

        <GridItem p={1} overflow="hidden">
          <Outlet />
        </GridItem>
      </Grid>
    </>
  )
}
export default DatabasePageLayout
