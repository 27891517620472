import { Flex, HStack, Stack, Tag, Text, Wrap } from '@chakra-ui/react'
import StandardButton from 'components/buttons/standard-button'
import ModalLayout from 'components/layouts/modal-layout'
import useNotification from 'hooks/use-notification'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Api from 'utils/api'

const TemplateDetailsModal = ({ signal, isOpen, onClose }) => {
  const { id, name, description, query_signal, query_companies, query_persons } = signal

  const navigate = useNavigate()
  const { accountId } = useParams()
  const { showSuccessMessage } = useNotification()

  const { mutate: cloneTemplate, isLoading } = useMutation(
    () => Api.post('signal/template-clone', { signal_id: id, account_id: accountId }),
    {
      onSuccess: (data) => {
        showSuccessMessage('Template cloned successfully')
        navigate(`/account/${accountId}/signal/${data.id}/review`)
      },
    },
  )

  return (
    <ModalLayout header="Template" isOpen={isOpen} onClose={onClose} width={600}>
      <Stack gap={9}>
        <Stack gap={1}>
          <Text fontSize={14} fontWeight={500} color="gray.900">
            Name
          </Text>
          <Text color="muted" fontSize={27}>
            {name}
          </Text>
        </Stack>

        {description && (
          <Stack gap={1}>
            <Text fontSize={14} fontWeight={500} color="gray.900">
              Description
            </Text>
            <Text color="muted" fontSize={16}>
              {description}
            </Text>
          </Stack>
        )}

        {(query_signal?.traits?.length > 0 || query_signal?.base?.length > 0) && (
          <Stack gap={2}>
            <Text fontSize={14} fontWeight={500} color="gray.900">
              Signal
            </Text>
            <SignalBaseQuery signalQuery={query_signal?.base} />
            <SignalTraits traits={query_signal?.traits} />
          </Stack>
        )}

        {(query_companies?.traits?.length > 0 || query_companies?.base?.length > 0) && (
          <Stack gap={2}>
            <Text fontSize={14} fontWeight={500} color="gray.900">
              Company
            </Text>
            <SignalBaseQuery signalQuery={query_companies?.base} />
            <SignalTraits traits={query_companies?.traits} />
          </Stack>
        )}

        {(query_persons?.traits?.length > 0 || query_persons?.base?.length > 0) && (
          <Stack gap={2}>
            <Text fontSize={14} fontWeight={500} color="gray.900">
              Contact
            </Text>
            <SignalBaseQuery signalQuery={query_persons?.base} />
            <SignalTraits traits={query_persons?.traits} />
          </Stack>
        )}

        <StandardButton minH={10} onClick={cloneTemplate} isLoading={isLoading}>
          Use Template
        </StandardButton>
      </Stack>
    </ModalLayout>
  )
}

const SignalBaseQuery = ({ signalQuery }) =>
  signalQuery?.map(({ id, title, action, value }, index) => {
    return (
      <Flex key={`${id}-${index}`} gap={4} mb={2}>
        <HStack h={8} px={3} border="1px" borderColor="gray.200" borderRadius="8px">
          <Text fontSize={14} color="gray.700" minW={133}>
            {title}
          </Text>
        </HStack>

        <Wrap>
          {Array.isArray(value) &&
            value.map((value, idx) => (
              <Tag
                h={8}
                key={idx}
                px={3}
                w="max-content"
                border="1px"
                borderRadius="8px"
                color={action === 'excludes' ? '#822727' : 'gray.700'}
                borderColor={action === 'excludes' ? '#E5A4A4' : 'gray.300'}
                bg={action === 'excludes' ? 'red.100' : 'gray.100'}
                fontWeight={600}
              >
                {value}
              </Tag>
            ))}

          {typeof value === 'string' ? (
            <Tag
              h={8}
              px={3}
              w="max-content"
              border="1px"
              borderRadius="8px"
              color={action === 'excludes' ? '#822727' : 'gray.700'}
              borderColor={action === 'excludes' ? '#E5A4A4' : 'gray.300'}
              bg={action === 'excludes' ? 'red.100' : 'gray.100'}
              fontWeight={600}
            >
              {value}
            </Tag>
          ) : null}
        </Wrap>
      </Flex>
    )
  })

const SignalTraits = ({ traits }) =>
  traits?.map(
    (traits, index) =>
      traits.length > 0 && (
        <Stack key={index} spacing="21px">
          <Flex gap={4} borderTop="1px" borderColor="gray.100" pt={4}>
            <Text fontWeight={600} color="gray.400">
              OR
            </Text>
            <Stack>
              <SignalBaseQuery signalQuery={traits} />
            </Stack>
          </Flex>
        </Stack>
      ),
  )

export default TemplateDetailsModal
