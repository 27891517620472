import {
  Avatar,
  Button,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StatusDot from 'components/status-dot'
import isEqual from 'lodash.isequal'
import { memo, useEffect, useState } from 'react'

const SelectMenu = ({
  label = null,
  options,
  selected,
  handleSelect,
  type,
  keepLabel,
  fs = 16,
  fw = 600,
  lh = '24px',
  bg = 'white',
  br = 6,
  color = 'gray.600',
  border = 'gray.200',
  placement = 'bottom-start',
  listWidth = 200,
  itemListHeight,
  isQueryBuilder,
  ...props
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure()

  const [selectedItem, setSelectedItem] = useState(() => selected)

  useEffect(() => {
    setSelectedItem(selected)
  }, [selected])

  const onChange = (item) => {
    !keepLabel && setSelectedItem(item)
    handleSelect(item)
    onClose()
  }

  const renderTitle = () => {
    if (isQueryBuilder) return selectedItem?.title
    return selectedItem ? selectedItem?.title?.replace('All', '') : label
  }

  return (
    <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement={placement} isLazy>
      <MenuButton
        as={Button}
        px={4}
        height={10}
        transition="all 0.2s"
        borderWidth="1px"
        bg={bg}
        borderRadius={br}
        borderColor={border}
        _hover={{ bg: 'gray.100' }}
        _focus={{ boxShadow: 'none', bg: bg }}
        _active={{ bg: bg }}
        {...props}
      >
        <Flex align="center" justify="space-between" gap={2}>
          <HStack>
            {type === 'status' && <StatusDot status={selectedItem?.value} />}
            {type === 'icon' && selectedItem?.icon && (
              <Icon
                as={FontAwesomeIcon}
                icon={selectedItem.icon}
                color={selectedItem.color ?? 'muted'}
                h={18}
                w={18}
              />
            )}
            {type === 'avatar' && selectedItem?.userId && (
              <Avatar bg="blue.500" color="white" src={selectedItem?.profile_image} h={6} w={6} />
            )}
            <Text color={color} fontSize={fs} fontWeight={fw} lineHeight={lh}>
              {renderTitle()}
            </Text>
          </HStack>

          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} size="xs" />
        </Flex>
      </MenuButton>

      <MenuList minW={listWidth}>
        {options.map((item, idx) =>
          item.value === 'Divider' ? (
            <MenuDivider key={`divider-${idx}`} />
          ) : (
            <MenuItem
              key={idx}
              onClick={() => onChange(item)}
              bg={selectedItem?.title === item?.title && 'gray.100'}
              _hover={{ bg: 'gray.100' }}
              h={itemListHeight}
            >
              <HStack>
                {type === 'status' && <StatusDot status={item?.value} />}
                {type === 'icon' && item.icon && (
                  <Icon as={FontAwesomeIcon} icon={item.icon} color={item.color ?? 'muted'} h={18} w={18} />
                )}
                {type === 'avatar' && item.userId && (
                  <Avatar bg="blue.500" color="white" src={item?.profile_image} h={6} w={6} />
                )}
                <Text color={color} fontSize={fs} fontWeight={fw}>
                  {item.title}
                </Text>
              </HStack>
            </MenuItem>
          ),
        )}
      </MenuList>
    </Menu>
  )
}

export default memo(SelectMenu, (prev, next) => isEqual(prev, next))
