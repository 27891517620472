import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import StandardButton from 'components/buttons/standard-button'
import ModalLayout from 'components/layouts/modal-layout'
import { useState } from 'react'
import AddLeadModalContent from './add-lead-modal-content'

const AddLeadsButtonModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <StandardButton icon={faPlus} onClick={() => setIsOpen(true)}>
        Add Leads
      </StandardButton>

      {isOpen && (
        <ModalLayout header="Add Lead" isOpen={isOpen} onClose={() => setIsOpen(false)} width={503}>
          <AddLeadModalContent />
        </ModalLayout>
      )}
    </>
  )
}

export default AddLeadsButtonModal
