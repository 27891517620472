import { Box, SimpleGrid } from '@chakra-ui/react'
import LoadingSpinner from 'components/loading/loading-spinner'
import SingleCompanyData from 'features/signal-leads/components/single-company-data'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

const CompanyPage = () => {
  const { companyId } = useParams()

  const {
    data: company,
    isLoading: isCompanyLoading,
    error: companyError,
  } = useQuery(['company', companyId], () => Api.get(`companies/${companyId}`))

  const {
    data: websiteData,
    isLoading: isWebsiteLoading,
    error: websiteError,
  } = useQuery(['website', companyId], () => Api.get(`websites`, { domain: company?.domain }), {
    enabled: !!company?.domain,
  })

  const website = websiteData?.results[0]
  console.log(website)

  if (isCompanyLoading || isWebsiteLoading) {
    return <LoadingSpinner />
  }

  if (companyError || websiteError) {
    return <Box p={4}>An error occurred while fetching the data.</Box>
  }
  return (
    <SimpleGrid columns={2} spacing={10}>
      <Box bg="white" p={4} borderRadius="lg">
        <SingleCompanyData lead={company} />
      </Box>
      <Box bg="white" p={4} borderRadius="lg" fontFamily="monospace" overflowX="auto">
        <pre style={{ whiteSpace: 'pre-wrap', fontSize: 'small' }}>{website?.data?.text}</pre>
      </Box>
    </SimpleGrid>
  )
}

export default CompanyPage
