import {
  Avatar,
  Button,
  Flex,
  FormControl,
  Grid,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { faCheck, faReply } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StandardButton from 'components/buttons/standard-button'
import ModalLayout from 'components/layouts/modal-layout'
import MetaTitle from 'components/meta-data'
import WhiteSurface from 'components/white-surface'
import SingleCompanyData from 'features/signal-leads/components/single-company-data'
import SinglePersonData from 'features/signal-leads/components/single-person-data'
import { Fragment, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import { fireBasicConfetti } from 'utils/confetti'
import SetInterestModal, { interestOptions } from './components/set-interest-modal'
import useUpdateCampaignLead from './hooks/use-update-campaign-lead'

const statusIcons = {
  ACTIVE: { svg: faReply, color: '#51BA72' },
  REPLIED: { svg: faReply, color: 'blue.600' },
  PAUSED: { svg: faReply, color: 'yellow.600' },
  FINISHED: { svg: faReply, color: 'red.600' },
}

const CampaignLeadDetailsPage = () => {
  const { leadId } = useParams()

  const [modal, setModal] = useState({ isOpen: false, type: null })

  const onClose = () => setModal({ isOpen: false, type: null })

  const { data } = useQuery(['campaign-lead', leadId], () => Api.get(`campaign-leads/${leadId}`))

  const { updateLead } = useUpdateCampaignLead()

  const handleManageMeeting = () => {
    if (data?.date_meeting) {
      updateLead({ date_meeting: null })
    } else {
      updateLead({ date_meeting: new Date().toISOString() }, { onSuccess: () => fireBasicConfetti() })
    }
  }

  return (
    <>
      <MetaTitle title={`Campaign Lead - ${leadId}`} />

      <Grid gap={6} gridTemplateColumns={{ lg: 'repeat(4, 1fr)' }}>
        <TopGridDetails
          title="Outreach User"
          display={data?.campaign?.user?.fname}
          avatar={
            <Avatar bg="blue.500" color="white" src={data?.campaign?.user?.profile_image} h={10} w={10} />
          }
          buttonText="View User"
          onClick={() => {}}
        />

        <TopGridDetails
          title="Status"
          display={data?.status}
          icon={{ svg: statusIcons[data?.status]?.svg, color: statusIcons[data?.status]?.color }}
          buttonText="Change"
          onClick={() => {}}
        />

        <TopGridDetails
          title="Interest"
          display={interestOptions[data?.interest]?.label}
          icon={{ svg: interestOptions[data?.interest]?.svg, color: interestOptions[data?.interest]?.color }}
          buttonText="Set Interest"
          onClick={() => setModal({ isOpen: true, type: 'interest' })}
        />

        <TopGridDetails
          title="Booking"
          display={data?.date_meeting && 'Booked'}
          icon={data?.date_meeting && { svg: faCheck, color: '#51BA72' }}
          buttonText={data?.date_meeting ? 'Clear' : 'Book'}
          onClick={handleManageMeeting}
        />
      </Grid>

      <Flex gap={6} mt={6}>
        <Stack w="50%" gap={6}>
          <WhiteSurface br={16} p={6}>
            <SinglePersonData lead={data} isLeadPage />
          </WhiteSurface>

          <WhiteSurface br={16} p={6}>
            <SingleCompanyData lead={data} />
          </WhiteSurface>
        </Stack>

        <WhiteSurface br={16} p={6} w="50%" h="100%">
          <LeadResponseAndPitch label="Response" data={data?.initial_response} value="initial_response" />
          <LeadResponseAndPitch label="Last Pitch" data={data?.initial_pitch} value="initial_pitch" />
        </WhiteSurface>
      </Flex>

      {modal.isOpen &&
        (() => {
          switch (modal.type) {
            case 'interest':
              return <SetInterestModal isOpen={modal.isOpen} onClose={onClose} />

            default:
              return null
          }
        })()}
    </>
  )
}

const TopGridDetails = ({ title, display, avatar, icon, buttonText, onClick }) => {
  return (
    <WhiteSurface justify="space-between">
      <Stack p={6}>
        <Text color="muted" fontSize={14} lineHeight="20px" mb={2}>
          {title}
        </Text>
        <HStack>
          {avatar}
          {icon && <Icon as={FontAwesomeIcon} icon={icon.svg} color={icon.color} h={10} w={10} />}
          <Text fontSize={36} lineHeight="44px" fontWeight={600}>
            {display}
          </Text>
        </HStack>
      </Stack>

      <Button
        variant="ghost"
        w="100%"
        h={14}
        borderTop="1px solid"
        borderColor="gray.200"
        borderRadius={0}
        justifyContent="start"
        color="muted"
        fontSize={20}
        lineHeight="30px"
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </WhiteSurface>
  )
}

const LeadResponseAndPitch = ({ label, data, value }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [textAreaInput, setTextAreaInput] = useState(() => data ?? '')

  useEffect(() => {
    setTextAreaInput(data)
  }, [data])

  const { updateLead, isUpdatingLead } = useUpdateCampaignLead()

  const closeModal = () => setIsOpen(false)

  const onSubmit = (e) => {
    e.preventDefault()
    updateLead({ [value]: textAreaInput?.length > 0 ? textAreaInput : null }, { onSuccess: () => closeModal() })
  }

  return (
    <>
      <HStack justify="space-between">
        <Text color="muted" fontSize={20} lineHeight="30px" fontWeight={600} mb={2}>
          {label}
        </Text>
        <Link fontSize={14} color="blue.500" textDecoration="underline" onClick={() => setIsOpen(true)}>
          Update
        </Link>
      </HStack>
      <Text p="10px" bg="gray.50" fontSize={14} lineHeight="22px" fontFamily="mono" color="muted" mb={6}>
        {data?.split('\n').map((line, idx) => (
          <Fragment key={idx}>
            {line}
            <br />
          </Fragment>
        ))}
      </Text>

      {isOpen && (
        <ModalLayout header={`Edit ${label}`} isOpen={isOpen} onClose={closeModal} size="2xl">
          <form onSubmit={onSubmit}>
            <FormControl>
              <Textarea
                value={textAreaInput}
                onChange={(e) => setTextAreaInput(e.target.value)}
                rows={10}
                autoFocus
              />
            </FormControl>

            <StandardButton mt={6} type="submit" isLoading={isUpdatingLead} w="100%">
              Update
            </StandardButton>
          </form>
        </ModalLayout>
      )}
    </>
  )
}
export default CampaignLeadDetailsPage
