import { Divider, FormControl, HStack, Input, Stack, Text } from '@chakra-ui/react'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import TeamTable from 'features/team/components/team-table'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import useNotification from 'hooks/use-notification'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

const TeamSettings = () => {
  const { accountId } = useParams()
  const invalidate = useInvalidateQuery()
  const { showSuccessMessage } = useNotification()

  const [email, setEmail] = useState('')

  const { data: activeUsers, isFetching: isFetchingActiveUsers } = useQuery(
    ['activeUsers'],
    async () => await Api.get('users', { activated: true, account_id: accountId }),
  )

  const { data: inactiveUsers, isFetching: isFetchingInactiveUsers } = useQuery(
    ['inactiveUsers'],
    async () => await Api.get('users', { activated: false, account_id: accountId }),
  )

  const { mutate: inviteUser, isLoading: isInviting } = useMutation(
    ({ email }) => Api.post('authenticate/invite-user', { email, account_id: accountId }),
    {
      onSuccess: () => {
        showSuccessMessage('User invited successfully')
        invalidate('activeUsers')
        invalidate('inactiveUsers')
        setEmail('')
      },
    },
  )

  const onSubmit = (e) => {
    e.preventDefault()
    inviteUser({ email })
  }

  return (
    <Stack maxW="none">
      <Stack>
        <form onSubmit={onSubmit}>
          <HStack mb={4}>
            <FormControl width={300} isRequired>
              <Input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email@example.com"
                aria-label="Email Input"
                _focus={{ boxShadow: 'none', borderColor: 'inherit' }}
              />
            </FormControl>

            <OutlinedButton icon={faPlus} type="submit" isLoading={isInviting}>
              Invite User
            </OutlinedButton>
          </HStack>
        </form>
      </Stack>

      <Divider />

      <Text fontSize={22} lineHeight="32px" mt={4}>
        Active
      </Text>
      <TeamTable data={activeUsers} isLoading={isFetchingActiveUsers} />

      <Text fontSize={22} lineHeight="32px" mt={10}>
        Inactive
      </Text>
      <TeamTable data={inactiveUsers} isLoading={isFetchingInactiveUsers} />
    </Stack>
  )
}

export default TeamSettings
