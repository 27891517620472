import { Box, Button, Grid, HStack, Text, VStack } from '@chakra-ui/react'
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import LoadingSpinner from 'components/loading/loading-spinner'
import SelectMenu from 'components/select-menu'
import WhiteSurface from 'components/white-surface'
import useChannelOptions from 'hooks/dropdown-filters/use-channel-options'
import useUserOptions from 'hooks/dropdown-filters/use-user-options'
import { useCallback, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import stringify from 'utils/stringify'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend)

const timeRangeOptions = [
  { title: 'All Time', value: null },
  { value: 'Divider' },
  { title: 'Last 7 Days', value: 7 },
  { title: 'Last 30 Days', value: 30 },
  { title: 'Last 3 Months', value: 90 },
  { title: 'Last 6 Months', value: 180 },
  { title: 'Last Year', value: 365 },
]

const displayOptions = [
  { title: 'Count', value: 'count' },
  { title: 'Percentage', value: 'percentage' },
]

const granularityOptions = [
  { title: 'Daily', value: 'day' },
  { title: 'Weekly', value: 'week' },
  { title: 'Monthly', value: 'month' },
]

const commonMetrics = [
  { label: 'Replied', data: 'replied', rate: 'reply_rate', metric: 'date_replied' },
  { label: 'Warm', data: 'warm', rate: 'warm_rate', metric: 'warm' },
  { label: 'Meetings', data: 'meetings', rate: 'meeting_rate', metric: 'date_meeting' },
]

const graphDisplay = {
  all: [
    { label: 'Sent', data: 'contacted', rate: null, metric: 'date_last_contact' },
    { label: 'New', data: 'new', rate: null, metric: 'date_first_contact' },
    ...commonMetrics,
  ],
  email: [
    { label: 'Sent', data: 'last_email', metric: 'date_last_email' },
    { label: 'First Email', data: 'first_email', metric: 'date_first_email' },
    ...commonMetrics,
  ],
  linkedin: [
    { label: 'Accepted', data: 'accepted', metric: 'date_linkedin_connection_accept' },
    { label: 'Requested', data: 'requested', metric: 'date_linkedin_connection_request' },
    ...commonMetrics,
  ],
}

const CampaignsGraph = () => {
  const { accountId } = useParams()

  const [selectedIndex, setSelectedIndex] = useState(1)
  const [timeRange, setTimeRange] = useState(timeRangeOptions[3])
  const [granularity, setGranularity] = useState(granularityOptions[0])
  const [displayOption, setDisplayOption] = useState(displayOptions[0])

  const { user, userOptions, selectUser } = useUserOptions()
  const { channel, channelOptions, selectChannel } = useChannelOptions({})

  const currentGraphDisplay = graphDisplay[channel?.value ?? 'all']
  const currentMetric = currentGraphDisplay[selectedIndex].metric

  const performanceEndpoint = channel?.value ?? 'overall'
  const chartsEndpoint = channel?.value ?? 'all-channels'

  const { data: overAllData, isLoading: overallLoading } = useQuery(
    [`campaign-metrics/${performanceEndpoint}-performance`, accountId, user, timeRange],
    () =>
      Api.get(`campaign-metrics/${performanceEndpoint}-performance`, {
        account_id: accountId,
        user_id: user?.userId,
        day_range: timeRange?.value,
      }),
  )

  const { data: graphData, isFetching } = useQuery(
    [`campaign-charts/${chartsEndpoint}`, accountId, user, timeRange, granularity, currentMetric],
    () =>
      Api.get(`campaign-charts/${chartsEndpoint}`, {
        account_id: accountId,
        user_id: user?.userId,
        day_range: timeRange?.value,
        granularity: granularity?.value,
        metric: currentMetric,
      }),
  )

  const selectTimeRange = useCallback((range) => {
    setTimeRange(range)
  }, [])

  const selectGranularity = useCallback((time) => {
    setGranularity(time)
  }, [])

  const selectDisplayOption = useCallback((option) => {
    setDisplayOption(option)
  }, [])

  const chartJsOptions = {
    responsive: true,
    animation: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: Math.max(0, ...(graphData?.results.map(({ count }) => count) || [])) < 50 ? 1 : 50,
          maxTicksLimit: 10,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  }

  const chartJsData = {
    labels: graphData?.results.map(({ day }) => day),
    datasets: [
      {
        data: graphData?.results.map(({ count }) => count),
        borderColor: '#36a2eb',
        backgroundColor: 'rgba(154, 208, 245, 0.5)',
        fill: true,
        borderWidth: 3,
        lineTension: 0.2,
        pointRadius: 3,
      },
    ],
  }

  return (
    <WhiteSurface>
      <HStack padding="16px 20px" justify="space-between" borderBottomWidth={1}>
        <HStack>
          <SelectMenu selected={channel} options={channelOptions} handleSelect={selectChannel} type="icon" />
          <SelectMenu selected={user} options={userOptions} handleSelect={selectUser} type="avatar" />
        </HStack>
        <HStack>
          <SelectMenu selected={displayOption} options={displayOptions} handleSelect={selectDisplayOption} />
          <SelectMenu selected={timeRange} options={timeRangeOptions} handleSelect={selectTimeRange} />
          <SelectMenu selected={granularity} options={granularityOptions} handleSelect={selectGranularity} />
        </HStack>
      </HStack>

      <HStack padding="16px 20px" h={134} borderBottomWidth={1}>
        <Grid templateColumns="repeat(5, 1fr)" w="100%" gap="40px">
          {currentGraphDisplay.map((item, idx) => (
            <Button key={idx} h="100%" p={4} onClick={() => setSelectedIndex(idx)} variant="ghost">
              <VStack gap={2} w="100%">
                <Text fontSize={12} lineHeight="20px" color="muted">
                  {item.label}
                </Text>
                {overallLoading ? (
                  <LoadingSpinner size="md" m={0} py="11px" px="0 !important" />
                ) : (
                  <>
                    <Text fontSize={38} fontWeight={600} lineHeight="46px">
                      {stringify(
                        overAllData?.[
                          displayOption.value === 'percentage' && item.rate ? item.rate : item.data
                        ],
                      )}
                      {displayOption.value === 'percentage' && item.rate && '%'}
                    </Text>
                    <Box w="100%" h="4px" bg={idx === selectedIndex && '#3182CE'} />
                  </>
                )}
              </VStack>
            </Button>
          ))}
        </Grid>
      </HStack>

      <HStack padding="16px 20px" justify="center">
        {isFetching ? <LoadingSpinner h={300} /> : <Line options={chartJsOptions} data={chartJsData} />}
      </HStack>
    </WhiteSurface>
  )
}

export default CampaignsGraph
