import { Icon } from '@chakra-ui/react'

const Logo = (props) => (
  <Icon viewBox="0 0 35 40" {...props}>
    <path
      d="M18.9861314,32.9915082 L19.9960338,36.5368259 C20.2035366,37.2652759 19.8069686,38.0316829 19.1102745,38.2486439 C18.7148306,38.371791 18.2870407,38.293926 17.9546597,38.0383023 L7.46387431,29.9701763 C2.94595067,26.9758667 -1.49213975e-13,22.0650852 -1.49213975e-13,16.5148139 C-1.49213975e-13,13.9584854 0.624928578,11.5378127 1.74054415,9.37756067 C1.76707044,9.3335228 1.87868655,9.16439279 1.91357422,9.12200928 C2.55090332,8.34774578 6.8914388,10.1361369 14.9351807,14.4871826 L18.9861314,32.9915082 Z"
      fill="#3F49A1"
      fillRule="nonzero"
    />
    <path
      d="M19.0619461,33.2576598 C14.6372455,29.8987399 11.7692308,24.5106017 11.7692308,18.4363636 C11.7692308,10.3629396 16.8357919,3.50156177 23.8899157,1.00587106 C30.6880218,3.32404499 35.5384615,9.39297679 35.5384615,16.5148139 C35.5384615,25.2555594 28.2322205,32.4103086 18.9861314,32.9915082 L19.0619461,33.2576598 Z"
      fill="#FFBE00"
      fillRule="nonzero"
    />
    <path
      d="M1.83908236,9.18981959 C4.74365304,3.74388924 10.7860162,-2.0250468e-13 17.7692308,-2.0250468e-13 C24.9852561,-2.0250468e-13 31.1966509,3.99768267 33.9793383,9.74009866 C31.637373,16.4619146 25.3672019,21.2727273 18,21.2727273 C10.4332755,21.2727273 4.02380722,16.1978079 1.83908236,9.18981959 Z"
      id="Mask"
      fill="#F35D52"
      fillRule="nonzero"
    />
  </Icon>
)

export default Logo
