import { HStack, Icon, Td, Text, Tr } from '@chakra-ui/react'
import { faAmazon, faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TableLayout from 'components/layouts/table-layout'
import UnderlineLink from 'components/links/underline-link'
import capitalize from 'utils/capitalize'
import stringify from 'utils/stringify'
import DomainAuthIcon from './domain-auth-icons'

export const providersIcons = {
  google: faGoogle,
  microsoft: faMicrosoft,
  amazon: faAmazon,
}

const DomainsTable = ({ data, isLoading, columns, header }) => {
  return (
    <TableLayout data={data} columns={columns} isLoading={isLoading} header={header}>
      {data?.results?.map((data) => (
        <Tr key={data.id}>
          <Td>
            <UnderlineLink
              text={data?.domain}
              path={`/account/${data?.account?.id}/settings/domains/${data?.id}`}
            />
          </Td>
          {columns[1].title === 'Account' && (
            <Td>
              <UnderlineLink
                text={data?.account?.name}
                path={`/account/${data?.account?.id}/settings/domains`}
              />
            </Td>
          )}
          <Td>
            <HStack gap={3}>
              {providersIcons[data?.mx_provider] && (
                <Icon
                  icon={providersIcons[data?.mx_provider]}
                  h={5}
                  w={5}
                  color="#828282"
                  as={FontAwesomeIcon}
                />
              )}
              <Text color="muted" fontSize={16} fontWeight={600}>
                {capitalize(data?.mx_provider)}
              </Text>
            </HStack>
          </Td>
          <Td>
            <Text color="muted">{data?.age ? `${stringify(data?.age)} Days` : ''}</Text>
          </Td>
          <Td>
            <Text color="muted">
              {data?.inboxes?.filter((inbox) => inbox.mode === 'outreach').length > 0
                ? data?.inboxes?.filter((inbox) => inbox.mode === 'outreach').length
                : ''}
            </Text>
          </Td>
          <Td>
            <Text color="muted">{data?.inboxes?.length > 0 ? data?.inboxes?.length : ''}</Text>
          </Td>
          <Td>
            <Text color="muted">{data?.reply_rate ? `${data?.reply_rate}%` : ''}</Text>
          </Td>
          <Td>
            <Text color="muted">{data?.open_rate ? `${data?.open_rate}%` : ''}</Text>
          </Td>
          <Td>
            <Text color="muted">{data?.glock_score ? `${data?.glock_score}%` : ''}</Text>
          </Td>
          <Td>
            <HStack gap={3}>
              <HStack gap={1}>
                <DomainAuthIcon value={data?.spf_valid} />
                <Text color="muted"></Text>
              </HStack>
              <HStack gap={1}>
                <DomainAuthIcon value={data?.dmarc_valid} />
                <Text color="muted"></Text>
              </HStack>
              <HStack gap={1}>
                <DomainAuthIcon value={data?.dkim_valid} />
                <Text color="muted"></Text>
              </HStack>
            </HStack>
          </Td>
        </Tr>
      ))}
    </TableLayout>
  )
}

export default DomainsTable
