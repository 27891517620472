import { createContext, useCallback, useContext, useState } from 'react'
import { useQuery } from 'react-query'
import Api from 'utils/api'
import Storage from 'utils/storage'

const AuthContext = createContext()
export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => Storage.get('user'))
  const [isAuthenticated, setAuthenticated] = useState(() => Storage.has('access_token'))

  const isAdmin = user?.admin
  const isAgency = !!user?.organization_id
  const organizationId = user?.organization_id

  const saveUser = (user) => {
    Storage.set('user', user)
    setUser(user)
  }

  useQuery(['user-detail'], async () => Api.get(`users/${user?.id}`), {
    enabled: !!user,
    onSuccess: (data) => {
      saveUser(data)
      setAuthenticated(true)
    },
  })

  const authenticateUser = useCallback(async (token) => {
    try {
      Storage.set('access_token', token)
      const currentUser = await Api.get('authenticate/current-user')
      saveUser(currentUser)
    } catch (error) {
      console.log(error)
    }
  }, [])

  const logOut = useCallback(() => {
    setAuthenticated(false)
    setUser(null)
    Storage.clear()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        authenticateUser,
        logOut,
        isAdmin,
        isAgency,
        organizationId,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
