import { Flex, Stack, Text } from '@chakra-ui/react'
import { faDownload } from '@fortawesome/pro-regular-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import ModalLayout from 'components/layouts/modal-layout'
import SelectMenu from 'components/select-menu'
import { useSignalLeadsProps } from 'context/signal-leads-context'
import useNotification from 'hooks/use-notification'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

const options = [
  { title: 'All Columns', value: null },
  { title: 'LinkedIn Audiences (Companies)', value: 'linkedin_companies' },
  { title: 'LinkedIn Audiences (Contacts)', value: 'linkedin_contacts' },
  { title: 'Google Customers', value: 'google' },
]

const DownloadLeadModal = ({ onClose }) => {
  const { signalId } = useParams()
  const { stage } = useSignalLeadsProps()
  const { showSuccessMessage } = useNotification()

  const [selected, setSelected] = useState(options[0])

  const { mutate, isLoading } = useMutation(
    ({ download }) => {
      let url = 'lead-download/signal2'
      if (download === 'linkedin_companies') url += '-linkedin-matched-companies'
      else if (download === 'linkedin_contacts') url += '-linkedin-matched-contacts'
      else if (download === 'google') url += '-google-customer-match'
      return Api.get(url, { signal_id: signalId, stage: stage })
    },
    {
      onSuccess: () => {
        showSuccessMessage('We have emailed you a download link for this export')
        onClose()
      },
    },
  )

  return (
    <ModalLayout header="Download Leads" isOpen={true} onClose={onClose} size="lg">
      <Stack align="center" spacing={6} mb={4}>
        <Text textAlign="center" maxW={330}>
          Generate a .CSV file that will be emailed to you for download.
        </Text>

        <Flex gap={2}>
          <SelectMenu selected={selected} options={options} handleSelect={setSelected} />
          <OutlinedButton
            onClick={() => mutate({ download: selected.value })}
            isLoading={isLoading}
            icon={faDownload}
          >
            Download
          </OutlinedButton>
        </Flex>
      </Stack>
    </ModalLayout>
  )
}

export default DownloadLeadModal
