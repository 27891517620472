import { useToast } from '@chakra-ui/react'
import { useCallback } from 'react'
import capitalize from 'utils/capitalize'

export const DEFAULT_POSITION = 'top-right'
export const DEFAULT_DURATION = 5000

const useNotification = () => {
  const toast = useToast()

  const showNotification = useCallback(
    ({ message, status, position = DEFAULT_POSITION, duration = DEFAULT_DURATION, isClosable = true }) =>
      toast({
        title: capitalize(message),
        status,
        position,
        isClosable,
        duration,
      }),
    [],
  )

  const showSuccessMessage = useCallback(
    (message) =>
      toast({
        title: capitalize(message),
        status: 'success',
        position: DEFAULT_POSITION,
        duration: DEFAULT_DURATION,
        isClosable: true,
      }),
    [],
  )

  return { showNotification, showSuccessMessage }
}

export default useNotification
