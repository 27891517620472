import { FormLabel, Text, Textarea } from '@chakra-ui/react'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import StandardButton from 'components/buttons/standard-button'
import ModalLayout from 'components/layouts/modal-layout'
import useImportDomains from 'features/signal-create/hooks/use-import-domains'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

const ImportDomainsButtonModal = () => {
  const { signalId } = useParams()

  const [domains, setDomains] = useState('')

  const [isOpen, setIsOpen] = useState(false)

  const { importDomains, isLoading } = useImportDomains()

  const closeModal = () => setIsOpen(false)

  const onSubmit = async (e) => {
    e.preventDefault()
    await importDomains({ signal_id: signalId, text: domains })
    closeModal()
    setDomains('')
  }

  return (
    <>
      <StandardButton icon={faPlus} onClick={() => setIsOpen(true)}>
        Import
      </StandardButton>

      {isOpen && (
        <ModalLayout header="Import Domains" isOpen={isOpen} onClose={closeModal} width={567}>
          <form onSubmit={onSubmit}>
            <FormLabel>Domains</FormLabel>
            <Textarea
              placeholder="hellooutbound.com&#10;google.com&#10;hubspot.com&#10;"
              value={domains}
              onChange={(e) => setDomains(e.target.value)}
              color="#4A5568"
              h={289}
            />
            <Text mb="34px" fontSize={14} mt={1} color="muted">
              Copy / Paste your domains, one per a line, into this text box.
            </Text>

            <StandardButton type="submit" w="100%" tabIndex="2" isLoading={isLoading} isDisabled={!domains}>
              Import Domains
            </StandardButton>
          </form>
        </ModalLayout>
      )}
    </>
  )
}

export default ImportDomainsButtonModal
