import { faGear, faGraduationCap, faStar } from '@fortawesome/pro-duotone-svg-icons'
import ColorBox from 'components/colorful-box'
import NavLink from 'components/links/nav-link'
import SideMenuDivider from 'components/side-menu-divider'
import WhiteSurface from 'components/white-surface'
import { useAuth } from 'context/auth-context'
import { useSignal } from 'context/signal-context'
import { useParams } from 'react-router-dom'
import { SignalIcon } from '../constants/signal-icons'

const SideNavigation = () => {
  const { isAdmin } = useAuth()

  const { accountId, signalId } = useParams()

  const { signal, stageCount } = useSignal()

  const basePath = accountId ? `/account/${accountId}/signal/${signalId}` : `/signal/${signalId}`

  const navLinks = {
    stages: [
      { name: 'Review', color: 'yellow', value: 'review', path: `${basePath}/review` },
      { name: 'Approved', color: 'green', value: 'complete', path: `${basePath}/approved` },
      { name: 'Archived', color: 'gray', value: 'archived', path: `${basePath}/archived` },
      { name: 'Declined', color: 'red', value: 'declined', path: `${basePath}/declined` },
    ],
    queries: [
      { name: 'Companies', icon: SignalIcon.companies, path: `${basePath}/query-company` },
      { name: 'Contacts', icon: SignalIcon.positions, path: `${basePath}/query-people` },
    ],
    settings: [
      { name: 'Scoring', icon: faStar, path: `${basePath}/scoring` },
      { name: 'Settings', icon: faGear, path: `${basePath}/settings` },
    ],
    admin_stages: [
      { name: 'Hopper', value: 'hopper', path: `${basePath}/hopper` },
      { name: 'Prospect', value: 'prospect', path: `${basePath}/prospect` },
      { name: 'Generic', value: 'generic', path: `${basePath}/generic` },
    ],
    admin_settings: [
      { name: 'Settings', icon: faGear, path: `${basePath}/admin-settings` },
      { name: 'Training', icon: faGraduationCap, path: `${basePath}/admin-training` },
    ],
  }

  const getQueryLinks = () => {
    if (signal?.query_type === 'import_domains') {
      return navLinks.queries.slice(1)
    }
    if (signal?.query_type === 'companies') {
      return navLinks.queries
    }
    if (signal?.query_type === 'positions') {
      return navLinks.queries.reverse()
    }
    return [
      {
        name: signal?.query_type_display,
        icon: SignalIcon[signal?.query_type],
        path: `${basePath}/query-signal`,
      },
      ...navLinks.queries,
    ]
  }

  return (
    <>
      <WhiteSurface as="nav" br={16} p={2} mb={4} gap={1}>
        {navLinks.stages.map((link, idx) => (
          <NavLink key={idx} justifyContent="space-between" path={link.path}>
            {link.name}
            <ColorBox color={link.color} value={stageCount?.[link.value] ?? 0} />
          </NavLink>
        ))}
      </WhiteSurface>

      <SideMenuDivider label="Search Queries" />

      <WhiteSurface as="nav" br={16} p={2} mb={4} gap={1}>
        {getQueryLinks().map((link, idx) => (
          <NavLink key={idx} path={link.path} icon={link.icon} iconSize={20}>
            {link.name}
          </NavLink>
        ))}
      </WhiteSurface>

      <SideMenuDivider label="Settings" />

      <WhiteSurface as="nav" br={16} p={2} mb={4} gap={1}>
        {navLinks.settings.map((link, idx) => (
          <NavLink key={idx} iconSize={16} {...link}>
            {link.name}
          </NavLink>
        ))}
      </WhiteSurface>

      {isAdmin && (
        <>
          <SideMenuDivider label="Admin" />

          <WhiteSurface as="nav" br={16} p={2}>
            {navLinks.admin_stages.map((link, idx) => (
              <NavLink key={idx} justifyContent="space-between" {...link}>
                {link.name}
                <ColorBox color="gray" value={stageCount?.[link.value] ?? 0} />
              </NavLink>
            ))}

            {navLinks.admin_settings.map((link, idx) => (
              <NavLink key={idx} iconSize={16} {...link}>
                {link.name}
              </NavLink>
            ))}
          </WhiteSurface>
        </>
      )}
    </>
  )
}

export default SideNavigation
