import { Box, Button, HStack } from '@chakra-ui/react'
import { useAuth } from 'context/auth-context'
import { useCurrentOrg } from 'context/current-organization'
import { useEffect } from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'

const NavAgency = () => {
  const { isAgency } = useAuth()
  const { pathname } = useLocation()
  const { organizationId } = useParams()
  const { setCurrentOrgId } = useCurrentOrg()

  useEffect(() => {
    if (!pathname.startsWith('/account/')) {
      setCurrentOrgId(organizationId)
    }
  }, [organizationId])

  const shouldNotDisplay =
    !isAgency ||
    pathname === '/organizations' ||
    pathname === '/templates' ||
    pathname === '/training' ||
    pathname.startsWith('/database') ||
    pathname.startsWith('/signal/')

  if (shouldNotDisplay) {
    return null
  }

  return (
    <Box as="nav" bg="bg-accent" overflow="auto">
      <HStack justify="space-between" py={4} px={8}>
        <HStack gap={3}>
          <LinkButton route="accounts">Accounts</LinkButton>
          <LinkButton route="team">Team</LinkButton>
          <LinkButton route="domains">Domains</LinkButton>
          <LinkButton route="inboxes">Inboxes</LinkButton>
        </HStack>
      </HStack>
    </Box>
  )
}

const LinkButton = ({ route, children, active }) => {
  const { currentOrgId } = useCurrentOrg()

  const getPath = (route) => (currentOrgId ? `/org/${currentOrgId}/${route}` : `/${route}`)

  return (
    <Button
      to={getPath(route)}
      as={NavLink}
      variant="ghost-on-accent"
      style={({ isActive }) => ({ background: (isActive || active) && '#3182ceab' })}
      _hover={{ background: '#3182ceab' }}
    >
      {children}
    </Button>
  )
}

export default NavAgency
