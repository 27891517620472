import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Api from 'utils/api'

const useCreateSignal = () => {
  const navigate = useNavigate()

  const { accountId } = useParams() ?? {}

  const { mutateAsync: createSignal, isLoading } = useMutation(
    ({ signalName, queryType, prospecting = true, isTemplate = false }) =>
      Api.post('signals', {
        account_id: accountId,
        name: signalName,
        query_type: queryType,
        select_person: prospecting,
        is_global_template: isTemplate,
      }),
    { onSuccess: (data) => navigate(`/account/${accountId}/signal/${data?.id}/review`) },
  )

  return { createSignal, isLoading }
}

export default useCreateSignal
