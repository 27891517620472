import { Box, Text, VStack } from '@chakra-ui/react'
import WhiteSurface from 'components/white-surface'
import { useSignalLeadsProps } from 'context/signal-leads-context'
import RefreshButton from 'features/signal/components/refresh-button'

const options = {
  review: {
    title: 'Nothing to Review',
    description: 'No leads are ready to review yet',
  },
  complete: {
    title: 'Nothing Approved',
    description: 'No leads are approved yet',
  },
  archived: {
    title: 'Nothing Archived',
    description: 'No leads are archived yet',
  },
  declined: {
    title: 'Nothing Declined',
    description: 'No leads are declined yet',
  },
  hopper: {
    title: 'Nothing in Hopper',
    description: 'No leads are in hopper yet',
  },
  prospect: {
    title: 'Nothing in Prospect',
    description: 'No leads are in prospect yet',
  },
  generic: {
    title: 'Nothing on Generic',
    description: 'No leads are generic yet',
  },
}

const EmptyLeads = () => {
  const { stage } = useSignalLeadsProps()

  return (
    <WhiteSurface p={8} h="100%">
      <VStack h="100%" justify="center" spacing={7}>
        <Text fontSize={32} lineHeight="32px" textAlign="center">
          {options[stage].title}
        </Text>

        <Box fontSize={18} lineHeight="28px" maxW={524} textAlign="center" color="gray.600">
          <Text>
            {options[stage].description}. Make sure you have built your search queries and added some leads!
          </Text>

          <RefreshButton w={140} mt={7} />
        </Box>
      </VStack>
    </WhiteSurface>
  )
}

export default EmptyLeads
