import { Stack, Text } from '@chakra-ui/react'
import ApproveButton from 'components/buttons/approve-button'
import ModalLayout from 'components/layouts/modal-layout'
import { useSignalLeadsProps } from 'context/signal-leads-context'
import pluralize from 'utils/pluralize'
import useLeadActions from '../hooks/use-lead-actions'

const ApproveLeadModal = ({ onClose }) => {
  const { approveLeads, isApproving } = useLeadActions()

  const { lead, inSingleView, selectedItems, clearSelectedItems } = useSignalLeadsProps()

  const amountSelected = inSingleView ? 1 : selectedItems.length

  const handleApprove = () => {
    const leadIds = inSingleView ? [lead?.id] : selectedItems.map((item) => item.id)
    approveLeads(leadIds, {
      onSuccess: () => {
        clearSelectedItems()
        onClose()
      },
    })
  }

  return (
    <ModalLayout header="Approve Leads" isOpen={true} onClose={onClose} size="lg">
      <Stack align="center" spacing={6} mb={4}>
        <Text textAlign="center">
          {amountSelected > 0
            ? `You are approving ${amountSelected} ${pluralize('lead', amountSelected)}.`
            : 'Please select at least one lead to approve.'}
        </Text>

        <ApproveButton
          onClick={handleApprove}
          isLoading={isApproving}
          isDisabled={!inSingleView && amountSelected === 0}
        >
          Approve
        </ApproveButton>
      </Stack>
    </ModalLayout>
  )
}

export default ApproveLeadModal
