import PageHeader from 'components/page-header'
import CampaignLeadsTable from '../components/campaign-leads-table'

const AllLeads = () => {
  return (
    <>
      <PageHeader title="All Leads" />
      <CampaignLeadsTable type="all" />
    </>
  )
}

export default AllLeads
