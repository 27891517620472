import useInvalidateQuery from 'hooks/use-invalidate-query'
import useNotification from 'hooks/use-notification'
import { useMutation } from 'react-query'
import Api from 'utils/api'

const useCampaignActions = ({ campaign }) => {
  const invalidate = useInvalidateQuery()
  const { showSuccessMessage } = useNotification()

  const onSuccess = ({ message }) => {
    showSuccessMessage(message)
    setTimeout(() => invalidate('campaigns'), 500)
  }

  const { mutate: syncStats } = useMutation(() => Api.get(`campaigns/${campaign?.id}`), {
    onSuccess: () => onSuccess({ message: 'Campaign synced' }),
  })

  const { mutate: deleteCampaign, isLoading: isDeleting } = useMutation(
    () => Api.delete(`campaigns/${campaign?.id}`),
    { onSuccess: () => onSuccess({ message: 'Campaign deleted' }) },
  )

  const { mutate: updateCampaign, isLoading: isUpdating } = useMutation(
    (payload) => Api.post(`campaigns/${campaign?.id}`, payload),
    {
      onSuccess: (_, payload) => {
        if ('user_id' in payload) return onSuccess({ message: 'User updated' })
        if (payload.status === 'archived') return onSuccess({ message: 'Campaign archived' })
        if (payload.status === 'running') return onSuccess({ message: 'Campaign is now running' })
        onSuccess({ message: 'Campaign updated' })
      },
    },
  )

  return { syncStats, updateCampaign, isUpdating, deleteCampaign, isDeleting }
}

export default useCampaignActions
