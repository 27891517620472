import { createContext, useContext, useState } from 'react'

const CurrentOrgContext = createContext()
export const useCurrentOrg = () => useContext(CurrentOrgContext)

export const CurrentOrgProvider = ({ children }) => {
  const [currentOrgId, setCurrentOrgId] = useState(null)

  return (
    <CurrentOrgContext.Provider value={{ currentOrgId, setCurrentOrgId }}>
      {children}
    </CurrentOrgContext.Provider>
  )
}
