import { HStack, Icon, Input, Td, Text, Tr, VStack } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TableLayout from 'components/layouts/table-layout'
import UnderlineLink from 'components/links/underline-link'
import MetaData from 'components/meta-data'
import PageHeader from 'components/page-header'
import { SignalIcon } from 'features/signal/constants/signal-icons'
import useDebounce from 'hooks/use-debounce'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'
import Api from 'utils/api'
import uid from 'utils/uid'
import CreateTemplateButtonModal from './components/create-template-button-modal'

const columns = [
  { id: uid(), title: 'Name' },
  { id: uid(), title: 'Type' },
]

const TemplatesPage = () => {
  const [searchTerm, setSearchTerm] = useSearchParams({ input: '' })

  const inputValue = searchTerm.get('input')

  const debouncedSearchterm = useDebounce(inputValue, 250)

  const { data: signals, isLoading } = useQuery(['templates', debouncedSearchterm], () =>
    Api.get('signal/template-search', { search: debouncedSearchterm || null }),
  )

  const onSearch = (e) => {
    setSearchTerm(
      (prev) => {
        prev.set('input', e.target.value)
        return prev
      },
      { replace: true },
    )
  }

  return (
    <>
      <MetaData title="Templates" />

      <VStack mx="auto">
        <PageHeader
          title="Signals Template"
          description="Create, view, and edit your templates"
          button={<CreateTemplateButtonModal />}
        />

        <TableLayout
          columns={columns}
          isLoading={isLoading}
          minW={{ base: '100%', md: 624 }}
          header={
            <Input
              type="text"
              placeholder="Search Templates"
              aria-label="Search Templates Input"
              value={inputValue}
              onChange={onSearch}
            />
          }
        >
          {signals?.results?.length === 0 ? (
            <Tr>
              <Td>
                <Text color="muted">No results found...</Text>
              </Td>
            </Tr>
          ) : (
            signals?.results.map((signal) => (
              <Tr key={signal.id}>
                <Td>
                  <UnderlineLink text={signal?.name} path={`/signal/${signal.id}/review`} fontSize={16} />
                </Td>
                <Td w={60}>
                  <HStack>
                    <Icon icon={SignalIcon[signal.query_type]} as={FontAwesomeIcon} h={5} w={6} />
                    <Text color="muted" fontSize={16} fontWeight={600}>
                      {signal.query_type_display}
                    </Text>
                  </HStack>
                </Td>
              </Tr>
            ))
          )}
        </TableLayout>
      </VStack>
    </>
  )
}

export default TemplatesPage
