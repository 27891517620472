import { AbsoluteCenter, Box, Divider } from '@chakra-ui/react'

const SideMenuDivider = ({ label }) => (
  <Box position="relative" py="22px">
    <Divider />
    <AbsoluteCenter bg="gray.50" fontSize={12} px={2} color="gray.400">
      {label}
    </AbsoluteCenter>
  </Box>
)

export default SideMenuDivider
