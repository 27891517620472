import { Box } from '@chakra-ui/react'
import { faClose, faInbox, faPlay, faSync, faUser } from '@fortawesome/pro-regular-svg-icons'
import SelectMenu from 'components/select-menu'
import { useCallback, useState } from 'react'
import ActivateCampaignModal from '../action-modals/activate-campaign-user'
import ArchiveCampaignModal from '../action-modals/archive-campaign-modal'
import DeleteCampaignModal from '../action-modals/delete-campaign-modal'
import SetUserModal from '../action-modals/select-user-modal'
import useCampaignActions from '../hooks/use-campaign-actions'

const CampaignActionDropdown = ({ campaign }) => {
  const isArchived = campaign?.status === 'archived'

  const actions = [
    { title: 'Sync Stats', value: 'sync', icon: faSync, color: '#959AA4' },
    { title: 'Set User', value: 'set_user', icon: faUser, color: '#959AA4' },
    {
      title: isArchived ? 'Activate Campaign' : 'Archive Campaign',
      value: isArchived ? 'activate' : 'archive',
      icon: isArchived ? faPlay : faInbox,
      color: '#959AA4',
    },
    { title: 'Delete Campaign', value: 'delete', icon: faClose, color: '#959AA4' },
  ]

  const [isHovered, setIsHovered] = useState(false)
  const [modal, setModal] = useState({ isOpen: false, action: null })

  const { syncStats } = useCampaignActions({ campaign })

  const onClose = () => setModal({ isOpen: false, action: null })

  const handleSelect = useCallback((option) => {
    if (option.value === 'sync') return syncStats()
    setModal({ isOpen: true, action: option.value })
  }, [])

  return (
    <>
      <Box
        h="58px"
        display="none"
        _groupHover={{ display: 'flex' }}
        justifyContent="end"
        alignItems="center"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={(e) => e.stopPropagation()}
      >
        <SelectMenu
          label="Actions"
          options={actions}
          handleSelect={handleSelect}
          type="icon"
          keepLabel
          placement="bottom-end"
          itemListHeight="40px"
          key={isHovered}
        />
      </Box>

      {modal.isOpen &&
        (() => {
          switch (modal.action) {
            case 'set_user':
              return <SetUserModal campaign={campaign} onClose={onClose} />

            case 'archive':
              return <ArchiveCampaignModal campaign={campaign} onClose={onClose} />

            case 'activate':
              return <ActivateCampaignModal campaign={campaign} onClose={onClose} />

            case 'delete':
              return <DeleteCampaignModal campaign={campaign} onClose={onClose} />

            default:
              return null
          }
        })()}
    </>
  )
}

export default CampaignActionDropdown
