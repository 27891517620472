import { FormControl, Input } from '@chakra-ui/react'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import StandardButton from 'components/buttons/standard-button'
import ModalLayout from 'components/layouts/modal-layout'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import useNotification from 'hooks/use-notification'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

const AddDomainButtonModal = () => {
  const [domain, setDomain] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = () => setIsOpen(false)

  const { accountId } = useParams()
  const invalidate = useInvalidateQuery()
  const { showSuccessMessage } = useNotification()

  const { mutate: addDomain, isLoading } = useMutation(
    ({ domain }) => Api.post('inbox-domains', { domain, account_id: accountId }),
    {
      onSuccess: () => {
        showSuccessMessage('Domain added successfully')
        invalidate('account-inbox-domains')
        setDomain('')
        closeModal()
      },
    },
  )

  const onSubmit = (e) => {
    e.preventDefault()
    addDomain({ domain })
  }

  return (
    <>
      <OutlinedButton icon={faPlus} onClick={() => setIsOpen(true)}>
        Add Domain
      </OutlinedButton>

      {isOpen && (
        <ModalLayout header="Add Domain" isOpen={isOpen} onClose={closeModal}>
          <form onSubmit={onSubmit}>
            <FormControl isRequired>
              <Input
                type="text"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                placeholder="hellooutbound.com"
                aria-label="Domain Input"
                autoFocus
              />
            </FormControl>

            <StandardButton mt={6} type="submit" isLoading={isLoading} w="100%">
              Add Domain
            </StandardButton>
          </form>
        </ModalLayout>
      )}
    </>
  )
}

export default AddDomainButtonModal
