import { Stack, Text } from '@chakra-ui/react'
import SelectMenu from 'components/select-menu'
import WhiteSurface from 'components/white-surface'
import { useSignal } from 'context/signal-context'
import ChangeSignalTypeModal from 'features/signal/components/change-signal-type-modal'
import { SignalIcon } from 'features/signal/constants/signal-icons'
import useUpdateSignal from 'features/signal/hooks/use-update-signal'
import { useCallback, useEffect, useState } from 'react'

const signalTypeOptions = [
  { title: 'Companies', value: 'companies', icon: SignalIcon.companies },
  { title: 'Contacts', value: 'positions', icon: SignalIcon.positions },
  { title: 'Topics', value: 'companies_pinecone', icon: SignalIcon.companies_pinecone },
  { title: 'Job Postings', value: 'job_postings', icon: SignalIcon.job_postings },
  { title: 'Funding', value: 'fundings', icon: SignalIcon.fundings },
  { title: 'Demographics', value: 'demographics', icon: SignalIcon.demographics },
  { title: 'Events', value: 'events', icon: SignalIcon.events },
]

const ChangeSignalType = () => {
  const { signal } = useSignal()
  const { updateSignal } = useUpdateSignal()

  const [selectedType, setSelectedType] = useState(null)
  const [changeSignalTypeModalOpen, setChangeSignalTypeModalOpen] = useState(false)

  const signalCurrentType = signalTypeOptions?.find(({ value }) => value === signal?.query_type)

  useEffect(() => {
    setSelectedType(signalCurrentType)
  }, [signal])

  const handleChangeType = useCallback(
    (item) => {
      if (item.value === signal?.query_type) return
      setChangeSignalTypeModalOpen(true)
      setSelectedType(item)
    },
    [signal],
  )

  const approveChange = () => {
    setChangeSignalTypeModalOpen(false)
    updateSignal({ query_type: selectedType.value, query_signal: null })
  }

  const dismissChange = () => {
    setSelectedType(signalCurrentType)
    setChangeSignalTypeModalOpen(false)
  }

  return (
    <>
      <Stack spacing={3}>
        <Text fontSize={22} lineHeight="32px">
          Type
        </Text>
        <WhiteSurface br={4} p={4}>
          <Text fontSize={14} color="gray.600">
            Change the search queries and trigger events used to build this signal.
          </Text>

          <SelectMenu
            type="icon"
            selected={selectedType}
            options={signalTypeOptions}
            handleSelect={handleChangeType}
            bg="transparent"
            border="gray.300"
            mt={4}
            w="max-content"
          />
        </WhiteSurface>
      </Stack>

      {changeSignalTypeModalOpen && (
        <ChangeSignalTypeModal
          isOpen={changeSignalTypeModalOpen}
          dismissChange={dismissChange}
          approveChange={approveChange}
        />
      )}
    </>
  )
}

export default ChangeSignalType
