import {
  faBuilding,
  faCalendarDays,
  faComments,
  faIdCardClip,
  faPeopleGroup,
  faSackDollar,
  faUpload,
  faUserTie,
} from '@fortawesome/pro-duotone-svg-icons'

export const SignalIcon = {
  companies: faBuilding,
  positions: faUserTie,
  companies_pinecone: faComments,
  job_postings: faIdCardClip,
  fundings: faSackDollar,
  demographics: faPeopleGroup,
  events: faCalendarDays,
  import_domains: faUpload,
}
