import { HStack, Icon, Text } from '@chakra-ui/react'
import { faFire, faPaperPlane, faPowerOff, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingSpinner from 'components/loading/loading-spinner'
import PageHeader from 'components/page-header'
import AddInboxButtonModal from 'features/inboxes/components/add-inbox-button-modal'
import InboxesTable from 'features/inboxes/components/inboxes-table'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import uid from 'utils/uid'

const connectedColumns = [
  { id: uid(), title: 'Inbox' },
  { id: uid(), title: 'Type' },
  { id: uid(), title: 'User' },
  { id: uid(), title: 'Connections' },
  { id: uid(), title: 'Age' },
  { id: uid(), title: 'Reply' },
  { id: uid(), title: 'Open' },
  { id: uid(), title: 'Glock' },
  { id: uid(), title: 'SmartLead' },
]

const unconnectedColumns = [
  { id: uid(), title: 'Inbox' },
  { id: uid(), title: 'Type' },
  { id: uid(), title: 'User' },
  { id: uid(), title: 'Connections' },
  { id: uid(), title: 'Opens' },
  { id: uid(), title: 'Glock' },
  { id: uid(), title: 'SmartLead' },
]

const InboxSetting = () => {
  const { accountId } = useParams()

  const { data: outreachInboxes, isFetching: outreachLoading } = useQuery(
    ['account-outreach-inboxes'],
    async () => await Api.get('inboxes', { account_id: accountId,  mode: 'outreach' }),
  )

  const { data: monitoringInboxes, isFetching: monitoringLoading } = useQuery(
    ['account-monitor-inboxes'],
    async () => await Api.get('inboxes', { account_id: accountId, connected: true, mode: 'monitor' }),
  )

  const { data: warmingInboxes, isFetching: warmingLoading } = useQuery(
    ['account-warming-inboxes'],
    async () => await Api.get('inboxes', { account_id: accountId, connected: true, mode: 'warm' }),
  )

  const { data: unconnectedInboxes, isFetching: unconnectedLoading } = useQuery(
    ['account-unconnnected-inboxes'],
    async () => await Api.get('inboxes', { account_id: accountId, connected: false }),
  )

  if (outreachLoading || monitoringLoading || warmingLoading || unconnectedLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <PageHeader
        title="Inboxes"
        description="Track inbox configuration, health, and deliverability."
        button={<AddInboxButtonModal />}
      />

      {outreachInboxes?.results.length > 0 && (
        <>
          <HStack gap={3} mb={2} pt={4}>
            <Icon icon={faPaperPlane} as={FontAwesomeIcon} h={6} w={6} color="#959AA4" />
            <Text fontSize={22} lineHeight="32px">
              Outreach
            </Text>
          </HStack>
          <InboxesTable data={outreachInboxes?.results} columns={connectedColumns} />
        </>
      )}

      {warmingInboxes?.results.length > 0 && (
        <>
          <HStack gap={3} mb={2} mt={9}>
            <Icon icon={faFire} as={FontAwesomeIcon} h={6} w={6} color="#959AA4" />c
            <Text fontSize={22} lineHeight="32px">
              Warming
            </Text>
          </HStack>
          <InboxesTable data={warmingInboxes?.results} columns={connectedColumns} />
        </>
      )}

      {monitoringInboxes?.results.length > 0 && (
        <>
          <HStack gap={3} mb={2} mt={9}>
            <Icon icon={faTriangleExclamation} as={FontAwesomeIcon} h={6} w={6} color="#959AA4" />c
            <Text fontSize={22} lineHeight="32px">
              Monitoring
            </Text>
          </HStack>
          <InboxesTable data={monitoringInboxes?.results} columns={connectedColumns} />
        </>
      )}

      {unconnectedInboxes?.results.length > 0 && (
        <>
          <HStack gap={3} mb={2} mt={9}>
            <Icon icon={faPowerOff} as={FontAwesomeIcon} h={6} w={6} color="#959AA4" />
            <Text fontSize={22} lineHeight="32px">
              Disconnected
            </Text>
          </HStack>
          <InboxesTable data={unconnectedInboxes?.results} columns={unconnectedColumns} />
        </>
      )}
    </>
  )
}

export default InboxSetting
