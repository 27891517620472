import { FormLabel, Grid, HStack, Input, Stack, Switch, Text, Textarea } from '@chakra-ui/react'
import { faPlay } from '@fortawesome/pro-regular-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import ModalLayout from 'components/layouts/modal-layout'
import SelectMenu from 'components/select-menu'
import { useSignalLeadsProps } from 'context/signal-leads-context'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import useNotification from 'hooks/use-notification'
import { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import pluralize from 'utils/pluralize'
import ModalToggleButtons from '../components/modal-toggle-buttons'

const RunPromptModal = ({ onClose }) => {
  const { accountId, signalId } = useParams()
  const invalidate = useInvalidateQuery()
  const { showSuccessMessage } = useNotification()
  const { stage, inReview, inSingleView, lead, selectedItems } = useSignalLeadsProps()

  const amountSelected = inSingleView && inReview ? 1 : selectedItems.length

  const [overwrite, setOverwrite] = useState(false)
  const [selectedPrompt, setSelectedPrompt] = useState(null)
  const [selectedOutput, setSelectedOutput] = useState(null)
  const [selectedModel, setSelectedModel] = useState(null)
  const [promptText, setPromptText] = useState('')
  const [promptName, setPromptName] = useState('') // Added state for promptName
  const [value, setValue] = useState(amountSelected > 0 ? 'selected' : 'all')

  const { data: prompts } = useQuery(['prompts', signalId, accountId], async () => {
    const signalRes = await Api.get('prompts', { resource_id: signalId, resource_type: 'signals' })
    const accountRes = await Api.get('prompts', { account_id: accountId, is_template: true })

    const signalPrompts =
      signalRes?.results?.map((prompt) => ({ title: `Signal: ${prompt.name}`, ...prompt })) || []

    const accountPrompts =
      accountRes?.results?.map((prompt) => ({ title: `Account: ${prompt.name}`, ...prompt })) || []

    return [...signalPrompts, ...accountPrompts]
  })

  const { data: modelOptions } = useQuery(['models', signalId], async () => {
    const res = await Api.get('signal-lead-prompts-get-models')
    return res?.results?.map((model) => ({ title: model }))
  })

  const { data: destinationOptions } = useQuery(['destinations', signalId], async () => {
    const res = await Api.get('signal-lead-prompts-get-destinations')
    return res?.results?.map((destination) => ({ title: destination }))
  })

  const { mutate: updatePrompt } = useMutation(
    (payload) => selectedPrompt?.id && Api.post(`prompts/${selectedPrompt.id}`, payload),
  )

  const handleSelectPrompt = useCallback((prompt) => {
    invalidate('prompts')
    setSelectedPrompt(prompt)
    setSelectedOutput({ title: prompt.destination })
    setSelectedModel({ title: prompt.model })
    setPromptText(prompt.prompt)
    setPromptName(prompt.name)
  }, [])

  const handleSelectOutput = useCallback((output) => {
    setSelectedOutput(output)
    updatePrompt({ destination: output.title })
  }, [])

  const handleSelectModel = useCallback((model) => {
    setSelectedModel(model)
    updatePrompt({ model: model.title })
  }, [])

  useEffect(() => {
    const autosave = setTimeout(() => updatePrompt({ prompt: promptText }), 1000)
    return () => clearTimeout(autosave)
  }, [promptText])

  const { mutate, isLoading: isRunningPrompt } = useMutation(
    ({ leadIds, promptId, override }) =>
      Api.post('signal-actions/run-prompt', {
        signal_id: signalId,
        account_id: accountId,
        stage: stage,
        prompt_id: promptId,
        override: override,
        ...(leadIds && { lead_ids: leadIds }),
      }),
    {
      onSuccess: (data) =>
        showSuccessMessage(`${data} ${pluralize('prompt', data)} have been queued up for processing`),
    },
  )

  const runPrompt = () => {
    const leadIds = inReview && inSingleView ? [lead?.id] : selectedItems.map((item) => item.id)
    mutate({
      leadIds: value === 'selected' ? leadIds : null,
      promptId: selectedPrompt?.id,
      override: overwrite,
    })
  }

  return (
    <ModalLayout header="Run Prompt" isOpen={true} onClose={onClose} size="6xl">
      <ModalToggleButtons value={value} setValue={setValue} />

      <Grid my={6} gap={6} gridTemplateColumns="330px 1fr">
        <Stack gap={6}>
          <Stack gap={0}>
            <FormLabel>Prompt</FormLabel>
            <SelectMenu
              options={prompts ?? []}
              selected={selectedPrompt}
              handleSelect={handleSelectPrompt}
              listWidth={330}
              fw={400}
            />
          </Stack>

          <Stack gap={0}>
            <FormLabel>Output Field</FormLabel>
            <SelectMenu
              options={destinationOptions ?? []}
              selected={selectedOutput}
              handleSelect={handleSelectOutput}
              listWidth={330}
              fw={400}
            />
          </Stack>

          <Stack gap={0}>
            <FormLabel>Model</FormLabel>
            <SelectMenu
              options={modelOptions ?? []}
              selected={selectedModel}
              handleSelect={handleSelectModel}
              listWidth={330}
              fw={400}
            />
          </Stack>

          <OutlinedButton
            onClick={runPrompt}
            isLoading={isRunningPrompt}
            isDisabled={amountSelected === 0 && value === 'selected'}
            icon={faPlay}
          >
            Run
          </OutlinedButton>

          <HStack gap={2}>
            <Switch size="md" isChecked={overwrite} onChange={() => setOverwrite(!overwrite)} />
            <Text fontSize={14} color="muted">
              Overwrite existing data
            </Text>
          </HStack>
        </Stack>

        {selectedPrompt && (
          <Stack gap={6} align="start" width="auto">
            <Stack gap={0} width="100%">
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Enter prompt name"
                value={promptName}
                onChange={(e) => setPromptName(e.target.value)}
                width="100%"
              />
            </Stack>
            <Stack gap={0} width="100%">
              <FormLabel>Prompt</FormLabel>
              <Textarea
                value={promptText}
                onChange={(e) => setPromptText(e.target.value)}
                rows={20}
                fontSize={14}
                color="gray.600"
                width="100%"
              />
            </Stack>
          </Stack>
        )}
      </Grid>
    </ModalLayout>
  )
}

export default RunPromptModal
