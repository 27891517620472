import { faRefresh } from '@fortawesome/pro-regular-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

const RefreshButton = ({ ...props }) => {
  const { signalId } = useParams()
  const invalidate = useInvalidateQuery()

  const [refreshLoading, setIsLoading] = useState(false)

  const { mutateAsync: updateProspectProcess } = useMutation(() =>
    Api.post('signal/stage-action', {
      signal_id: signalId,
      action: 'signal_stage_process',
      stage: 'prospect',
    }),
  )

  const refreshSignal = async () => {
    setIsLoading(true)
    try {
      await Promise.all([
        invalidate('signal'),
        invalidate('stages-count'),
        invalidate('signal-leads'),
        updateProspectProcess(),
      ])
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <OutlinedButton icon={faRefresh} isLoading={refreshLoading} onClick={refreshSignal} {...props}>
      Refresh
    </OutlinedButton>
  )
}

export default RefreshButton
