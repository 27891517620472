import { FormLabel, Stack, Switch, Text } from '@chakra-ui/react'
import WhiteSurface from 'components/white-surface'
import { useSignal } from 'context/signal-context'
import useUpdateSignal from 'features/signal/hooks/use-update-signal'
import { useState } from 'react'

const Prospecting = () => {
  const { signal } = useSignal()

  const { updateSignal } = useUpdateSignal()

  const [prospectingOn, setProspectingOn] = useState(() => signal?.select_person)
  const [uscaProspecting, setUscaProspecting] = useState(() => signal?.prospecting_usca)
  const [emailRequired, setEmailRequired] = useState(() => signal?.email_required)

  const toggleProspecting = () => {
    setProspectingOn((prev) => !prev)
    updateSignal({ select_person: !signal?.select_person })
  }

  const toggleUscaProspec = () => {
    setUscaProspecting((prev) => !prev)
    updateSignal({ prospecting_usca: !signal?.prospecting_usca })
  }

  const toggleEmailRequired = () => {
    setEmailRequired((prev) => !prev)
    updateSignal({ email_required: !signal?.email_required })
  }

  return (
    <Stack spacing={3}>
      <Stack align="center" direction="row">
        <Switch isChecked={prospectingOn} onChange={toggleProspecting} />
        <Text fontSize={22} lineHeight="32px">
          Prospecting
        </Text>
      </Stack>

      {!prospectingOn ? (
        <WhiteSurface br={4} p={4}>
          <Text fontSize={14} color="gray.600">
            Toggle the switch to turn prospecting on.
          </Text>
        </WhiteSurface>
      ) : (
        <WhiteSurface br={4} p={4}>
          <FormLabel>United States & Canada Only</FormLabel>
          <Stack align="center" direction="row">
            <Switch isChecked={uscaProspecting} onChange={toggleUscaProspec} />
            <Text fontSize={14} color="gray.600">
              Limit prospecting to the United States and Canada. Turn off if you want to do international
              prospecting.
            </Text>
          </Stack>
          <FormLabel mt={4}>Require Email</FormLabel>
          <Stack align="center" direction="row">
            <Switch isChecked={emailRequired} onChange={toggleEmailRequired} />
            <Text fontSize={14} color="gray.600">
              Only show leads that have a verified email address. Turn this off if you want more leads that
              only have LinkedIn Profiles.
            </Text>
          </Stack>
        </WhiteSurface>
      )}
    </Stack>
  )
}

export default Prospecting
