import PageHeader from 'components/page-header'
import CampaignLeadsTable from '../components/campaign-leads-table'

const ClickLeads = () => {
  return (
    <>
      <PageHeader title="Click Leads" />
      <CampaignLeadsTable type="clicked" />
    </>
  )
}

export default ClickLeads
