import { Flex, Stack, Text } from '@chakra-ui/react'
import { faDownload } from '@fortawesome/pro-regular-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import ModalLayout from 'components/layouts/modal-layout'
import SelectMenu from 'components/select-menu'
import useNotification from 'hooks/use-notification'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

const options = [
  { title: 'Full Export', value: 'full' },
  { title: 'LinkedIn Audiences (Companies)', value: 'linkedin' },
  { title: 'SkyLead Campaign', value: 'skylead' },
]

const DownloadLeadModal = ({ query, onClose }) => {
  const { accountId, campaignId } = useParams()
  const { showSuccessMessage } = useNotification()

  const [selected, setSelected] = useState(options[0])

  const { mutate, isLoading } = useMutation(
    ({ format }) =>
      Api.get('lead-download/campaign-leads', {
        account_id: accountId,
        campaign_id: campaignId,
        query,
        format,
      }),
    {
      onSuccess: () => {
        showSuccessMessage('We have emailed you a download link for this export')
        onClose()
      },
    },
  )

  return (
    <ModalLayout header="Download Leads" isOpen={true} onClose={onClose} size="lg">
      <Stack align="center" spacing={6} mb={4}>
        <Text textAlign="center" maxW={330}>
          Generate a .CSV file that will be emailed to you for download.
        </Text>

        <Flex gap={2}>
          <SelectMenu selected={selected} options={options} handleSelect={setSelected} />
          <OutlinedButton
            onClick={() => mutate({ format: selected.value })}
            isLoading={isLoading}
            icon={faDownload}
          >
            Download
          </OutlinedButton>
        </Flex>
      </Stack>
    </ModalLayout>
  )
}

export default DownloadLeadModal
