import PageHeader from 'components/page-header'
import CampaignLeadsTable from '../components/campaign-leads-table'

const OpenLeads = () => {
  return (
    <>
      <PageHeader title="Open Leads" />
      <CampaignLeadsTable type="opened" />
    </>
  )
}

export default OpenLeads
