import {
  AbsoluteCenter,
  Box,
  Checkbox,
  Divider,
  Flex,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getSortingClass } from 'components/column-table/utils'
import LoadingSpinner from 'components/loading/loading-spinner'
import WhiteSurface from 'components/white-surface'

const TableLayout = ({
  data,
  isLoading,
  columns,
  removeColumn,
  children,
  header,
  selectItem,
  selectedItems,
  sortConfig,
  handleSort,
  paginationText,
  queryFilter,
  ...props
}) => {
  return (
    <WhiteSurface overflow="hidden" {...props}>
      <HStack padding={header ? '16px 20px' : '10px'} justify="space-between" gap={0}>
        {header}
      </HStack>

      {queryFilter && <HStack padding="4px 20px 20px">{queryFilter}</HStack>}

      {isLoading ? (
        <LoadingSpinner />
      ) : data?.results.length === 0 ? (
        <Text p="16px 20px" fontSize={14} color="muted" borderTop="1px solid" borderColor="gray.100">
          No results found...
        </Text>
      ) : (
        <Box overflowX="auto" boxShadow="sm">
          <Table>
            <Thead>
              <HeaderCell
                columns={columns}
                data={data}
                selectItem={selectItem}
                selectedItems={selectedItems}
                removeColumn={removeColumn}
                handleSort={handleSort}
                sortConfig={sortConfig}
              />
            </Thead>

            <Tbody>{children}</Tbody>
          </Table>

          {paginationText && (
            <Box position="relative" py="22px" my="14px" w="100%">
              <Divider />
              <AbsoluteCenter bg="bg-surface" fontSize={12} px={2} color="gray.400">
                {paginationText}
              </AbsoluteCenter>
            </Box>
          )}
        </Box>
      )}
    </WhiteSurface>
  )
}

const HeaderCell = ({ columns, data, selectItem, selectedItems, removeColumn, handleSort, sortConfig }) => {
  return (
    <Tr>
      {columns?.map((col) => (
        <Th key={col.id} fontSize={16} py={0}>
          <Flex align="center" gap={3} role="group" h={12} p={0}>
            {col.title === 'checkbox' ? (
              <Checkbox
                onChange={() => selectItem(data?.results, true)}
                isChecked={selectedItems.length > 0}
                size="lg"
                isIndeterminate={selectedItems.length > 0 && selectedItems.length < data.results.length}
              />
            ) : (
              <>
                <Text position="relative" mr={-3} _after={getSortingClass(col, sortConfig)}>
                  {col.title}
                </Text>
                {!col.isFixed && removeColumn && (
                  <Menu isLazy placement="bottom-end">
                    <MenuButton
                      as={IconButton}
                      aria-label="Options"
                      icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                      variant="ghost"
                      color="muted"
                      minW={0}
                      px={3}
                    />
                    <MenuList py={1} minW={0}>
                      {handleSort && (
                        <>
                          <MenuItem onClick={() => handleSort(col, 'ASC')} fontSize={14}>
                            Sort A ➞ Z
                          </MenuItem>
                          <MenuItem onClick={() => handleSort(col, 'DESC')} fontSize={14}>
                            Sort Z ➞ A
                          </MenuItem>
                        </>
                      )}
                      <MenuItem onClick={() => removeColumn(col)} fontSize={14} pr={4}>
                        Remove column
                      </MenuItem>
                    </MenuList>
                  </Menu>
                )}
              </>
            )}
          </Flex>
        </Th>
      ))}
    </Tr>
  )
}

export default TableLayout
