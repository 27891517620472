import uid from 'utils/uid'

export const intialColumns = {
  account: [
    { id: uid(), title: 'Account', display: 'name', isFixed: true },
    { id: uid(), title: 'Status', display: 'status' },
    { id: uid(), title: 'Plan', display: 'plan' },
  ],
  companies: [
    { id: uid(), title: 'Company', display: 'company', isFixed: true },
    { id: uid(), title: 'Categories', display: 'personas' },
    { id: uid(), title: 'Employees', display: 'employee_range' },
    { id: uid(), title: 'Business Model', display: 'business_model' },
  ],
  positions: [
    { id: uid(), title: 'Name', display: 'full_name', isFixed: true, filterType: 'person' },
    { id: uid(), title: 'Positions', display: 'position' },
    { id: uid(), title: 'LinkedIn', display: 'linkedin', filterType: 'person' },
    { id: uid(), title: 'Months', display: 'months_in_position' },
    { id: uid(), title: 'Role', display: 'role' },
    { id: uid(), title: 'Seniority', display: 'seniority' },
    { id: uid(), title: 'Company', display: 'domain', filterType: 'company' },
  ],
  fired: [
    { id: uid(), title: 'Name', display: 'full_name', isFixed: true, filterType: 'person' },
    { id: uid(), title: 'Positions', display: 'position' },
    { id: uid(), title: 'LinkedIn', display: 'linkedin', filterType: 'person' },
    { id: uid(), title: 'Months', display: 'months_in_position' },
    { id: uid(), title: 'Role', display: 'role' },
    { id: uid(), title: 'Seniority', display: 'seniority' },
    { id: uid(), title: 'Company', display: 'domain', filterType: 'company' },
  ],
  job_postings: [
    { id: uid(), title: 'Position', display: 'position', isFixed: true },
    { id: uid(), title: 'Role', display: 'personas' },
    { id: uid(), title: 'Netwotk', display: 'source' },
    { id: uid(), title: 'Location', display: 'location' },
    { id: uid(), title: 'Domain', display: 'domain', filterType: 'company' },
  ],
  demographics: [
    { id: uid(), title: 'Name', display: 'full_name', isFixed: true, filterType: 'person' },
    { id: uid(), title: 'Company', display: 'domain', filterType: 'company' },
    { id: uid(), title: 'Positions', display: 'position' },
    { id: uid(), title: 'Months', display: 'months_in_position' },
    { id: uid(), title: 'Role', display: 'role' },
    { id: uid(), title: 'Seniority', display: 'seniority' },
    { id: uid(), title: 'LinkedIn', display: 'linkedin', filterType: 'person' },
  ],
  fundings: [
    { id: uid(), title: 'Name', display: 'name', isFixed: true },
    { id: uid(), title: 'Company', display: 'domain', filterType: 'company' },
    { id: uid(), title: 'Type', display: 'type' },
    { id: uid(), title: 'Amount', display: 'amount' },
    { id: uid(), title: 'Announced', display: 'date_announced' },
  ],
  events: [
    { id: uid(), title: 'Type', display: 'type' },
    { id: uid(), title: 'Detected', display: 'date_created' },
    { id: uid(), title: 'Company', display: 'domain', filterType: 'company' },
    { id: uid(), title: 'Employees', display: 'employee_range', filterType: 'company' },
  ],
  signal: [
    { id: uid(), title: 'checkbox', display: 'checkbox', isHidden: true },
    { id: uid(), title: 'Company', display: 'company', isFixed: true, filterType: 'company' },
    { id: uid(), title: 'Categories', display: 'personas', filterType: 'company' },
    { id: uid(), title: 'Employees', display: 'employee_range', filterType: 'company' },
    { id: uid(), title: 'Business Model', display: 'business_model', filterType: 'company' },
  ],
  companies_pinecone: [
    { id: uid(), title: 'Company', display: 'company', isFixed: true },
    { id: uid(), title: 'Categories', display: 'personas' },
    { id: uid(), title: 'Employees', display: 'employee_range' },
    { id: uid(), title: 'Business Model', display: 'business_model' },
  ],
  query_companies: [
    { id: uid(), title: 'Company', display: 'company', isFixed: true },
    { id: uid(), title: 'Categories', display: 'personas' },
    { id: uid(), title: 'Employees', display: 'employee_range' },
    { id: uid(), title: 'Business Model', display: 'business_model' },
  ],
  query_persons: [
    { id: uid(), title: 'Name', display: 'full_name', isFixed: true, filterType: 'person' },
    { id: uid(), title: 'Positions', display: 'position' },
    { id: uid(), title: 'LinkedIn', display: 'linkedin', filterType: 'person' },
    { id: uid(), title: 'Months', display: 'months_in_position' },
    { id: uid(), title: 'Role', display: 'role' },
    { id: uid(), title: 'Seniority', display: 'seniority' },
    { id: uid(), title: 'Company', display: 'domain', filterType: 'company' },
  ],
  campaign_leads: [
    { id: uid(), title: 'checkbox', display: 'checkbox', isHidden: true },
    { id: uid(), title: 'Name', display: 'full_name', isFixed: true },
    { id: uid(), title: 'Domain', display: 'domain', filterType: 'company' },
    { id: uid(), title: 'Replied', display: 'date_replied' },
    { id: uid(), title: 'Booked', display: 'date_meeting' },
    { id: uid(), title: 'Campaign', display: 'name', filterType: 'campaign' },
    { id: uid(), title: 'User', display: 'user.fname', filterType: 'campaign' },
  ],
}
