import PageHeader from 'components/page-header'
import CampaignLeadsTable from '../components/campaign-leads-table'

const RepliedLeads = () => {
  return (
    <>
      <PageHeader title="Replied Leads" description="Leads that have replied to a campaign." />
      <CampaignLeadsTable type="replied" />
    </>
  )
}

export default RepliedLeads
