import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

const SignalContext = createContext()
export const useSignal = () => useContext(SignalContext)

export const SignalProvider = ({ children }) => {
  const { signalId } = useParams()

  const { data: signal, isLoading } = useQuery(['signal', signalId], () => Api.get(`signals/${signalId}`))

  const { data: stageCount } = useQuery(['stages-count', signalId], () =>
    Api.get('signal/get-stages', { signal_id: signalId }),
  )

  return <SignalContext.Provider value={{ signal, isLoading, stageCount }}>{children}</SignalContext.Provider>
}
