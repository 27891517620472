import { Box } from '@chakra-ui/react'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import StandardButton from 'components/buttons/standard-button'
import ModalLayout from 'components/layouts/modal-layout'
import CreateBlankSignalForm from 'features/signal-create/components/create-blank-signal-form'
import { useState } from 'react'

const CreateTemplateButtonModal = () => {
  const [IsOpen, setIsOpen] = useState(false)

  return (
    <>
      <StandardButton icon={faPlus} onClick={() => setIsOpen(true)}>
        New Template
      </StandardButton>

      {IsOpen && (
        <ModalLayout
          header="Create New Template"
          isOpen={IsOpen}
          onClose={() => setIsOpen(false)}
          width={620}
        >
          <Box borderRadius="lg">
            <CreateBlankSignalForm isTemplate />
          </Box>
        </ModalLayout>
      )}
    </>
  )
}

export default CreateTemplateButtonModal
