import { useCallback, useState } from 'react'

const useSelectItems = ({ data, unselectable = [] }) => {
  const [selectedItems, setSelectedItems] = useState([])

  const selectItem = useCallback(
    (items, selectAll = false, event) => {
      if (selectAll) {
        const selectableItems = items.filter(({ id }) => !unselectable?.includes(id))
        return setSelectedItems(selectedItems.length > 0 ? [] : selectableItems)
      }

      const newSelectedItems = items.reduce(
        (all, current) =>
          all.some(({ id }) => id === current.id)
            ? all.filter(({ id }) => id !== current.id)
            : [...all, current],
        selectedItems,
      )
      setSelectedItems(newSelectedItems)

      if (event.shiftKey && selectedItems?.length > 0) {
        const rowIndex = data.results.indexOf(items[0])
        const lastIndex = data.results.indexOf(selectedItems.at(-1))
        const range = data.results.slice(Math.min(rowIndex, lastIndex), Math.max(rowIndex, lastIndex) + 1)
        const selectableRange = range.filter(({ id }) => !unselectable?.includes(id))
        setSelectedItems(selectableRange)
      }
    },
    [selectedItems, unselectable],
  )

  const clearSelectedItems = useCallback(() => {
    setSelectedItems([])
  }, [])

  return { selectedItems, selectItem, clearSelectedItems }
}

export default useSelectItems
