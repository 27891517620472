import { Stack } from '@chakra-ui/react'
import AddFilterTraitButton from 'components/query-builder/components/add-filter-trait-button'
import RenderFilterRow from 'components/query-builder/components/render-filter-row'
import { useColumnFields } from 'context/column-fields-context'

const QueryFilterComponent = ({ queryBuilder, filterKey }) => {
  const fields = useColumnFields(filterKey)

  const { query, addFilter, removeFilter, selectAction, addFilterValue, removeFilterValue, addKeywordValue } =
    queryBuilder

  if (query?.base.length < 1) {
    return null
  }

  return (
    <Stack gap={4}>
      {query?.base?.map((filter, idx) => (
        <RenderFilterRow
          key={idx}
          fieldIndex={idx}
          filter={filter}
          removeFilter={removeFilter}
          addKeywordValue={addKeywordValue}
          addFilterValue={addFilterValue}
          removeFilterValue={removeFilterValue}
          selectAction={selectAction}
        />
      ))}
      <AddFilterTraitButton filterFields={fields} addFilter={addFilter} />
    </Stack>
  )
}

export default QueryFilterComponent
