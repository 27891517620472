import { Stack } from '@chakra-ui/react'
import CreateTemplate from './components/create-template'
import ChangeProspectingStrategy from './components/change-prospecting'

const SignalAdminSettings = () => {
  return (
    <Stack mx="auto" maxW={651} spacing="44px">
      <CreateTemplate />

      <ChangeProspectingStrategy />
    </Stack>
  )
}

export default SignalAdminSettings
