import { Button, Flex, Text } from '@chakra-ui/react'
import ModalLayout from 'components/layouts/modal-layout'

const DeleteSignalModal = ({ isOpen, dismissChange, approveChange }) => {
  return (
    <ModalLayout header="Delete Signal" isOpen={isOpen} onClose={dismissChange}>
      <Text>Are you sure you want to delete this signal?</Text>
      <Flex justify="center" gap={2} mt={6}>
        <Button colorScheme="green" onClick={approveChange}>
          Yes
        </Button>
        <Button colorScheme="red" onClick={dismissChange}>
          No
        </Button>
      </Flex>
    </ModalLayout>
  )
}

export default DeleteSignalModal
