import { SignalLeadsProvider, useSignalLeadsProps } from 'context/signal-leads-context'
import { createContext, useContext } from 'react'
import EmptyLeads from './components/empty-leads'
import LeadSingleView from './lead-single-view'
import LeadTableView from './lead-table-view'

const SignalLeadsContext = createContext()
export const useSignalLeads = () => useContext(SignalLeadsContext)

const SignalLeadsPage = ({ stage }) => (
  <SignalLeadsProvider stage={stage}>
    <SignalLeadsView />
  </SignalLeadsProvider>
)

const SignalLeadsView = () => {
  const { signalLeads, inReview, inSingleView, queryBuilder } = useSignalLeadsProps()

  if (signalLeads?.results?.length === 0 && queryBuilder?.query?.base?.length === 0) {
    return <EmptyLeads />
  }

  if (inReview && inSingleView) {
    return <LeadSingleView />
  }

  return <LeadTableView />
}

export default SignalLeadsPage
