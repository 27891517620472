import { createContext, useContext, useState } from 'react'
import { useQuery } from 'react-query'
import Api from 'utils/api'

const FilterTagsContext = createContext()

export const useFilterTags = (filter) => {
  const { savedTags, setSavedTags } = useContext(FilterTagsContext)
  const key = `${filter.resource}.${filter.column}`

  const { isLoading } = useQuery(['tags', filter], () => Api.get('tags', { key }), {
    onSuccess: (data) => setSavedTags((prevTags) => ({ ...prevTags, [key]: data.results })),
    enabled: !savedTags[key],
  })

  return { savedTags, isLoading }
}

export const FilterTagsProvider = ({ children }) => {
  const [savedTags, setSavedTags] = useState({})

  return (
    <FilterTagsContext.Provider value={{ savedTags, setSavedTags }}>{children}</FilterTagsContext.Provider>
  )
}
