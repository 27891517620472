import { FormControl, HStack, Input, Stack } from '@chakra-ui/react'
import OutlinedButton from 'components/buttons/outlined-button'
import PageHeader from 'components/page-header'
import useDebounce from 'hooks/use-debounce'
import { useState } from 'react'
import CampaignLeadsTable from '../components/campaign-leads-table'

const SearchLeads = () => {
  const [showResults, setShowResults] = useState('')
  const [searchInputValue, setSearchInputValue] = useState('')
  const searchTerm = useDebounce(searchInputValue, 250)

  const onInputChange = (e) => {
    setSearchInputValue(e.target.value)
    setShowResults(false)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setShowResults(true)
  }

  return (
    <>
      <PageHeader title="Search Leads" description="Search for leads in a campaign." />

      <Stack>
        <form onSubmit={onSubmit}>
          <HStack mb={4}>
            <FormControl width={500} isRequired>
              <Input
                type="text"
                value={searchInputValue}
                onChange={onInputChange}
                placeholder="Search for name, email, website, company name, or linkedin"
                aria-label="Search Input"
                _focus={{ boxShadow: 'none', borderColor: 'inherit' }}
                minLength={3}
              />
            </FormControl>

            <OutlinedButton type="submit">Search</OutlinedButton>
          </HStack>
        </form>
      </Stack>

      {showResults && <CampaignLeadsTable type="all" searchTerm={searchTerm} />}
    </>
  )
}

export default SearchLeads
