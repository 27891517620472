import { Avatar, HStack, Td, Text, Tr } from '@chakra-ui/react'
import ColorBox from 'components/colorful-box'
import TableLayout from 'components/layouts/table-layout'
import useUserOptions from 'hooks/dropdown-filters/use-user-options'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import uid from 'utils/uid'

const columns = [
  { id: uid(), title: 'User' },
  { id: uid(), title: 'Email' },
  { id: uid(), title: 'Linkedin' },
]

const SendingQueueTable = () => {
  const { accountId } = useParams()
  const { userOptions } = useUserOptions()

  const fetchDataForUser = async (user) => {
    const linkedInData = await Api.get('campaign-metrics/linkedin-performance', {
      account_id: accountId,
      user_id: user.userId,
    })
    const emailData = await Api.get('campaign-metrics/email-performance', {
      account_id: accountId,
      user_id: user.userId,
    })
    return {
      name: user.title,
      profile_image: user.profile_image,
      email: emailData.queue,
      linkedin: linkedInData.queue,
    }
  }

  const { data, isLoading } = useQuery(
    ['sending-queues-table', accountId, userOptions],
    async () => await Promise.all(userOptions.slice(2).map(fetchDataForUser)),
    { enabled: !!userOptions.length },
  )

  return (
    <TableLayout
      columns={columns}
      isLoading={isLoading}
      header={
        <HStack fontWeight={600} color="muted" h="41px">
          <Text>Sending Queues</Text>
        </HStack>
      }
    >
      {userOptions.length === 0 ? (
        <Tr>
          <Td>
            <Text color="muted">No results found...</Text>
          </Td>
        </Tr>
      ) : (
        data?.map((data, idx) => (
          <Tr key={idx}>
            <Td>
              <HStack>
                <Avatar bg="blue.500" color="white" src={data?.profile_image} h={6} w={6} />
                <Text color="muted">{data?.name}</Text>
              </HStack>
            </Td>

            <Td>{data?.email ? <ColorBox color="yellow" value={data?.email} /> : null}</Td>

            <Td>{data?.linkedin ? <ColorBox color="yellow" value={data?.linkedin} /> : null}</Td>
          </Tr>
        ))
      )}
    </TableLayout>
  )
}

export default SendingQueueTable
