import { createStandaloneToast } from '@chakra-ui/react'
import axios from 'axios'
import { DEFAULT_DURATION, DEFAULT_POSITION } from 'hooks/use-notification'
import capitalize from './capitalize'
import Storage from './storage'

const { toast } = createStandaloneToast()

const Api = {
  get: async (endpoint, params) => {
    return request('GET', endpoint, null, params)
  },

  post: async (endpoint, data) => {
    return request('POST', endpoint, data, null)
  },

  delete: async (endpoint) => {
    return request('DELETE', endpoint)
  },
}

export default Api

async function request(method, endpoint, data, params) {
  try {
    const response = await axios({
      method,
      url: `${process.env.REACT_APP_API_URL}/${endpoint}`,
      headers: {
        'Content-Type': 'application/json',
        ...(Storage.get('access_token') && {
          Authorization: `Bearer ${Storage.get('access_token')}`,
        }),
      },
      responseType: 'json',
      data,
      params,
    })
    return response.data
  } catch (error) {
    const { data } = error.response ?? {}
    const errorMessage = data?.message || data?.error || 'An error has occurred'
    toast({
      title: capitalize(errorMessage),
      status: 'error',
      position: DEFAULT_POSITION,
      duration: DEFAULT_DURATION,
      isClosable: true,
    })
    throw new Error()
  }
}
