import { HStack, Icon, Text } from '@chakra-ui/react'
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StyledIconButton from 'components/buttons/styled-icon-button'
import { useEffect, useRef, useState } from 'react'

const PasswordTableCell = ({ password }) => {
  const [isVisible, setIsVisible] = useState(false)
  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef.current && password) {
      const tempSpan = document.createElement('span')
      tempSpan.style.visibility = 'hidden'
      tempSpan.textContent = password
      document.body.appendChild(tempSpan)
      const width = tempSpan.getBoundingClientRect().width
      document.body.removeChild(tempSpan)
      inputRef.current.style.width = `${width}px`
    }
  }, [password])

  if (!password) return null

  const passwordDisplay = isVisible ? password : '•'.repeat(password.length)

  return (
    <HStack gap={1}>
      <Text color="muted" ref={inputRef} fontSize={isVisible ? 14 : 18}>
        {passwordDisplay}
      </Text>
      <StyledIconButton onClick={() => setIsVisible(!isVisible)}>
        <Icon icon={isVisible ? faEye : faEyeSlash} as={FontAwesomeIcon} color="muted" />
      </StyledIconButton>
    </HStack>
  )
}

export default PasswordTableCell
