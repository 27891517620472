import { useQueryClient } from 'react-query'

const useInvalidateQuery = () => {
  const queryClient = useQueryClient()

  const invalidate = (id) => queryClient.invalidateQueries(id)

  return invalidate
}

export default useInvalidateQuery
