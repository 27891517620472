import { Stack } from '@chakra-ui/react'

const WhiteSurface = ({ children, br = 8, bs = 'sm', border, ...props }) => (
  <Stack
    bg="bg-surface"
    gap={0}
    borderRadius={br}
    boxShadow={border ? 'none' : bs}
    border={border ? '1px solid' : 'none'}
    borderColor="gray.200"
    {...props}
  >
    {children}
  </Stack>
)

export default WhiteSurface
