import { Avatar, Button, Checkbox, HStack, Icon, Link, Td, Text } from '@chakra-ui/react'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { checkAndFormatString } from 'components/column-table/utils'
import { channelIcons } from 'hooks/dropdown-filters/use-channel-options'
import capitalize from 'utils/capitalize'
import { interestOptions } from './set-interest-modal'

const RenderTableCell = ({ column, lead, selectedItems, selectItem }) => {
  const { title, filterType, display } = column ?? {}
  const value = lead?.[filterType] ? lead?.[filterType]?.[display] : lead[display]

  if (display === 'checkbox') {
    return (
      <Td w={0} onClick={(e) => e.stopPropagation()}>
        <HStack>
          <Button
            onClick={(e) => selectItem([lead], false, e)}
            p={0}
            minW={0}
            bg="none"
            _hover={{ bg: 'none' }}
            _focus={{ boxShadow: 'none' }}
          >
            <Checkbox
              isChecked={selectedItems?.some(({ id }) => id === lead.id)}
              size="lg"
              iconColor="white"
              isDisabled
              sx={{
                'label:disabled:checked ~ &': {
                  cursor: 'pointer',
                },
              }}
            />
          </Button>
        </HStack>
      </Td>
    )
  }

  if (display === 'full_name') {
    return (
      <Td textAlign="start">
        <HStack color="muted">
          {lead?.replied_source ? (
            <Icon as={FontAwesomeIcon} icon={channelIcons[lead?.replied_source]} h={4} w={4} />
          ) : null}

          <Text fontWeight={600}>{checkAndFormatString(value)}</Text>
        </HStack>
      </Td>
    )
  }

  if (display === 'interest') {
    return (
      <Td textAlign="start">
        <HStack color="muted">
          {lead?.interest ? (
            <Icon
              as={FontAwesomeIcon}
              icon={interestOptions[lead?.interest].svg}
              color={interestOptions[lead?.interest].color}
              h={4}
              w={4}
            />
          ) : null}

          <Text>{capitalize(value)}</Text>
        </HStack>
      </Td>
    )
  }

  if (title === 'Domain') {
    return (
      <Td>
        <Link
          href={`https://${lead?.company?.domain}`}
          color="#3182CE"
          isExternal
          target="_blank"
          rel="noopener noreferrer"
          textDecoration="underline"
        >
          {lead?.company?.domain}
        </Link>
      </Td>
    )
  }

  if (title === 'Channel') {
    return (
      <Td>
        {channelIcons[lead?.replied_source] ? (
          <HStack color="muted">
            <Icon icon={channelIcons[lead?.replied_source]} h={5} w={5} as={FontAwesomeIcon} />
            <Text>{capitalize(lead?.replied_source)}</Text>
          </HStack>
        ) : null}
      </Td>
    )
  }

  if (title === 'Booked') {
    return (
      <Td>
        {lead?.date_meeting ? <Icon icon={faCheck} h={5} w={5} color="#51BA72" as={FontAwesomeIcon} /> : null}
      </Td>
    )
  }

  if (title === 'User') {
    return (
      <Td>
        {lead?.campaign?.user?.fname ? (
          <HStack>
            <Avatar bg="blue.500" color="white" src={lead?.campaign?.user?.profile_image} h={6} w={6} />
            <Text color="muted">{lead?.campaign?.user?.fname}</Text>
          </HStack>
        ) : null}
      </Td>
    )
  }

  if (typeof value === 'string' && value.startsWith('http')) {
    return (
      <Td textAlign="start">
        <Link
          href={value}
          color="#3182CE"
          isExternal
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          {value}
        </Link>
      </Td>
    )
  }

  return (
    <Td textAlign="start">
      <Text color="muted" whiteSpace="pre-line" fontWeight={400}>
        {checkAndFormatString(value)}
      </Text>
    </Td>
  )
}

export default RenderTableCell
