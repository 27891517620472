import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { faPlus, faXmark } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StyledIconButton from 'components/buttons/styled-icon-button'
import LoadingSpinner from 'components/loading/loading-spinner'
import { useFilterTags } from 'context/filter-tags-context'
import { useRef, useState } from 'react'

const MultiTagSelector = ({ filter, traitIndex, fieldIndex, addFilterValue }) => {
  const { savedTags, isLoading } = useFilterTags(filter)

  const filterTag = savedTags[`${filter.resource}.${filter.column}`]

  const initialFocusRef = useRef()

  const [searchTerm, setSearchTerm] = useState('')
  const [activeIndex, setActiveIndex] = useState(-1)

  const filteredTags = filterTag?.filter(
    ({ tag }) => tag.toLowerCase().includes(searchTerm.toLowerCase()) && !filter?.value?.includes(tag),
  )

  const selectTag = (tag) => {
    if (tag) {
      addFilterValue(filter, tag.tag, traitIndex, fieldIndex)
      initialFocusRef.current.focus()
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      return selectTag(filteredTags[activeIndex])
    }
    if (e.key === 'ArrowDown') {
      return setActiveIndex((prevIndex) => Math.min(prevIndex + 1, filteredTags.length - 1))
    }
    if (e.key === 'ArrowUp') {
      return setActiveIndex((prevIndex) => Math.max(prevIndex - 1, 0))
    }
  }

  return (
    <Popover isLazy initialFocusRef={initialFocusRef} returnFocusOnClose={false}>
      <PopoverTrigger>
        <Button
          variant="outline"
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
          w="70px"
          fontSize={14}
          color="gray.300"
          h={8}
          px={2}
          _focus={{ boxShadow: 'none' }}
        >
          Add
        </Button>
      </PopoverTrigger>

      <PopoverContent width="105%">
        <Box maxH={350} py={2} overflowY="auto">
          <Box p="6px 12px">
            <InputGroup>
              <Input
                size="sm"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                _focus={{ boxShadow: 'none' }}
                tabIndex="0"
                ref={initialFocusRef}
                onKeyDown={handleKeyPress}
              />
              <InputRightElement h={8}>
                <StyledIconButton onClick={() => setSearchTerm('')}>
                  <FontAwesomeIcon icon={faXmark} />
                </StyledIconButton>
              </InputRightElement>
            </InputGroup>
          </Box>

          {isLoading ? (
            <LoadingSpinner size="md" />
          ) : (
            filteredTags?.map((tag, index) => (
              <Box key={tag.tag}>
                <Button
                  w="100%"
                  variant="unstyled"
                  fontSize={14}
                  fontWeight={400}
                  lineHeight="20px"
                  p="6px 12px"
                  borderRadius={0}
                  textAlign="left"
                  tabIndex="0"
                  onClick={() => selectTag(tag)}
                  bg={index === activeIndex && 'gray.200'}
                  _hover={{ bg: 'gray.100' }}
                  _focus={{ boxShadow: 'none', bg: 'gray.300' }}
                >
                  {tag.tag}
                </Button>
              </Box>
            ))
          )}
        </Box>
      </PopoverContent>
    </Popover>
  )
}

export default MultiTagSelector
