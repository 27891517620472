import ColumnTable from 'components/column-table/column-table-component'
import PageHeader from 'components/page-header'
import Pagination from 'components/pagination'
import useHandleSearchQuery from 'components/query-builder/hooks/use-handle-search-query'
import QueryBuilder from 'components/query-builder/query-builder-component'
import { useColumnFields } from 'context/column-fields-context'
import { SignalIcon } from 'features/signal/constants/signal-icons'

const Events = () => {
  const type = 'events'

  const fields = useColumnFields(type)

  const { isLoading, searchResults, handleSearchQuery, paginationData } = useHandleSearchQuery({ type })

  return (
    <>
      <PageHeader
        title="Events"
        // description="Search for businesses using topics, categories, headcount and a variety of filters"
        icon={SignalIcon.events}
      />

      <QueryBuilder
        type={type}
        isLoading={isLoading}
        handleSearchQuery={handleSearchQuery}
        filterFields={fields}
      />

      <ColumnTable
        type={type}
        isLoading={isLoading}
        data={searchResults}
        columnFields={fields}
        header={<Pagination data={paginationData} />}
      />
    </>
  )
}

export default Events
