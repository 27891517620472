import SelectMenu from 'components/select-menu'
import { useSignalLeadsProps } from 'context/signal-leads-context'
import { signalLeadTableMenu } from 'features/signal-leads/constants/leads-actions'
import { useCallback, useState } from 'react'
import AnalyzeStageModal from '../action-modals/analyze-stage-modal'
import ApproveLeadModal from '../action-modals/approve-lead.modal'
import ArchiveLeadModal from '../action-modals/archive-lead-modal'
import DeclineLeadModal from '../action-modals/decline-lead-modal'
import DeleteLeadModal from '../action-modals/delete-lead-modal'
import DownloadLeadModal from '../action-modals/download-lead-modal'
import MoveToCampaignModal from '../action-modals/move-to-campaign-modal'
import RebuildLeadModal from '../action-modals/rebuild-lead-modal'
import RunAdminPromptModal from '../action-modals/run-admin-prompt-modal'
import RunPromptModal from '../action-modals/run-prompt-modal'

const LeadActionsDropdown = ({ addQueryFilter }) => {
  const { stage, inSingleView, selectedItems } = useSignalLeadsProps()

  const [modal, setModal] = useState({ isOpen: false, action: null })

  const onClose = () => setModal({ isOpen: false, action: null })

  const handleSelect = useCallback((option) => {
    setModal({ isOpen: true, action: option.value })
  }, [])

  return (
    <>
      <SelectMenu
        label={`Actions ${!inSingleView && selectedItems?.length ? `(${selectedItems?.length})` : ''}`}
        options={signalLeadTableMenu[stage]}
        handleSelect={handleSelect}
        type="icon"
        keepLabel
      />

      {modal.isOpen &&
        (() => {
          switch (modal.action) {
            case 'approve':
              return <ApproveLeadModal onClose={onClose} />

            case 'decline':
              return <DeclineLeadModal onClose={onClose} />

            case 'delete':
              return <DeleteLeadModal onClose={onClose} />

            case 'archive':
              return <ArchiveLeadModal onClose={onClose} />

            case 'rebuild':
              return <RebuildLeadModal onClose={onClose} />

            case 'download':
              return <DownloadLeadModal onClose={onClose} />

            case 'analyze':
              return <AnalyzeStageModal onClose={onClose} addQueryFilter={addQueryFilter} />

            case 'campaign':
              return <MoveToCampaignModal onClose={onClose} selectedItems={selectedItems} />

            case 'run_prompt':
              return <RunPromptModal onClose={onClose} />

            case 'run_admin_prompt':
              return <RunAdminPromptModal onClose={onClose} />

            default:
              return null
          }
        })()}
    </>
  )
}

export default LeadActionsDropdown
