import useSelectItems from 'components/column-table/hooks/use-select-items'
import useSortItems from 'components/column-table/hooks/use-sort-items'
import useHandleBuildQuery from 'components/query-builder/hooks/use-handle-build-query'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import Storage from 'utils/storage'
import stringify from 'utils/stringify'

const FETCHING_LIMIT = 100

const SignalLeadsContext = createContext()
export const useSignalLeadsProps = () => useContext(SignalLeadsContext)

export const SignalLeadsProvider = ({ stage, children }) => {
  const { signalId } = useParams()

  const [page, setPage] = useState(0)
  const [currentLeadIndex, setCurrentLeadIndex] = useState(0)
  const [approvedLeads, setApprovedLeads] = useState([])
  const [declinedLeads, setDeclinedLeads] = useState([])
  const [view, setView] = useState(() => Storage.get('lead-view') ?? 'single')

  const inSingleView = view === 'single'
  const inReview = stage === 'review'

  const invalidate = useInvalidateQuery()

  const {
    query,
    addFilter,
    removeFilter,
    selectAction,
    addFilterValue,
    removeFilterValue,
    addKeywordValue,
    updateQuery,
  } = useHandleBuildQuery({ type: stage, shouldNotSave: true })

  const { sortConfig, handleSort } = useSortItems({ col: null })

  const { data: signalLeads, isFetching } = useQuery(
    ['signal-leads', signalId, stage, page, sortConfig, query],
    () =>
      Api.get('signal-leads', {
        signal_id: signalId,
        stage: stage,
        limit: FETCHING_LIMIT,
        offset: page * FETCHING_LIMIT,
        sort: sortConfig.direction,
        order: sortConfig.column,
        query,
      }),
  )

  const { selectedItems, selectItem, clearSelectedItems } = useSelectItems({
    data: signalLeads,
    unselectable: [...approvedLeads, ...declinedLeads],
  })

  const selectView = (option) => {
    setView(option)
    Storage.set('lead-view', option)
    if (approvedLeads.length || declinedLeads.length) {
      invalidate('signal-leads')
    }
  }

  const setReviwedLeads = (ids, action) => {
    const idsToAdd = Array.isArray(ids) ? ids : [ids]
    if (action === 'decline') {
      setDeclinedLeads([...declinedLeads, ...idsToAdd])
    } else {
      setApprovedLeads([...approvedLeads, ...idsToAdd])
    }
  }

  useEffect(() => {
    clearSelectedItems()
    setPage(0)
    invalidate('stages-count')
    setDeclinedLeads([])
    setApprovedLeads([])
  }, [stage])

  const totalCount = signalLeads?.total_count

  const nextPage = useCallback(() => {
    if (!inSingleView) {
      setPage((prevPage) => prevPage + 1)
      setCurrentLeadIndex(0)
      return
    }
    if (currentLeadIndex + 1 === FETCHING_LIMIT) {
      setPage((prevPage) => prevPage + 1)
      setCurrentLeadIndex(0)
      return
    }
    return setCurrentLeadIndex((prev) => prev + 1)
  }, [page, view, currentLeadIndex])

  const previousPage = useCallback(() => {
    if (!inSingleView) {
      setPage((prevPage) => prevPage - 1)
      setCurrentLeadIndex(0)
      return
    }
    if (currentLeadIndex === 0) {
      setPage((prevPage) => prevPage - 1)
      setCurrentLeadIndex(FETCHING_LIMIT - 1)
      return
    }
    return setCurrentLeadIndex((prev) => prev - 1)
  }, [page, view, currentLeadIndex])

  const singleViewPage = FETCHING_LIMIT * page + currentLeadIndex

  const disablePrev = inSingleView && inReview ? singleViewPage === 0 : page === 0

  const disableNext =
    inSingleView && inReview ? singleViewPage + 1 >= totalCount : FETCHING_LIMIT * (page + 1) >= totalCount

  const paginationText = `Showing ${page * FETCHING_LIMIT + 1}-${Math.min(
    FETCHING_LIMIT * (page + 1),
    totalCount,
  )} of ${stringify(totalCount)} results`

  return (
    <SignalLeadsContext.Provider
      value={{
        stage,
        view,
        selectView,
        approvedLeads,
        declinedLeads,
        setReviwedLeads,
        isFetching,
        signalLeads,
        selectedItems,
        selectItem,
        clearSelectedItems,
        sortConfig,
        handleSort,
        inReview,
        inSingleView,
        lead: signalLeads?.results?.[currentLeadIndex],
        paginationData: {
          page: inSingleView ? singleViewPage : page,
          paginationEnabled: true,
          paginationLoading: isFetching && page === 0,
          paginationText,
          nextPage,
          previousPage,
          disablePrev,
          disableNext,
          totalCount: Number(totalCount || 0),
          FETCHING_LIMIT,
          setPage,
        },
        queryBuilder: {
          query,
          addFilter,
          removeFilter,
          selectAction,
          addFilterValue,
          removeFilterValue,
          addKeywordValue,
          updateQuery,
        },
      }}
    >
      {children}
    </SignalLeadsContext.Provider>
  )
}
