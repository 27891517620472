import {
  faArchive,
  faBackward,
  faBan,
  faChartPie,
  faDownload,
  faPaperPlane,
  faRobot,
  faThumbsDown,
  faThumbsUp,
} from '@fortawesome/pro-regular-svg-icons'

const actions = {
  approve: { title: 'Approve', value: 'approve', icon: faThumbsUp, color: '#51BA72' },
  decline: { title: 'Decline', value: 'decline', icon: faThumbsDown, color: '#EA6565' },
  archive: { title: 'Archive', value: 'archive', icon: faArchive, color: '#959AA4' },
  rebuild: { title: 'Rebuild', value: 'rebuild', icon: faBackward, color: '#959AA4' },
  delete: { title: 'Delete', value: 'delete', icon: faBan, color: '#EA6565' },
  download: { title: 'Download', value: 'download', icon: faDownload, color: '#959AA4' },
  campaign: { title: 'Move to Campaign', value: 'campaign', icon: faPaperPlane, color: '#959AA4' },
  analyze: { title: 'Analyze', value: 'analyze', icon: faChartPie, color: '#959AA4' },
  run_prompt: { title: 'Run Prompt', value: 'run_prompt', icon: faRobot, color: '#959AA4' },
  run_admin_prompt: { title: 'Admin Prompt', value: 'run_admin_prompt', icon: faRobot, color: '#959AA4' },
  divider: { value: 'Divider' },
}

export const signalLeadTableMenu = {
  review: [
    actions.approve,
    actions.decline,
    actions.divider,
    actions.rebuild,
    actions.divider,
    actions.run_prompt,
    actions.run_admin_prompt,
    actions.analyze,
    actions.divider,
    actions.delete,
  ],
  complete: [
    actions.campaign,
    actions.download,
    actions.divider,
    actions.archive,
    actions.rebuild,
    actions.divider,
    actions.run_prompt,
    actions.run_admin_prompt,
    actions.analyze,
    actions.divider,
    actions.delete,
  ],
  declined: [actions.rebuild, actions.delete, actions.analyze, actions.run_admin_prompt],
  archived: [
    actions.campaign,
    actions.download,
    actions.divider,
    actions.rebuild,
    actions.divider,
    actions.analyze,
    actions.divider,
    actions.delete,
  ],
  hopper: [actions.rebuild, actions.delete],
  prospect: [actions.rebuild, actions.delete],
  generic: [actions.rebuild, actions.delete],
}
