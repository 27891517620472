import { useSignal } from 'context/signal-context'
import { useCallback, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import Api from 'utils/api'
import Storage from 'utils/storage'
import stringify from 'utils/stringify'

const FETCHING_LIMIT = 100

const useHandleSearchQuery = ({ type, queryType, overrideQuery }) => {
  const { signal } = useSignal() ?? {}

  const [page, setPage] = useState(0)
  const [totalCount, setTotalCount] = useState(null)
  const [paginationEnabled, setPaginationEnabled] = useState(false)
  const [searchResults, setSearchResults] = useState(null)

  const fetchResults = async ({ countOnly }) => {
    const query = overrideQuery || signal?.[type] || Storage.get(`query-builder-${type}`)

    if (!overrideQuery && !query?.base?.length && !query?.traits?.length) return

    const companyQuery =
      queryType === 'companies_pinecone'
        ? signal?.query_companies ?? Storage.get(`query-builder-${queryType}`)
        : null

    return await Api.post('search', {
      query_type: queryType ?? type,
      offset: page * FETCHING_LIMIT,
      limit: FETCHING_LIMIT,
      company_query: companyQuery,
      query: query,
      count_only: countOnly ?? false,
    })
  }

  const { isFetching } = useQuery(['searchResults', page], fetchResults, {
    enabled: !(queryType && page === 0),
    onSuccess: (data) => setSearchResults(data),
  })

  const { mutate: handleSearchQuery, isLoading: searchLoading } = useMutation(fetchResults, {
    onSuccess: (data) => {
      setSearchResults(data)
      setPage(0)
    },
  })

  const { isLoading: paginationLoading } = useQuery(
    ['totalCount', searchResults],
    () => fetchResults({ countOnly: true }),
    {
      enabled: paginationEnabled && page === 0,
      onSuccess: (data) => setTotalCount(data),
    },
  )

  const loadPagination = useCallback(() => {
    setPaginationEnabled(true)
  }, [])

  const nextPage = useCallback(() => {
    setPage((prevPage) => prevPage + 1)
  }, [page])

  const previousPage = useCallback(() => {
    setPage((prevPage) => prevPage - 1)
  }, [page])

  const paginationText = `${page * FETCHING_LIMIT + 1}-${Math.min(
    FETCHING_LIMIT * (page + 1),
    totalCount,
  )} of ${stringify(totalCount)} results`

  const disablePrev = page === 0
  const disableNext = FETCHING_LIMIT * (page + 1) >= totalCount

  return {
    isLoading: isFetching || searchLoading,
    searchResults,
    handleSearchQuery,
    paginationData: {
      paginationEnabled,
      loadPagination,
      paginationLoading,
      paginationText,
      nextPage,
      previousPage,
      disablePrev,
      disableNext,
    },
  }
}

export default useHandleSearchQuery
