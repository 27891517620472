import { Textarea } from '@chakra-ui/react'
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import StandardButton from 'components/buttons/standard-button'
import ColumnTable from 'components/column-table/column-table-component'
import Pagination from 'components/pagination'
import useHandleSearchQuery from 'components/query-builder/hooks/use-handle-search-query'
import QueryBuilderComponent from 'components/query-builder/query-builder-component'
import WhiteSurface from 'components/white-surface'
import { useColumnFields } from 'context/column-fields-context'
import { useSignal } from 'context/signal-context'
import { useEffect, useState } from 'react'
import useUpdateSignal from '../hooks/use-update-signal'

const SignalQueryTrigger = () => {
  const { signal } = useSignal()

  const signalType = signal?.query_type
  const querySignal = signal?.query_signal

  const [textAreaValue, setTextAreaValue] = useState(querySignal?.keyword_query ?? '')

  const { updateSignal } = useUpdateSignal()

  const fields = useColumnFields(signalType)

  const { isLoading, searchResults, handleSearchQuery, paginationData } = useHandleSearchQuery({
    queryType: signalType,
    overrideQuery: { keyword_query: textAreaValue },
  })

  useEffect(() => {
    if (signalType !== 'companies_pinecone') return
    const autosave = setTimeout(() => updateSignal({ query_signal: { keyword_query: textAreaValue } }), 1000)
    return () => clearTimeout(autosave)
  }, [textAreaValue, signalType])

  return (
    <>
      {signalType === 'companies_pinecone' ? (
        <WhiteSurface br={8} mb={4} p={5} gap={5} border>
          <Textarea
            value={textAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
            rows={10}
            placeholder="Describe the types of companies that you want to target. Use language and keywords that can be found on the homepage of the company’s website."
          />
          <StandardButton
            icon={faMagnifyingGlass}
            onClick={handleSearchQuery}
            isLoading={isLoading}
            isDisabled={textAreaValue.length === 0}
            w="max-content"
          >
            Preview
          </StandardButton>
        </WhiteSurface>
      ) : (
        <QueryBuilderComponent
          type="query_signal"
          isLoading={isLoading}
          handleSearchQuery={handleSearchQuery}
          filterFields={fields}
          buttonText="Preview"
        />
      )}

      <ColumnTable
        type={signalType}
        isLoading={isLoading}
        data={searchResults}
        columnFields={fields}
        header={<Pagination data={paginationData} />}
      />
    </>
  )
}

export default SignalQueryTrigger
