import QueryBuilderFilterModal from 'components/query-builder/components/query-builder-filter-modal'
import SelectMenu from 'components/select-menu'
import { useColumnFields } from 'context/column-fields-context'
import { useCallback, useState } from 'react'

const filterOptions = [
  { title: 'None', value: 'none' },
  { value: 'Divider' },
  { title: 'Custom', value: 'custom' },
]

const FiltersDropdown = ({ queryBuilder }) => {
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const filterFields = useColumnFields('campaign-leads-filter')

  const { query, addFilter, updateQuery } = queryBuilder ?? {}

  const selectFilter = (filter) => {
    addFilter(filter)
    setFilterModalOpen(false)
  }

  const handleSelect = useCallback(
    (option) => {
      if (option.value === 'none' && query?.base.length > 0) {
        return updateQuery({ base: [] })
      }
      if (option.value === 'custom') {
        updateQuery({ base: [] })
        return setFilterModalOpen(true)
      }
    },
    [queryBuilder],
  )

  return (
    <>
      <SelectMenu
        label="Filters"
        options={filterOptions}
        handleSelect={handleSelect}
        keepLabel
        listWidth={160}
      />

      {filterModalOpen && (
        <QueryBuilderFilterModal
          isOpen={filterModalOpen}
          onClose={() => setFilterModalOpen(false)}
          filterFields={filterFields}
          selectFilter={selectFilter}
        />
      )}
    </>
  )
}

export default FiltersDropdown
