import MetaData from 'components/meta-data'
import PageHeader from 'components/page-header'
import { useNavigate } from 'react-router-dom'
import uid from 'utils/uid'
import CampaignsTable from './components/campaigns-table'
import SyncCampaignsButton from './components/sync-campaigns-button'

const columns = [
  { id: uid(), title: 'Name' },
  { id: uid(), title: '' },
  { id: uid(), title: 'Channel' },
  { id: uid(), title: 'Queue' },
  { id: uid(), title: 'Contacted' },
  { id: uid(), title: 'Opens' },
  { id: uid(), title: 'Replied' },
  { id: uid(), title: 'Warm' },
  { id: uid(), title: 'Meetings' },
  { id: uid(), title: 'Unsubs' },
  { id: uid(), title: 'Errors' },
  { id: uid(), title: 'User' },
]

const CampaignsPage = () => {
  const navigate = useNavigate()

  return (
    <>
      <MetaData title="Campaigns" />

      <PageHeader
        title="Campaigns"
        description="Sync your campaign and track performance across channels."
        button={<SyncCampaignsButton />}
      />

      <CampaignsTable columns={columns} selectCampaign={(campaign) => navigate(`${campaign.id}/leads`)} />
    </>
  )
}

export default CampaignsPage
