import { Flex, Spinner, Text } from '@chakra-ui/react'
import { useSignal } from 'context/signal-context'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import stringify from 'utils/stringify'

const ProccessingCountBox = () => {
  const { stageCount } = useSignal()
  const { signalId } = useParams()

  const { data: queueCount, isFetching: isFetchingQueueCount } = useQuery(
    ['signal-queue-pending', signalId],
    () =>
      Api.get('queue', {
        group_id: signalId,
        group_type: 'signals',
        status: 'PENDING',
        limit: 1,
      }),
    {
      staleTime: 2000, // Data becomes stale after 45 seconds
      enabled: !!signalId, // Only run the query if signalId is available
    },
  )

  const totalProcessingCount = stageCount?.prospect + Number(queueCount?.total_count || 0)

  if (isFetchingQueueCount || totalProcessingCount < 1) {
    return null
  }

  return (
    <Flex
      px={6}
      align="center"
      gap={2}
      borderRadius={8}
      border="1px"
      borderColor="gray.300"
      variant="outline"
      bg="gray.50"
    >
      <Spinner size="sm" />
      <Text fontWeight={600} color="muted">
        Processing {stringify(totalProcessingCount)}
      </Text>
    </Flex>
  )
}

export default ProccessingCountBox
