import { Heading, Text, VStack } from '@chakra-ui/react'
import MetaData from 'components/meta-data'

const SubPageLayout = ({ title, description, children }) => {
  return (
    <>
      <MetaData title={title} />

      <VStack spacing={0} mt={9}>
        <Heading fontSize={32} fontWeight={400} lineHeight={8} mb="15px">
          {title}
        </Heading>
        <Text fontSize={18} lineHeight={7} color="muted" textAlign="center" mb={10} maxW={515}>
          {description}
        </Text>

        {children}
      </VStack>
    </>
  )
}

export default SubPageLayout
