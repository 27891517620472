import { Input, Td, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useMutation } from 'react-query'
import Api from 'utils/api'

const EditableTableCell = ({ value, display, endpoint }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedValue, setEditedValue] = useState(value ?? '')

  const { mutate } = useMutation((payload) => Api.post(endpoint, payload))

  const handleClick = (e) => {
    e.stopPropagation()
    setIsEditing(true)
  }

  const handleSave = async (e) => {
    e.preventDefault()
    setIsEditing(false)
    if (editedValue !== value) {
      mutate({ [display]: editedValue })
    }
  }

  return (
    <Td textAlign="start" onClick={handleClick}>
      {isEditing ? (
        <form onSubmit={handleSave}>
          <Input
            value={editedValue}
            onChange={(e) => setEditedValue(e.target.value)}
            onBlur={handleSave}
            aria-label="Editable Table Cell"
            autoFocus
            fontSize={14}
          />
        </form>
      ) : (
        <Text color="muted" whiteSpace="pre-line">
          {editedValue || ''}
        </Text>
      )}
    </Td>
  )
}

export default EditableTableCell
