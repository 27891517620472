import { Text, VStack } from '@chakra-ui/react'
import { faArchive } from '@fortawesome/pro-regular-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import ModalLayout from 'components/layouts/modal-layout'
import useCampaignActions from '../hooks/use-campaign-actions'

const ArchiveCampaignModal = ({ campaign, onClose }) => {
  const { updateCampaign, isUpdating } = useCampaignActions({ campaign })

  return (
    <ModalLayout header="Archive Campaign" isOpen={true} onClose={onClose}>
      <VStack justify="center" gap={4}>
        <Text>Are you sure you want to archive this campaign?</Text>

        <OutlinedButton
          onClick={() => updateCampaign({ status: 'archived' })}
          isLoading={isUpdating}
          icon={faArchive}
        >
          Archive
        </OutlinedButton>
      </VStack>
    </ModalLayout>
  )
}

export default ArchiveCampaignModal
