import { Td, Tr } from '@chakra-ui/react'
import TableLayout from 'components/layouts/table-layout'
import UnderlineLink from 'components/links/underline-link'
import MetaData from 'components/meta-data'
import PageHeader from 'components/page-header'
import { useQuery } from 'react-query'
import Api from 'utils/api'
import uid from 'utils/uid'
import CreateOrgButtonModal from './components/create-org-button-modal'

const columns = [{ id: uid(), title: 'Account' }]

const OrganizationsPage = () => {
  const { data, isFetching } = useQuery(['organizations'], async () => await Api.get('organizations'))

  return (
    <>
      <MetaData title="Organizations" />

      <PageHeader
        title="Organizations"
        description="Manage your client’s outbound activities in once place."
        button={<CreateOrgButtonModal />}
      />

      <TableLayout columns={columns} isLoading={isFetching}>
        {data?.results?.map((org) => (
          <Tr key={org?.id}>
            <Td>
              <UnderlineLink text={org.name} path={`/org/${org?.id}/accounts`} />
            </Td>
          </Tr>
        ))}
      </TableLayout>
    </>
  )
}

export default OrganizationsPage
