import { Text } from '@chakra-ui/react'
import WhiteSurface from './white-surface'

const GridBox = ({ title, value, children, ...props }) => (
  <WhiteSurface p={6} justifyContent="space-between" {...props}>
    {title && (
      <Text color="muted" fontSize={14} lineHeight="20px" mb={2}>
        {title}
      </Text>
    )}
    {value && (
      <Text fontSize={33} fontWeight={600} lineHeight="44px">
        {value}
      </Text>
    )}
    {children}
  </WhiteSurface>
)

export default GridBox
