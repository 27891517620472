import { Button, ButtonGroup, Flex, HStack, Text } from '@chakra-ui/react'
import OutlinedButton from './buttons/outlined-button'
import LoadingSpinner from './loading/loading-spinner'

const Pagination = ({ data, onlyButton }) => {
  const {
    paginationEnabled,
    loadPagination,
    paginationLoading,
    paginationText,
    nextPage,
    previousPage,
    disablePrev,
    disableNext,
  } = data

  if (!paginationEnabled) {
    return <Button onClick={loadPagination}>Load Pages</Button>
  }

  if (paginationLoading && paginationEnabled) {
    return (
      <Flex>
        <LoadingSpinner p={0} size="md" />
      </Flex>
    )
  }

  return (
    <HStack>
      {!onlyButton && (
        <Text color="muted" fontSize={14}>
          {paginationText}
        </Text>
      )}
      {disablePrev && disableNext ? null : (
        <ButtonGroup>
          <OutlinedButton isDisabled={disablePrev} onClick={previousPage}>
            Previous
          </OutlinedButton>
          <OutlinedButton isDisabled={disableNext} onClick={nextPage}>
            Next
          </OutlinedButton>
        </ButtonGroup>
      )}
    </HStack>
  )
}

export default Pagination
