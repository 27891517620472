import { Box, Button, HStack } from '@chakra-ui/react'
import { useAuth } from 'context/auth-context'
import { NavLink } from 'react-router-dom'

const NavAdmin = () => {
  const { isAdmin } = useAuth()

  if (!isAdmin) return null

  return (
    <Box as="nav" bg="#2B2B2B" overflow="auto">
      <HStack justify="space-between" py={4} px={8}>
        <HStack gap={3}>
          <LinkButton to="/organizations">Organizations</LinkButton>
          <LinkButton to="/templates">Templates</LinkButton>
          <LinkButton to="/database/companies">Database</LinkButton>
          <LinkButton to="/training">Training</LinkButton>
        </HStack>
      </HStack>
    </Box>
  )
}

const LinkButton = ({ to, children }) => (
  <Button
    to={to}
    as={NavLink}
    variant="ghost-on-accent"
    style={({ isActive }) => ({ background: isActive && '#494949' })}
    _hover={{ background: '#494949' }}
  >
    {children}
  </Button>
)

export default NavAdmin
