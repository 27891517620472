import { Stack, Text } from '@chakra-ui/react'
import OutlinedButton from 'components/buttons/outlined-button'
import WhiteSurface from 'components/white-surface'
import { useSignal } from 'context/signal-context'
import useUpdateSignal from 'features/signal/hooks/use-update-signal'

const ArchiveSignal = () => {
  const { signal } = useSignal()
  const { updateSignal, isLoading } = useUpdateSignal()

  const isActive = signal.status === 'active'

  const buttonText = isActive ? 'Archive' : 'Activate'
  const buttonAction = isActive ? 'archived' : 'active'

  const description = isActive
    ? 'Archive the signal to stop it from processing any new leads.'
    : 'Activate the signal to start it processing new leads.'

  return (
    <Stack spacing={3}>
      <Text fontSize={22} lineHeight="32px">
        {buttonText}
      </Text>
      <WhiteSurface br={4} p={4}>
        <Text fontSize={14} color="gray.600">
          {description}
        </Text>
        <OutlinedButton
          mt={4}
          onClick={() => updateSignal({ status: buttonAction })}
          isLoading={isLoading}
          w="max-content"
        >
          {buttonText}
        </OutlinedButton>
      </WhiteSurface>
    </Stack>
  )
}

export default ArchiveSignal
