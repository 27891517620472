import { Grid, GridItem } from '@chakra-ui/react'
import { faBuilding, faEnvelope, faGlobe, faUser, faUsers } from '@fortawesome/pro-regular-svg-icons'
import NavLink from 'components/links/nav-link'
import MetaData from 'components/meta-data'
import SideMenuDivider from 'components/side-menu-divider'
import WhiteSurface from 'components/white-surface'
import { useAuth } from 'context/auth-context'
import { Outlet, useParams } from 'react-router-dom'

const AccountSettingsPageLayout = () => {
  const { isAgency } = useAuth()

  const { accountId } = useParams()

  const basePath = `/account/${accountId}/settings`

  const navLinks = {
    personal: [
      {
        label: 'User',
        icon: faUser,
        path: `${basePath}/user`,
      },
    ],
    team: [
      {
        label: 'Team',
        icon: faUsers,
        path: `${basePath}/team`,
      },
      {
        label: 'Inboxes',
        icon: faEnvelope,
        path: `${basePath}/inboxes`,
      },
      {
        label: 'Domains',
        icon: faGlobe,
        path: `${basePath}/domains`,
      },
    ],
    agency: [
      {
        label: 'Account',
        icon: faBuilding,
        path: `${basePath}/agency-account`,
      },
    ],
  }

  return (
    <>
      <MetaData title="Account Settings" />

      <Grid gap="30px" mt={17} gridTemplateColumns={{ base: '1fr', md: '235px 1fr' }}>
        <GridItem w="100%">
          <SideMenuDivider label="Personal" />

          <WhiteSurface as="nav" br={16} p={2} mb={4} gap={1}>
            {navLinks.personal.map((link, idx) => (
              <NavLink key={idx} justifyContent="start" {...link}>
                {link.label}
              </NavLink>
            ))}
          </WhiteSurface>

          <SideMenuDivider label="Team" />

          <WhiteSurface as="nav" br={16} p={2} mb={4} gap={1}>
            {navLinks.team.map((link, idx) => (
              <NavLink key={idx} justifyContent="start" {...link}>
                {link.label}
              </NavLink>
            ))}
          </WhiteSurface>

          {isAgency && (
            <>
              <SideMenuDivider label="Agency" />

              <WhiteSurface as="nav" br={16} p={2} gap={1}>
                {navLinks.agency.map((link, idx) => (
                  <NavLink key={idx} justifyContent="start" {...link}>
                    {link.label}
                  </NavLink>
                ))}
              </WhiteSurface>
            </>
          )}
        </GridItem>

        <GridItem p={1} overflow="hidden">
          <Outlet />
        </GridItem>
      </Grid>
    </>
  )
}
export default AccountSettingsPageLayout
