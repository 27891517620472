import { HStack, Icon, Stack, Switch, Text } from '@chakra-ui/react'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import {
  faBan,
  faCheck,
  faClose,
  faCopy,
  faEnvelope,
  faPaperPlane,
  faPlay,
  faReply,
  faClock,
  faUserXmark,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OutlinedButton from 'components/buttons/outlined-button'
import LoadingSpinner from 'components/loading/loading-spinner'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import pluralize from 'utils/pluralize'

const CheckConflicts = ({ selectedCampaign, selectedLeads, handleSuccess }) => {
  const { accountId } = useParams()
  const [archiveIt, setArchiveIt] = useState(true)
  const [allowDuplicates, setAllowDuplicates] = useState(false)

  const leadIds = selectedLeads.map(({ id }) => id)

  const { data, isLoading } = useQuery(
    ['signal-leads-pre-check', accountId, selectedCampaign, selectedLeads, allowDuplicates],
    () =>
      Api.post('signal-leads-pre-check', {
        account_id: accountId,
        campaign_id: selectedCampaign?.id,
        lead_ids: leadIds,
        allow_duplicates: allowDuplicates,
      }),
  )

  const { valid, errors } = data ?? {}

  const { mutate: moveToCampaign, isLoading: isMoving } = useMutation(
    () =>
      Api.post('signal-leads-add-to-campaign', {
        account_id: accountId,
        campaign_id: selectedCampaign?.id,
        lead_ids: valid,
        archive_on_success: archiveIt,
        allow_duplicates: allowDuplicates,
      }),
    { onSuccess: () => handleSuccess() },
  )

  const displayTopBox =
    errors?.active ||
    errors?.duplicate ||
    errors?.replied ||
    errors?.do_not_contact ||
    errors?.unsubscribed ||
    errors?.invalid_user ||
    errors?.missing_profile ||
    errors?.too_soon ||
    errors?.in_campaign ||
    errors?.missing_email

  if (isLoading) {
    return (
      <HStack>
        <LoadingSpinner />
      </HStack>
    )
  }

  return (
    <HStack gap={3}>
      {displayTopBox && (
        <Stack gap={4} w={280} p="25px" border="1px solid #E2E8F0" borderRadius={10}>
          {errors?.active && (
            <HStack gap={3}>
              <Icon icon={faPlay} h={5} w={5} as={FontAwesomeIcon} color="#EA6565" />
              <Text fontWeight={700}>{errors.active} Currently Active</Text>
            </HStack>
          )}
          {errors?.duplicate && (
            <HStack gap={3}>
              <Icon icon={faCopy} h={5} w={5} as={FontAwesomeIcon} color="#EA6565" />
              <Text fontWeight={700}>{errors.duplicate} Already Contacted</Text>
            </HStack>
          )}
          {errors?.replied && (
            <HStack gap={3}>
              <Icon icon={faReply} h={5} w={5} as={FontAwesomeIcon} color="#EA6565" />
              <Text fontWeight={700}>{errors.replied} Replied</Text>
            </HStack>
          )}
          {errors?.do_not_contact && (
            <HStack gap={3}>
              <Icon icon={faBan} h={5} w={5} as={FontAwesomeIcon} color="#EA6565" />
              <Text fontWeight={700}>{errors.do_not_contact} Blacklisted</Text>
            </HStack>
          )}
          {errors?.unsubscribed && (
            <HStack gap={3}>
              <Icon icon={faClose} h={5} w={5} as={FontAwesomeIcon} color="#EA6565" />
              <Text fontWeight={700}>{errors.unsubscribed} Unsubscribed</Text>
            </HStack>
          )}
          {errors?.invalid_user && (
            <HStack gap={3}>
              <Icon icon={faUserXmark} h={5} w={5} as={FontAwesomeIcon} color="#EA6565" />
              <Text fontWeight={700}>
                {errors.invalid_user} {pluralize('Campaign', errors.invalid_user)} Missing User
              </Text>
            </HStack>
          )}
          {errors?.missing_profile && (
            <HStack gap={3}>
              <Icon icon={faLinkedin} h={5} w={5} as={FontAwesomeIcon} color="#EA6565" />
              <Text fontWeight={700}>
                {errors.missing_profile} Missing {pluralize('Profile', errors.missing_profile)}
              </Text>
            </HStack>
          )}
          {errors?.missing_email && (
            <HStack gap={3}>
              <Icon icon={faEnvelope} h={5} w={5} as={FontAwesomeIcon} color="#EA6565" />
              <Text fontWeight={700}>
                {errors.missing_email} Missing {pluralize('Email', errors.missing_email)}
              </Text>
            </HStack>
          )}
          {errors?.too_soon && (
            <HStack gap={3}>
              <Icon icon={faClock} h={5} w={5} as={FontAwesomeIcon} color="#EA6565" />
              <Text fontWeight={700}>{errors.too_soon} Too Soon</Text>
            </HStack>
          )}
          {errors?.in_campaign && (
            <HStack gap={3}>
              <Icon icon={faEnvelope} h={5} w={5} as={FontAwesomeIcon} color="#EA6565" />
              <Text fontWeight={700}>{errors.in_campaign} In Campaign</Text>
            </HStack>
          )}
        </Stack>
      )}

      <Stack w={260}>
        <Stack gap={5} p="25px" alignItems="center" border="1px solid #E2E8F0" borderRadius={10}>
          {valid?.length > 0 ? (
            <HStack gap={3}>
              <Icon icon={faCheck} h={5} w={5} as={FontAwesomeIcon} color="#51BA72" />
              <Text fontWeight={700}>{valid?.length} Leads Ready</Text>
            </HStack>
          ) : (
            <HStack gap={3}>
              <Icon icon={faClose} h={5} w={5} as={FontAwesomeIcon} color="#EA6565" />
              <Text fontWeight={700}>No Leads to Move</Text>
            </HStack>
          )}

          <OutlinedButton
            w="100%"
            icon={faPaperPlane}
            onClick={moveToCampaign}
            isLoading={isMoving}
            isDisabled={!valid?.length}
          >
            Launch
          </OutlinedButton>
        </Stack>

        <Stack gap={4} p="25px" alignItems="center" border="1px solid #E2E8F0" borderRadius={10}>
          <HStack gap={3}>
            <Switch size="md" isChecked={archiveIt} onChange={() => setArchiveIt(!archiveIt)} />
            <Text fontSize={14} color="muted" w={160}>
              Archive leads after they have been successfully moved.
            </Text>
          </HStack>
          <HStack gap={3}>
            <Switch
              size="md"
              isChecked={allowDuplicates}
              onChange={() => setAllowDuplicates(!allowDuplicates)}
            />
            <Text fontSize={14} color="muted" w={160}>
              Allow duplicates if a lead is inactive and has not replied already.
            </Text>
          </HStack>
        </Stack>
      </Stack>
    </HStack>
  )
}

export default CheckConflicts
