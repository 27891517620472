/* eslint-disable react/no-unescaped-entities */
import { Button, HStack, Icon, Stack, Text } from '@chakra-ui/react'
import { faFaceFrownSlight, faFaceMeh, faFaceSmile } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalLayout from 'components/layouts/modal-layout'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

export const interestOptions = {
  warm: {
    label: 'Warm',
    value: 'warm',
    svg: faFaceSmile,
    color: '#51BA72',
  },
  maybe_later: {
    label: 'Maybe Later',
    value: 'maybe_later',
    svg: faFaceMeh,
    color: 'gray.600',
  },
  neutral: {
    label: 'Neutral',
    value: 'neutral',
    svg: faFaceMeh,
    color: 'gray.600',
  },
  forwarded: {
    label: 'Forwarded',
    value: 'forwarded',
    svg: faFaceMeh,
    color: 'gray.600',
  },
  out_of_office: {
    label: 'Out of Office',
    value: 'out_of_office',
    svg: faFaceMeh,
    color: 'gray.600',
  },
  no_longer_here: {
    label: 'No Longer Here',
    value: 'no_longer_here',
    svg: faFaceMeh,
    color: 'gray.600',
  },
  unsubscribe: {
    label: 'Unsubscribe',
    value: 'unsubscribe',
    svg: faFaceFrownSlight,
    color: 'red.600',
  },
}

const SetInterestModal = ({ isOpen, onClose }) => {
  const { leadId } = useParams()
  const invalidate = useInvalidateQuery()

  const { mutate } = useMutation(
    (interest) => Api.post('response-tracking/set-lead-interest', { lead_id: leadId, interest }),
    {
      onSuccess: () => {
        invalidate('campaign-lead')
        onClose()
      },
    },
  )

  const handleClick = (interest) => {
    mutate(interest)
  }

  return (
    <ModalLayout header="Lead Interest" isOpen={isOpen} onClose={onClose} size="xl">
      <Text textAlign="center" mb={4}>
        Mark a lead's interest level to indicate how important it is.
      </Text>

      <Stack gap={0} border="1px solid" borderColor="gray.200" borderRadius={4} overflow="hidden">
        {Object.values(interestOptions).map((option) => (
          <Button
            key={option.value}
            variant="ghost"
            borderBottom="1px solid"
            borderColor="gray.200"
            borderRadius={0}
            _last={{ borderBottom: 0 }}
            onClick={() => handleClick(option.value)}
          >
            <HStack>
              <Icon as={FontAwesomeIcon} icon={option.svg} color={option.color} h={6} w={6} />
              <Text>{option.label}</Text>
            </HStack>
          </Button>
        ))}
      </Stack>
    </ModalLayout>
  )
}

export default SetInterestModal
