import { useSignalLeadsProps } from 'context/signal-leads-context'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import { useMutation } from 'react-query'
import Api from 'utils/api'

const useLeadActions = () => {
  const invalidate = useInvalidateQuery()

  const { inReview, inSingleView, setReviwedLeads } = useSignalLeadsProps() ?? {}

  const handleSuccess = ({ leadIds, action }) => {
    if (inReview && inSingleView) {
      setTimeout(() => {
        invalidate('signal-leads')
        invalidate('stages-count')
      }, 300)
      return
    }
    setReviwedLeads(leadIds, action)
  }

  const { mutate: approveLeads, isLoading: isApproving } = useMutation(
    (leadIds) =>
      Promise.all(
        leadIds.map((leadId) =>
          Api.get('signal-leads-approve', {
            lead_id: leadId,
          }),
        ),
      ),
    { onSuccess: (_, leadIds) => handleSuccess({ leadIds, action: 'approve' }) },
  )

  const { mutate: declineLeads, isLoading: isDeclining } = useMutation(
    (leadIds) =>
      Promise.all(
        leadIds.map((leadId) =>
          Api.get('signal-leads-decline', {
            lead_id: leadId,
          }),
        ),
      ),
    { onSuccess: (_, leadIds) => handleSuccess({ leadIds, action: 'decline' }) },
  )

  const { mutate: declineContact, isLoading: isDecliningContact } = useMutation(
    (leadId) =>
      Api.get('signal-leads-decline-contact', {
        lead_id: leadId,
      }),
    { onSuccess: (_, leadIds) => handleSuccess({ leadIds, action: 'decline' }) },
  )

  return { approveLeads, isApproving, declineLeads, isDeclining, declineContact, isDecliningContact }
}

export default useLeadActions
