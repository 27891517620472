import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react'
import StandardButton from 'components/buttons/standard-button'
import { useAuth } from 'context/auth-context'
import { useState } from 'react'
import { useMutation } from 'react-query'
import Api from 'utils/api'
import AuthPageLayout from '../layout/auth-page-layout'

const Register = () => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [companyName, setCompanyName] = useState('')

  const { authenticateUser } = useAuth()

  const { mutate, isLoading } = useMutation(
    () =>
      Api.post('authenticate/register', {
        email: email,
        password: password,
        fname: firstName,
        lname: lastName,
        company_name: companyName,
      }),
    {
      onSuccess: (data) => {
        if (data.token) {
          authenticateUser(data.token)
        }
      },
    },
  )

  const onSubmit = (e) => {
    e.preventDefault()
    mutate()
  }

  const subHeader = {
    cta: 'Already have an account?',
    linkText: 'Back to login',
    path: '/login',
  }

  return (
    <AuthPageLayout onSubmit={onSubmit} title="Sign up" subHeader={subHeader}>
      <Flex direction="row">
        <FormControl id="firstName" isRequired mt={3} mr={2}>
          <FormLabel>First Name</FormLabel>
          <Input
            type="text"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First"
          />
        </FormControl>
        <FormControl id="lastName" isRequired mt={3} ml={2}>
          <FormLabel>Last Name</FormLabel>
          <Input
            type="text"
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last"
          />
        </FormControl>
      </Flex>
      <FormControl id="email" isRequired mt={3}>
        <FormLabel>Work Email</FormLabel>
        <Input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="email@example.com"
        />
      </FormControl>
      <FormControl id="password" isRequired mt={3}>
        <FormLabel>Password</FormLabel>
        <Input
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="**********"
        />
      </FormControl>
      <FormControl id="companyName" isRequired mt={3}>
        <FormLabel>Company</FormLabel>
        <Input
          type="text"
          name="companyName"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          placeholder="Your Company"
        />
      </FormControl>

      <StandardButton type="submit" isLoading={isLoading}>
        Sign Up
      </StandardButton>
    </AuthPageLayout>
  )
}

export default Register
