import { Flex, FormLabel, Input, Stack, Text } from '@chakra-ui/react'
import OutlinedButton from 'components/buttons/outlined-button'
import SelectMenu from 'components/select-menu'
import WhiteSurface from 'components/white-surface'
import { useAccount } from 'context/account-context'
import { useEffect, useState } from 'react'
import useUpdateAccountSettings from '../hooks/use-update-account-settings'

const planOptions = [
  { title: 'Trial', value: 'trial' },
  { title: 'Data', value: 'data' },
  { title: 'Startup', value: 'startup' },
  { title: 'Campaigns', value: 'campaigns' },
  { title: 'Managed', value: 'managed' },
]

const statusOptions = [
  { title: 'Active', value: 'active' },
  { title: 'Archived', value: 'archived' },
  { title: 'Onboarding', value: 'onboarding' },
  { title: 'Setup', value: 'setup' },
]

const AgencySettings = () => {
  const { account } = useAccount()

  const { name, plan, status, total_credits, monthly_credits, reply_api_key, quickmail_account_id } =
    account ?? {}

  const [inputName, setName] = useState(() => name ?? '')
  const [inputPlan, setPlan] = useState(() => planOptions.find((opt) => opt.value === plan))
  const [inputStatus, setStatus] = useState(() => statusOptions.find((opt) => opt.value === status))
  const [credits, setCredits] = useState(() => total_credits ?? '')
  const [monthlyCredits, setMonthlyCredits] = useState(() => monthly_credits ?? '')
  const [replyApiKey, setReplyApiKey] = useState(() => reply_api_key ?? '')
  const [quickmailAccountId, setQuickmailAccountId] = useState(() => quickmail_account_id ?? '')

  useEffect(() => {
    if (account) {
      setName(name ?? '')
      setPlan(planOptions.find((opt) => opt.value === plan))
      setStatus(statusOptions.find((opt) => opt.value === status))
      setCredits(total_credits ?? '')
      setMonthlyCredits(monthly_credits ?? '')
      setReplyApiKey(reply_api_key ?? '')
      setQuickmailAccountId(quickmail_account_id ?? '')
    }
  }, [account])

  const { updateAccount, isLoading } = useUpdateAccountSettings()

  const handleUpdateAccountInfo = (e) => {
    e.preventDefault()
    updateAccount({ name: inputName, plan: inputPlan?.value, status: inputStatus?.value })
  }

  const handleUpdateCredits = (e) => {
    e.preventDefault()
    updateAccount({ total_credits: credits, monthly_credits: monthlyCredits })
  }

  const handleUpdateIntegrations = (e) => {
    e.preventDefault()
    updateAccount({ reply_api_key: replyApiKey, quickmail_account_id: quickmailAccountId })
  }

  return (
    <Stack mx="auto" maxW={651} spacing={3}>
      <Text fontSize={22} lineHeight="32px">
        Account Information
      </Text>
      <WhiteSurface br={4} p={4}>
        <form onSubmit={handleUpdateAccountInfo}>
          <RenderInput
            label="Account Name"
            value={inputName}
            onChange={setName}
            placeholder="Account Name"
            mt={0}
          />
          <Stack w={320} spacing={0} mt={4}>
            <FormLabel>Plan</FormLabel>
            <SelectMenu
              selected={inputPlan}
              options={planOptions}
              handleSelect={setPlan}
              listWidth={320}
              fw={400}
            />
          </Stack>

          <Stack w={320} spacing={0} mt={4}>
            <FormLabel>Status</FormLabel>
            <SelectMenu
              selected={inputStatus}
              options={statusOptions}
              handleSelect={setStatus}
              listWidth={320}
              fw={400}
            />
          </Stack>

          <OutlinedButton mt={4} type="submit" isLoading={isLoading}>
            Save
          </OutlinedButton>
        </form>
      </WhiteSurface>

      <Text fontSize={22} lineHeight="32px" mt={10}>
        Integrations
      </Text>
      <WhiteSurface br={4} p={4}>
        <form onSubmit={handleUpdateIntegrations}>
          <RenderInput
            label="Reply API Key"
            value={replyApiKey}
            onChange={setReplyApiKey}
            placeholder="Enter Reply API Key"
            mt={0}
          />
          <RenderInput
            label="QuickMail Account ID"
            value={quickmailAccountId}
            onChange={setQuickmailAccountId}
            placeholder="Enter QuickMail Account ID"
            mt={4}
          />
          <OutlinedButton mt={4} type="submit" isLoading={isLoading}>
            Save
          </OutlinedButton>
        </form>
      </WhiteSurface>

      <Text fontSize={22} lineHeight="32px" mt={10}>
        Credits
      </Text>
      <WhiteSurface br={4} p={4}>
        <form onSubmit={handleUpdateCredits}>
          <Flex gap={3}>
            <Stack spacing={0} w="50%">
              <RenderInput
                label="Current Credits"
                value={credits}
                onChange={setCredits}
                placeholder="0"
                type="number"
                mt={0}
              />
            </Stack>
            <Stack spacing={0} w="50%">
              <RenderInput
                label="Monthly Recurring Credits"
                value={monthlyCredits}
                onChange={setMonthlyCredits}
                placeholder="0"
                type="number"
                mt={0}
              />
            </Stack>
          </Flex>
          <OutlinedButton mt={4} type="submit" isLoading={isLoading}>
            Update
          </OutlinedButton>
        </form>
      </WhiteSurface>
    </Stack>
  )
}

const RenderInput = ({ label, value, onChange, placeholder, mt = 4, type = 'text', ...props }) => (
  <>
    <FormLabel mt={mt}>{label}</FormLabel>
    <Input
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      {...props}
    />
  </>
)

export default AgencySettings
