import { Stack, Text } from '@chakra-ui/react'
import MetaData from 'components/meta-data'
import { useAuth } from 'context/auth-context'
import { useCurrentOrg } from 'context/current-organization'
import { useQuery } from 'react-query'
import Api from 'utils/api'
import TeamTable from './components/team-table'

const TeamPage = () => {
  const { organizationId } = useAuth()
  const { currentOrgId } = useCurrentOrg()

  const { data: activeUsers, isFetching: isFetchingActiveUsers } = useQuery(
    ['activeUsers'],
    async () => await Api.get('users', { activated: true, organization_id: currentOrgId ?? organizationId }),
  )

  const { data: inactiveUsers, isFetching: isFetchingInactiveUsers } = useQuery(
    ['inactiveUsers'],
    async () => await Api.get('users', { activated: false, organization_id: currentOrgId ?? organizationId }),
  )

  return (
    <>
      <MetaData title="Team" />

      <Stack maxW="none">
        <Text fontSize={22} lineHeight="32px" mt={4}>
          Active
        </Text>
        <TeamTable data={activeUsers} isLoading={isFetchingActiveUsers} />

        <Text fontSize={22} lineHeight="32px" mt={10}>
          Inactive
        </Text>
        <TeamTable data={inactiveUsers} isLoading={isFetchingInactiveUsers} />
      </Stack>
    </>
  )
}

export default TeamPage
