import MetaData from 'components/meta-data'
import PageHeader from 'components/page-header'
import SelectMenu from 'components/select-menu'
import { useAuth } from 'context/auth-context'
import { useCurrentOrg } from 'context/current-organization'
import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import Api from 'utils/api'
import Storage from 'utils/storage'
import uid from 'utils/uid'
import DomainsTable from './components/domains-table'

const columns = [
  { id: uid(), title: 'Domain' },
  { id: uid(), title: 'Account' },
  { id: uid(), title: 'MX' },
  { id: uid(), title: 'Age' },
  { id: uid(), title: 'Outreach' },
  { id: uid(), title: 'Inboxes' },
  { id: uid(), title: 'Reply' },
  { id: uid(), title: 'Opens' },
  { id: uid(), title: 'Glock' },
  { id: uid(), title: 'DNS' },
]

const planOptions = [
  { title: 'All Plans', value: null },
  { value: 'Divider' },
  { title: 'Trial', value: 'trial' },
  { title: 'Data', value: 'data' },
  { title: 'Startup', value: 'startup' },
  { title: 'Campaigns', value: 'campaigns' },
  { title: 'Managed', value: 'managed' },
]

const DomainsPage = () => {
  const { currentOrgId } = useCurrentOrg()
  const { isAdmin, organizationId } = useAuth()

  const [plan, setPlan] = useState(() => Storage.get('domains-table-plan') || planOptions[0])

  const selectPlan = useCallback((plan) => {
    setPlan(plan)
    Storage.set('domains-table-plan', plan)
  }, [])

  const { data, isFetching } = useQuery(
    ['inbox-domains', plan],
    async () =>
      await Api.get('inbox-domains', {
        plan: plan?.value ?? null,
        organization_id: currentOrgId ?? (isAdmin ? null : organizationId),
        account_status: 'active',
      }),
  )

  return (
    <>
      <MetaData title="Domains" />

      <PageHeader title="Domains" description="Track domain configuration, health, and deliverability." />

      <DomainsTable
        data={data}
        isLoading={isFetching}
        columns={columns}
        header={<SelectMenu selected={plan} options={planOptions} handleSelect={selectPlan} />}
      />
    </>
  )
}

export default DomainsPage
