import { HStack, Icon, Td, Text, Tr } from '@chakra-ui/react'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import StandardButton from 'components/buttons/standard-button'
import ColorBox from 'components/colorful-box'
import TableLayout from 'components/layouts/table-layout'
import UnderlineLink from 'components/links/underline-link'
import MetaData from 'components/meta-data'
import PageHeader from 'components/page-header'
import SelectMenu from 'components/select-menu'
import StatusDot from 'components/status-dot'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Api from 'utils/api'
import capitalize from 'utils/capitalize'
import Storage from 'utils/storage'
import uid from 'utils/uid'
import { SignalIcon } from '../signal/constants/signal-icons'

const columns = [
  { id: uid(), title: 'Name' },
  { id: uid(), title: 'Status' },
  { id: uid(), title: 'Type' },
  { id: uid(), title: 'Review' },
  { id: uid(), title: 'Approved' },
  { id: uid(), title: 'Archived' },
  { id: uid(), title: 'Declined' },
]

const statusOptions = [
  { id: uid(), title: 'All Status', value: null },
  { id: uid(), value: 'Divider' },
  { id: uid(), title: 'Active', value: 'active' },
  { id: uid(), title: 'Archived', value: 'archived' },
]

const SignalsOverview = () => {
  const navigate = useNavigate()
  const { accountId } = useParams()

  const [status, setStatus] = useState(() => Storage.get('signals-table-status') || statusOptions[0])

  const { data: signals, isLoading } = useQuery(
    ['signals', accountId, status],
    () =>
      Api.get('signals', {
        account_id: accountId,
        status: status?.value,
      }),
    { enabled: !isNaN(Number(accountId)) },
  )

  const selectSignalStatus = (status) => {
    setStatus(status)
    Storage.set('signals-table-status', status)
  }

  return (
    <>
      <MetaData title="Signals" />

      <PageHeader
        title="Signals"
        description="Create, view, and edit your Signals"
        button={
          <StandardButton icon={faPlus} onClick={() => navigate(`/account/${accountId}/signals/create`)}>
            New Signal
          </StandardButton>
        }
      />

      <TableLayout
        columns={columns}
        isLoading={isLoading}
        header={
          <SelectMenu
            selected={status}
            options={statusOptions}
            handleSelect={selectSignalStatus}
            type="status"
          />
        }
      >
        {signals?.results?.length === 0 ? (
          <Tr>
            <Td>
              <Text color="muted">No results found...</Text>
            </Td>
          </Tr>
        ) : (
          signals?.results?.map((signal) => (
            <Tr key={signal.id}>
              <Td w="20%">
                <UnderlineLink
                  text={signal?.name}
                  path={`/account/${accountId}/signal/${signal.id}/review`}
                />
              </Td>
              <Td>
                <HStack>
                  <StatusDot status={signal.status} />
                  <Text color="muted">{capitalize(signal.status)}</Text>
                </HStack>
              </Td>
              <Td>
                <HStack>
                  <Icon icon={SignalIcon[signal.query_type]} as={FontAwesomeIcon} h={5} w={5} color="muted" />
                  <Text color="muted">{signal.query_type_display}</Text>
                </HStack>
              </Td>
              <Td>
                {signal.stage_counts?.review ? (
                  <ColorBox color="yellow" value={signal.stage_counts.review} />
                ) : null}
              </Td>
              <Td>
                {signal.stage_counts?.complete ? (
                  <ColorBox color="green" value={signal.stage_counts.complete} />
                ) : null}
              </Td>
              <Td>
                {signal.stage_counts?.archived ? (
                  <ColorBox color="gray" value={signal.stage_counts.archived} />
                ) : null}
              </Td>
              <Td>
                {signal.stage_counts?.declined ? (
                  <ColorBox color="red" value={signal.stage_counts.declined} />
                ) : null}
              </Td>
            </Tr>
          ))
        )}
      </TableLayout>
    </>
  )
}

export default SignalsOverview
