import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import Api from 'utils/api'
import Storage from 'utils/storage'
import { useAuth } from './auth-context'

const ColumnFieldsContext = createContext()

export const useColumnFields = (type) => {
  const { company, jobs, person, position, email, signal, funding, event, lead, campaign, inbox, account } =
    useContext(ColumnFieldsContext)

  const { isAdmin } = useAuth()

  if (type === 'companies' || type === 'query_companies' || type === 'companies_pinecone') {
    return filterFieldsByAdmin({ company }, isAdmin)
  }
  if (type === 'jobs-db') {
    return filterFieldsByAdmin({ jobs, company }, isAdmin)
  }
  if (type === 'positions') {
    return filterFieldsByAdmin({ person, position, company }, isAdmin)
  }
  if (type === 'fired') {
    return filterFieldsByAdmin({ person, position, company }, isAdmin)
  }
  if (type === 'signal-leads') {
    return filterFieldsByAdmin({ company, person, position, email, signal }, isAdmin)
  }
  if (type === 'demographics' || type === 'query_persons') {
    return filterFieldsByAdmin({ person, position }, isAdmin)
  }
  if (type === 'fundings') {
    return filterFieldsByAdmin({ funding, company }, isAdmin)
  }
  if (type === 'job_postings') {
    return filterFieldsByAdmin({ jobs }, isAdmin)
  }
  if (type === 'events') {
    return filterFieldsByAdmin({ company, event }, isAdmin)
  }
  if (type === 'campaign-leads') {
    return filterFieldsByAdmin({ lead, company, campaign, inbox }, isAdmin)
  }
  if (type === 'campaign-leads-filter') {
    return filterFieldsByAdmin({ lead, company, campaign }, isAdmin)
  }
  if (type === 'account') {
    return filterFieldsByAdmin({ account }, isAdmin)
  }
  return null
}

export const ColumnFieldsProvider = ({ children }) => {
  const { data: company } = useQuery(['fields-company'], () => {
    return getColumnFields('companies-fields')
  })

  const { data: jobs } = useQuery(['fields-jobs'], () => {
    return getColumnFields('jobs-fields', 'job_postings')
  })

  const { data: person } = useQuery(['fields-person'], () => {
    return getColumnFields('persons2-fields')
  })

  const { data: fired } = useQuery(['fields-fired'], () => {
    return getColumnFields('persons2-fields')
  })

  const { data: position } = useQuery(['fields-position'], () => {
    return getColumnFields('positions-fields')
  })

  const { data: email } = useQuery(['fields-email'], () => {
    return getColumnFields('emails2-fields')
  })

  const { data: signal } = useQuery(['fields-signal'], () => {
    return getColumnFields('signal-leads-fields', 'signal_leads')
  })

  const { data: funding } = useQuery(['fields-fundings'], () => {
    return getColumnFields('fundings-fields')
  })

  const { data: event } = useQuery(['fields-events'], () => {
    return getColumnFields('events-fields')
  })

  const { data: lead } = useQuery(['fields-leads'], () => {
    return getColumnFields('campaign-leads-fields', 'campaign_leads')
  })

  const { data: campaign } = useQuery(['fields-campaign'], () => {
    return getColumnFields('campaigns-fields')
  })

  const { data: inbox } = useQuery(['fields-inbox'], () => {
    return getColumnFields('inboxes-fields')
  })

  const { data: account } = useQuery(['fields-account'], () => {
    return getColumnFields('account-fields')
  })

  return (
    <ColumnFieldsContext.Provider
      value={{
        company,
        jobs,
        person,
        position,
        fired,
        email,
        signal,
        funding,
        event,
        lead,
        campaign,
        inbox,
        account,
      }}
    >
      {children}
    </ColumnFieldsContext.Provider>
  )
}

// This function fetches and caches column field data from a given endpoint every 24 hours.
// If the data is already stored on local storage and not expired, it returns the cached data.
// Otherwise, it fetches the data from the API, adds a resource type to each result, caches the new data with an expiry time, and then returns the data.
const getColumnFields = async (endpoint, resourceType) => {
  const now = new Date()
  const expiryTime = 24 * 60 * 60 * 1000 // 24 hours in milliseconds
  const storedData = Storage.get(endpoint)

  if (storedData && now.getTime() < storedData.expiry) {
    return storedData.value
  }

  const res = await Api.get(endpoint)
  const resWithResource = res.results.map((result) => ({
    ...result,
    resource: resourceType ?? endpoint.split('-')[0],
  }))

  Storage.set(endpoint, {
    expiry: now.getTime() + expiryTime,
    value: resWithResource,
  })

  return resWithResource
}

const filterFieldsByAdmin = (fields, isAdmin) => {
  const filteredFields = {}

  for (const [key, value] of Object.entries(fields)) {
    if (isAdmin) {
      filteredFields[key] = value?.filter(({ admin }) => !admin)
      filteredFields[`${key} admin`] = value?.filter(({ admin }) => admin)
    } else {
      filteredFields[key] = value?.filter(({ admin }) => !admin)
    }
    if (filteredFields[`${key} admin`]?.length === 0) {
      delete filteredFields[`${key} admin`]
    }
  }

  return filteredFields
}
