import { FormLabel, Input, Stack, Text, Textarea } from '@chakra-ui/react'
import OutlinedButton from 'components/buttons/outlined-button'
import WhiteSurface from 'components/white-surface'
import { useSignal } from 'context/signal-context'
import useUpdateSignal from 'features/signal/hooks/use-update-signal'
import { useState } from 'react'

const BasicSettings = () => {
  const { signal } = useSignal()
  const { updateSignal, isLoading } = useUpdateSignal()

  const [name, setName] = useState(() => signal?.name ?? '')
  const [description, setDescription] = useState(() => signal?.description ?? '')

  const onSubmit = (e) => {
    e.preventDefault()
    updateSignal({ name, description })
  }

  return (
    <Stack spacing={3}>
      <Text fontSize={22} lineHeight="32px">
        Settings
      </Text>
      <WhiteSurface br={4} p={4}>
        <form onSubmit={onSubmit}>
          <FormLabel>Name</FormLabel>
          <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="First" />
          <FormLabel mt={4}>Description</FormLabel>
          <Textarea
            placeholder="Here is a simple placeholder."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            color="#4A5568"
            fontSize={14}
          />

          <OutlinedButton mt={4} type="submit" isLoading={isLoading}>
            Save
          </OutlinedButton>
        </form>
      </WhiteSurface>
    </Stack>
  )
}

export default BasicSettings
