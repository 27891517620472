import PageHeader from 'components/page-header'

const Ecommerce = () => {
  return (
    <>
      <PageHeader title="E-commerce" />
    </>
  )
}

export default Ecommerce
