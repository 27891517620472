import {
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react'
import { faClose, faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ColumnButton from 'components/column-table/components/column-button'
import useTableColumns from 'components/column-table/hooks/use-table-columns'
import { checkAndFormatString } from 'components/column-table/utils'
import TableLayout from 'components/layouts/table-layout'
import UnderlineLink from 'components/links/underline-link'
import MetaData from 'components/meta-data'
import PageHeader from 'components/page-header'
import SelectMenu from 'components/select-menu'
import StatusDot from 'components/status-dot'
import { useAuth } from 'context/auth-context'
import { useColumnFields } from 'context/column-fields-context'
import useDebounce from 'hooks/use-debounce'
import { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import Api from 'utils/api'
import capitalize from 'utils/capitalize'
import Storage from 'utils/storage'
import CreateAccountButtonModal from './components/create-account-button-modal'

const statusOptions = [
  { title: 'All Status', value: null },
  { value: 'Divider' },
  { title: 'Active', value: 'active' },
  { title: 'Archived', value: 'archived' },
  { title: 'Onboarding', value: 'onboarding' },
  { title: 'Setup', value: 'setup' },
]

const planOptions = [
  { title: 'All Plans', value: null },
  { value: 'Divider' },
  { title: 'Trial', value: 'trial' },
  { title: 'Data', value: 'data' },
  { title: 'Startup', value: 'startup' },
  { title: 'Campaigns', value: 'campaigns' },
  { title: 'Managed', value: 'managed' },
]

const AccountsPage = () => {
  const { isAdmin, organizationId } = useAuth()
  const { organizationId: orgIdFromParams } = useParams()

  const type = 'account'
  const fields = useColumnFields(type)
  const { activeColumns, addColumn, removeColumn, dragColumn } = useTableColumns(type)

  const [accountPlan, setPlan] = useState(() => Storage.get('account-table-plan') || planOptions[0])
  const [accountStatus, setStatus] = useState(() => Storage.get('account-table-status') || statusOptions[0])

  const [searchTerm, setSearchTerm] = useSearchParams({ search: '' })
  const inputValue = searchTerm.get('search')
  const dbSearchTerm = useDebounce(inputValue, 250)

  const [inputFocus, setInputFocus] = useState(() => !!searchTerm.get('search'))

  const { data, isFetching } = useQuery(
    ['accounts', accountStatus, accountPlan, organizationId, dbSearchTerm],
    () =>
      Api.get('accounts', {
        status: inputFocus ? null : accountStatus?.value,
        plan: inputFocus ? null : accountPlan?.value,
        filter: dbSearchTerm || null,
        organization_id: orgIdFromParams ?? (isAdmin ? null : organizationId),
      }),
  )

  const selectAccountStatus = useCallback((status) => {
    setStatus(status)
    Storage.set('account-table-status', status)
  }, [])

  const selectAccountPlan = useCallback((plan) => {
    setPlan(plan)
    Storage.set('account-table-plan', plan)
  }, [])

  const onSearch = (e) => {
    setSearchTerm(
      (prev) => {
        prev.set('search', e.target.value)
        return prev
      },
      { replace: true },
    )
  }

  const cleanInput = () => {
    setSearchTerm(
      (prev) => {
        prev.set('search', '')
        return prev
      },
      { replace: true },
    )
    setInputFocus(false)
  }

  return (
    <>
      <MetaData title="Accounts" />

      <PageHeader
        title="Accounts"
        description="Manage your client’s outbound activities in once place."
        button={<CreateAccountButtonModal />}
      />

      <TableLayout
        data={data}
        columns={activeColumns}
        isLoading={isFetching}
        header={
          <>
            {inputFocus || (
              <Flex gap={3}>
                <ColumnButton
                  columns={fields}
                  activeColumns={activeColumns}
                  addColumn={addColumn}
                  removeColumn={removeColumn}
                  dragColumn={dragColumn}
                />
                <SelectMenu
                  selected={accountStatus}
                  options={statusOptions}
                  handleSelect={selectAccountStatus}
                  type="status"
                />
                <SelectMenu selected={accountPlan} options={planOptions} handleSelect={selectAccountPlan} />
              </Flex>
            )}

            <InputGroup w={inputFocus ? '100%' : 321}>
              <Input
                placeholder="Search Accounts"
                aria-label="Search Accounts Input"
                type="text"
                value={inputValue}
                onChange={onSearch}
                onFocus={() => setInputFocus(true)}
              />
              <InputRightElement>
                <IconButton size="sm" onClick={cleanInput}>
                  <Icon as={FontAwesomeIcon} icon={faClose} />
                </IconButton>
              </InputRightElement>
            </InputGroup>
          </>
        }
      >
        {useMemo(
          () =>
            data?.results.map((account) => (
              <Tr key={account?.id}>
                {activeColumns?.map((col) => (
                  <Td key={col.id}>
                    <RenderTableCell account={account} column={col} />
                  </Td>
                ))}
              </Tr>
            )),
          [data, activeColumns],
        )}
      </TableLayout>
    </>
  )
}

const RenderTableCell = ({ account, column }) => {
  const { title, display, filterType } = column
  const value = account?.[filterType] ? account?.[filterType]?.[display] : account[display]

  if (title === 'Account') {
    return <UnderlineLink text={value} path={`/account/${account?.id}/dashboard`} />
  }

  if (title === 'Status') {
    return (
      <HStack>
        <StatusDot status={value} />
        <Text color="muted">{capitalize(value)}</Text>
      </HStack>
    )
  }

  if (title === 'Plan') {
    return <Text color="muted">{capitalize(value)}</Text>
  }

  if (title === 'Contract Ends') {
    return <Text color="muted">{value} Days</Text>
  }

  if (title === 'Review' && value) {
    return (
      <HStack>
        <StatusDot status="review" />
        <Text color="muted">{value.toLocaleString('en-US')}</Text>
      </HStack>
    )
  }

  if (title === 'Approved' && value) {
    return (
      <HStack>
        <StatusDot status="approved" />
        <Text color="muted">{value.toLocaleString('en-US')}</Text>
      </HStack>
    )
  }

  if (title === 'Queue Email' && value) {
    return (
      <HStack color="muted">
        <Icon as={FontAwesomeIcon} icon={faEnvelope} h={5} w={5} color="muted" />
        <Text color="muted">{value.toLocaleString('en-US')}</Text>
      </HStack>
    )
  }

  if (typeof value === 'boolean') {
    return <Text color="muted">{value ? 'Yes' : 'No'}</Text>
  }

  if (display.endsWith('rate')) {
    return <Text color="muted">{value ? `${value}%` : null}</Text>
  }

  if (typeof value === 'string' && value.startsWith('http')) {
    return (
      <Td textAlign="start">
        <Link
          href={value}
          color="#3182CE"
          isExternal
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          {value}
        </Link>
      </Td>
    )
  }

  return (
    <Text color="muted" whiteSpace="pre-line">
      {checkAndFormatString(value)}
    </Text>
  )
}

export default AccountsPage
