import { Button, Flex, Stack, Text, useDisclosure } from '@chakra-ui/react'
import ModalLayout from 'components/layouts/modal-layout'
import WhiteSurface from 'components/white-surface'
import useNotification from 'hooks/use-notification'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Api from 'utils/api'

const DomainDelete = () => {
  const navigate = useNavigate()
  const { domainId } = useParams()
  const { showSuccessMessage } = useNotification()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { mutate: deleteSignal, isLoading } = useMutation(() => Api.delete(`inbox-domains/${domainId}`), {
    onSuccess: () => {
      showSuccessMessage('Domain deleted')
      navigate(-1)
    },
  })

  const approveChange = () => {
    onClose()
    deleteSignal()
  }

  return (
    <>
      <Stack spacing={3} mb="38px">
        <Text fontSize={22} lineHeight="32px">
          Delete
        </Text>
        <WhiteSurface br={4} p={4}>
          <Text fontSize={14} color="gray.600">
            Permanently delete this domain.
          </Text>
          <Button
            mt={4}
            bg="#EA6565"
            color="white"
            _hover={{ bg: 'red.500' }}
            onClick={onOpen}
            isLoading={isLoading}
            w={83}
          >
            Delete
          </Button>
        </WhiteSurface>
      </Stack>

      {isOpen && (
        <ModalLayout header="Delete Domain" isOpen={isOpen} onClose={onClose}>
          <Text>Are you sure you want to delete this domain?</Text>
          <Flex justify="center" gap={2} mt={6}>
            <Button colorScheme="green" onClick={approveChange}>
              Yes
            </Button>
            <Button colorScheme="red" onClick={onClose}>
              No
            </Button>
          </Flex>
        </ModalLayout>
      )}
    </>
  )
}

export default DomainDelete
