import MetaData from 'components/meta-data'
import PageHeader from 'components/page-header'
import CampaignLeadsTable from 'features/campaign-leads/components/campaign-leads-table'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

const LeadsFromCampaign = () => {
  const { campaignId } = useParams()

  const { data } = useQuery(['campaign', campaignId], () => Api.get(`campaigns/${campaignId}`))

  return (
    <>
      <MetaData title={data?.name} />

      <PageHeader title={data?.name} />

      <CampaignLeadsTable type="inside_campaign" />
    </>
  )
}

export default LeadsFromCampaign
