import { AbsoluteCenter, Box, Button, ButtonGroup, Divider } from '@chakra-ui/react'
import { useSignal } from 'context/signal-context'
import { useSignalLeadsProps } from 'context/signal-leads-context'
import stringify from 'utils/stringify'

const ModalToggleButtons = ({ value, setValue }) => {
  const { stageCount } = useSignal()
  const { stage, inReview, inSingleView, selectedItems } = useSignalLeadsProps()

  const amountSelected = inReview && inSingleView ? 1 : selectedItems.length

  return (
    <Box position="relative" w="100%" py={5}>
      <Divider />
      <AbsoluteCenter bg="white">
        <ButtonGroup spacing={0}>
          <Button
            variant="outline"
            bg={value === 'selected' ? 'gray.100' : 'none'}
            borderRadius="8px 0 0 8px"
            onClick={() => setValue('selected')}
          >
            Selected ({stringify(amountSelected)})
          </Button>

          <Button
            variant="outline"
            bg={value === 'all' ? 'gray.100' : 'none'}
            borderRadius="0 8px 8px 0"
            borderLeft="none"
            onClick={() => setValue('all')}
          >
            All ({stringify(stageCount[stage])})
          </Button>
        </ButtonGroup>
      </AbsoluteCenter>
    </Box>
  )
}

export default ModalToggleButtons
