import QueryBuilderFilterModal from 'components/query-builder/components/query-builder-filter-modal'
import SelectMenu from 'components/select-menu'
import { useColumnFields } from 'context/column-fields-context'
import { useSignalLeadsProps } from 'context/signal-leads-context'
import { useCallback, useState } from 'react'

const filterOptions = [
  { title: 'None', value: 'none' },
  { value: 'Divider' },
  { title: 'High Score', value: 'high_score' },
  { title: 'Low Score', value: 'low_score' },
  { value: 'Divider' },
  { title: 'Has Email', value: 'has_email' },
  { value: 'Divider' },
  { title: 'Custom', value: 'custom' },
]

const FiltersDropdown = () => {
  const [filterModalOpen, setFilterModalOpen] = useState(false)

  const filterFields = useColumnFields('signal-leads')
  const scoreField = filterFields?.signal?.find((val) => val.title === 'Score')
  const emailField = filterFields?.email?.find((val) => val.title === 'Email')

  const { queryBuilder } = useSignalLeadsProps()
  const { query, addFilter, updateQuery } = queryBuilder ?? {}

  const selectFilter = (filter) => {
    addFilter(filter)
    setFilterModalOpen(false)
  }

  const handleSelect = useCallback(
    (option) => {
      if (option.value === 'none' && query?.base.length > 0) {
        return updateQuery({ base: [] })
      }
      if (option.value === 'high_score') {
        return updateQuery({
          base: [{ ...scoreField, action: 'greater_than', value: '4' }],
        })
      }
      if (option.value === 'low_score') {
        return updateQuery({
          base: [{ ...scoreField, action: 'less_than', value: '3' }],
        })
      }
      if (option.value === 'has_email') {
        return updateQuery({
          base: [{ ...emailField, action: 'exists' }],
        })
      }
      if (option.value === 'custom') {
        updateQuery({ base: [] })
        return setFilterModalOpen(true)
      }
    },
    [queryBuilder],
  )

  return (
    <>
      <SelectMenu
        label="Filters"
        options={filterOptions}
        handleSelect={handleSelect}
        keepLabel
        listWidth={160}
      />

      {filterModalOpen && (
        <QueryBuilderFilterModal
          isOpen={filterModalOpen}
          onClose={() => setFilterModalOpen(false)}
          filterFields={filterFields}
          selectFilter={selectFilter}
        />
      )}
    </>
  )
}

export default FiltersDropdown
