import ClosableTag from './closable-tag'

const RenderTags = ({ filter, traitIndex, fieldIndex, removeFilterValue }) => {
  if (!filter.value?.length) {
    return null
  }

  if (Array.isArray(filter.value)) {
    return filter.value?.map((value, index) => (
      <ClosableTag
        key={index}
        fw={600}
        onClick={() => removeFilterValue(filter, value, traitIndex, fieldIndex)}
        isExclusion={filter.action === 'excludes'}
      >
        {value}
      </ClosableTag>
    ))
  }

  return (
    <ClosableTag
      fw={600}
      onClick={() => removeFilterValue(filter, filter.value, traitIndex, fieldIndex)}
      isExclusion={filter.action === 'excludes'}
    >
      {filter.value}
    </ClosableTag>
  )
}

export default RenderTags
