import { FormControl, Input } from '@chakra-ui/react'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import StandardButton from 'components/buttons/standard-button'
import ModalLayout from 'components/layouts/modal-layout'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import useNotification from 'hooks/use-notification'
import { useState } from 'react'
import { useMutation } from 'react-query'
import Api from 'utils/api'

const CreateOrgButtonModal = () => {
  const [name, setName] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = () => setIsOpen(false)

  const invalidate = useInvalidateQuery()
  const { showSuccessMessage } = useNotification()

  const { mutate: createOrganization, isLoading } = useMutation(() => Api.post('organizations', { name }), {
    onSuccess: () => {
      showSuccessMessage('Organization created successfully')
      invalidate('organizations')
      setName('')
      closeModal()
    },
  })

  const onSubmit = (e) => {
    e.preventDefault()
    createOrganization()
  }

  return (
    <>
      <StandardButton onClick={() => setIsOpen(true)} icon={faPlus}>
        New Organization
      </StandardButton>

      {isOpen && (
        <ModalLayout header="New Organization" isOpen={isOpen} onClose={closeModal}>
          <form onSubmit={onSubmit}>
            <FormControl isRequired>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Organization Name"
                aria-label="Organization Name Input"
                autoFocus
              />
            </FormControl>

            <StandardButton mt={6} type="submit" isLoading={isLoading} w="100%">
              Create Organization
            </StandardButton>
          </form>
        </ModalLayout>
      )}
    </>
  )
}

export default CreateOrgButtonModal
