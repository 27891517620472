import SubPageLayout from 'components/layouts/sub-page-layout'
import WhiteSurface from 'components/white-surface'
import CreateBlankSignalForm from '../components/create-blank-signal-form'

const BlankSignal = () => {
  return (
    <SubPageLayout
      title="Create New Signal"
      description="Name your Signal and select the query type. You can always change the query type later."
    >
      <WhiteSurface p={4} minW={{ base: '100', md: 557 }}>
        <CreateBlankSignalForm />
      </WhiteSurface>
    </SubPageLayout>
  )
}

export default BlankSignal
