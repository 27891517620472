import { Flex, HStack, Heading, Stack, Text } from '@chakra-ui/react'
import CustomLink from 'components/links/custom-link'
import Logo from 'components/logo'
import WhiteSurface from 'components/white-surface'

const AuthPageLayout = ({ children, title, subHeader, onSubmit }) => {
  return (
    <Flex minH="100dvh" align="center" justify="center" direction="column">
      <Stack spacing={3} mb={8} align="center">
        <Logo width={78} height={70} />
        <Heading as="h1" fontSize={30} fontWeight={600} lineHeight="38px">
          {title}
        </Heading>

        {subHeader && (
          <HStack spacing="1" justify="center">
            <Text color="muted">{subHeader.cta}</Text>
            <CustomLink path={subHeader.path} text={subHeader.linkText} />
          </HStack>
        )}
      </Stack>

      <WhiteSurface br={12} p={{ base: 6, sm: 8 }} w={{ base: '90%', sm: 448 }}>
        <form onSubmit={onSubmit}>
          <Stack gap={5}>{children}</Stack>
        </form>
      </WhiteSurface>
    </Flex>
  )
}

export default AuthPageLayout
