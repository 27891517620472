import { Flex, Grid, GridItem } from '@chakra-ui/react'
import LoadingSpinner from 'components/loading/loading-spinner'
import MetaData from 'components/meta-data'
import PageHeader from 'components/page-header'
import { SignalProvider, useSignal } from 'context/signal-context'
import RefreshButton from 'features/signal/components/refresh-button'
import { Outlet } from 'react-router-dom'
import AddLeadsButtonModal from './components/add-leads-button-modal'
import ImportDomainsButtonModal from './components/import-domains-button-modal'
import ProccessingCountBox from './components/processing-count-box'
import SideNavigation from './components/side-navigation'

const SignalPageLayout = () => (
  <SignalProvider>
    <SignalPageView />
  </SignalProvider>
)

const SignalPageView = () => {
  const { signal, isLoading } = useSignal()

  const isImportDomains = signal?.query_type === 'import_domains'

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <MetaData title={`${signal?.name} | Signal`} />

      <PageHeader title={signal?.name} description={signal?.description} />

      <Flex position={{ base: 'relative', md: 'absolute' }} top={0} right={1} gap={4}>
        <ProccessingCountBox />
        <RefreshButton bg="transparent" borderColor="gray.300" />
        {isImportDomains ? <ImportDomainsButtonModal /> : <AddLeadsButtonModal />}
      </Flex>

      <Grid gap={4} gridTemplateColumns={{ base: '1f', md: '235px 1fr' }}>
        <GridItem>
          <SideNavigation />
        </GridItem>
        <GridItem px={1} pb={1} overflow="auto">
          <Outlet />
        </GridItem>
      </Grid>
    </>
  )
}

export default SignalPageLayout
