import { FormControl, Input } from '@chakra-ui/react'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import StandardButton from 'components/buttons/standard-button'
import ModalLayout from 'components/layouts/modal-layout'
import { useAuth } from 'context/auth-context'
import useNotification from 'hooks/use-notification'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Api from 'utils/api'

const CreateAccountButtonModal = () => {
  const { organizationId } = useAuth()
  const { organizationId: orgIdFromParams } = useParams()

  const [name, setName] = useState('')
  const [isOpen, setIsOpen] = useState(false)

  const closeModal = () => setIsOpen(false)

  const navigate = useNavigate()
  const { showSuccessMessage } = useNotification()

  const { mutate: createAccount, isLoading } = useMutation(
    () =>
      Api.post('accounts', {
        name,
        organization_id: orgIdFromParams ?? organizationId,
      }),
    {
      onSuccess: (data) => {
        showSuccessMessage('Account created successfully')
        navigate(`/account/${data?.id}/signals/overview`)
      },
    },
  )

  const onSubmit = (e) => {
    e.preventDefault()
    createAccount()
  }

  return (
    <>
      <StandardButton onClick={() => setIsOpen(true)} icon={faPlus}>
        New Account
      </StandardButton>

      {isOpen && (
        <ModalLayout header="New Account" isOpen={isOpen} onClose={closeModal}>
          <form onSubmit={onSubmit}>
            <FormControl isRequired>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Account Name"
                aria-label="Account Name Input"
                autoFocus
              />
            </FormControl>

            <StandardButton mt={6} type="submit" isLoading={isLoading} w="100%">
              Create Account
            </StandardButton>
          </form>
        </ModalLayout>
      )}
    </>
  )
}

export default CreateAccountButtonModal
