import {
  Box,
  Flex,
  FormLabel,
  Grid,
  HStack,
  Heading,
  Input,
  Link,
  Stack,
  Td,
  Text,
  Tr,
} from '@chakra-ui/react'
import {
  faEye,
  faFire,
  faPaperPlane,
  faPlus,
  faPowerOff,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import OutlinedButton from 'components/buttons/outlined-button'
import GridBox from 'components/grid-box'
import TableLayout from 'components/layouts/table-layout'
import LoadingSpinner from 'components/loading/loading-spinner'
import MetaData from 'components/meta-data'
import SelectMenu from 'components/select-menu'
import StatusDot from 'components/status-dot'
import WhiteSurface from 'components/white-surface'
import { useAccount } from 'context/account-context'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import useNotification from 'hooks/use-notification'
import { useCallback, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import capitalize from 'utils/capitalize'
import { getDiffDays } from 'utils/date'
import uid from 'utils/uid'
import ConnectButton from '../components/inbox-connect-button'
import useUpdateInboxSettings from '../hooks/use-update-inbox-settings'

const testsColumns = [
  { id: uid(), title: 'Report' },
  { id: uid(), title: 'Score' },
  { id: uid(), title: 'Subject' },
]

const modeOptions = [
  { title: 'Outreach', value: 'outreach', icon: faPaperPlane, color: '#959AA4' },
  { title: 'Warm', value: 'warm', icon: faFire, color: '#F8C73D' },
  { title: 'Monitor', value: 'monitor', icon: faTriangleExclamation, color: '#F8C73D' },
]

const InboxDetails = () => {
  const [mode, setMode] = useState()
  const [user, setUser] = useState()
  const [userOptions, setUserOptions] = useState([])

  const invalidate = useInvalidateQuery()
  const { accountId, inboxId } = useParams()
  const { showSuccessMessage } = useNotification()
  const { updateInbox } = useUpdateInboxSettings()

  const { data: inboxData, isLoading } = useQuery(
    ['inbox-detail', inboxId],
    async () => await Api.get(`inboxes/${inboxId}`),
    { onSuccess: (data) => setMode(modeOptions?.find(({ value }) => value === data?.mode)) },
  )

  useQuery(['users', inboxData], () => Api.get('users', { account_id: accountId, is_outreach: true }), {
    enabled: !!inboxData,
    onSuccess: (users) => {
      const userSelectOptions = users.results.map((user) => ({
        userId: user?.id,
        title: user?.fname,
        profile_image: user?.profile_image,
      }))
      setUserOptions(userSelectOptions)
      setUser(userSelectOptions?.find(({ userId }) => userId === inboxData?.user_id))
    },
  })

  const { data: glockTests, isLoading: testsLoading } = useQuery(
    ['deliverability-tests', inboxId],
    async () => await Api.get('deliverability-tests', { inbox_id: inboxId, limit: 6 }),
  )

  const { mutate: createGlockTest, isLoading: isGlocking } = useMutation(
    () =>
      Api.post('inbox-deliverability-test', {
        inbox_id: inboxId,
        subject: 'just saying hello',
        body: 'Hi im loooking for help today',
        type: 'text',
      }),
    {
      onSuccess: () => {
        showSuccessMessage('New Glock Test created successfully')
        invalidate('deliverability-tests')
      },
    },
  )

  const { mutate: disconnectInbox, isLoading: isDisconnecting } = useMutation(
    () => Api.post('mail/disconnect-nylas', { inbox_id: inboxId }),
    {
      onSuccess: () => {
        showSuccessMessage('Inbox disconnected successfully')
        invalidate('inbox-detail')
      },
    },
  )

  const selectInboxMode = useCallback(
    (item) => {
      if (item.value === inboxData?.mode) return
      setMode(item)
      updateInbox({ mode: item.value })
    },
    [inboxData],
  )

  const selectInboxUser = useCallback(
    (item) => {
      if (item.userId === inboxData?.user_id) return
      setUser(item)
      updateInbox({ user_id: item.userId })
    },
    [inboxData],
  )

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <MetaData title={inboxData?.email} />

      <Stack gap="38px">
        <HStack w="100%" justify="space-between">
          <Heading fontSize={30} fontWeight={500} lineHeight="38px">
            {inboxData?.email}
          </Heading>

          <HStack gap={3}>
            <SelectMenu
              selected={user ?? { title: 'Select User' }}
              options={userOptions}
              handleSelect={selectInboxUser}
              type="avatar"
              bg="transparent"
            />

            <SelectMenu
              selected={mode}
              options={modeOptions}
              handleSelect={selectInboxMode}
              type="icon"
              bg="transparent"
            />

            {inboxData.nylas_access_token ? (
              <OutlinedButton
                icon={faPowerOff}
                bg="transparent"
                onClick={disconnectInbox}
                isLoading={isDisconnecting}
              >
                Disconnect
              </OutlinedButton>
            ) : (
              <ConnectButton data={inboxData} />
            )}
          </HStack>
        </HStack>

        <Grid gap="18px" gridTemplateColumns={{ base: '1fr', sm: '1fr 1fr', lg: 'repeat(4, 1fr);' }}>
          <GridBox title="Status">
            <HStack gap={3}>
              <StatusDot status={inboxData?.status} size={6} />
              <Text fontSize={33} fontWeight={600} lineHeight="44px">
                {capitalize(inboxData?.status)}
              </Text>
            </HStack>
          </GridBox>
          <GridBox title="Age (Days)" value={getDiffDays(inboxData?.date_created)} />
          <GridBox title="Open Rates" value={`${inboxData?.statistics?.open_rate ?? 0}%`} />
          <GridBox title="Glock Score" value={`${inboxData?.statistics?.inbox_rate ?? 0}%`} />
          <GridBox title="Reply Rate" value={`${inboxData?.reply_rate ?? 0}%`} />
          <GridBox title="Replied 14" value={`${inboxData?.statistics?.replied_14 ?? 0}`} />
          <GridBox title="Contacted 14" value={`${inboxData?.statistics?.contacted_14 ?? 0}`} />
        </Grid>

        <Grid gap="38px" gridTemplateColumns={{ base: '1fr', sm: '1fr 1fr' }}>
          <Stack gap={10}>
            <PasswordComponent password={inboxData?.password} />

            <MailFlowComponent
              accountId={inboxData?.mailflow_account_id}
              inboxId={inboxData?.mailflow_inbox_id}
              mailFlowLink={inboxData?.mailflow_link}
            />

            <QuickMailComponent
              quickmailId={inboxData?.quickmail_id}
              quickmailLink={inboxData?.quickmail_link}
            />

            <Box>
              <Text fontSize={22} lineHeight="32px" mb={2}>
                Reply.io
              </Text>
              <WhiteSurface br={4} p={4}>
                <OutlinedButton
                  icon={faEye}
                  as={Link}
                  href={inboxData?.reply_link}
                  isDisabled={!inboxData?.reply_link}
                  isExternal
                  w="max-content"
                >
                  View Inbox
                </OutlinedButton>
              </WhiteSurface>
            </Box>

            <Box>
              <Text fontSize={22} lineHeight="32px" mb={2}>
                Front
              </Text>
              <WhiteSurface br={4} p={4}>
                <OutlinedButton
                  icon={faEye}
                  as={Link}
                  href={inboxData?.front_channel_link}
                  isDisabled={!inboxData?.front_channel_link}
                  isExternal
                  w="max-content"
                >
                  View Channel
                </OutlinedButton>
              </WhiteSurface>
            </Box>

            <Box>
              <Text fontSize={22} lineHeight="32px" mb={2}>
                SmartLead
              </Text>
              <WhiteSurface br={4} p={4}>
                <OutlinedButton
                  icon={faEye}
                  as={Link}
                  href={inboxData?.smartlead_link}
                  isDisabled={!inboxData?.smartlead_id}
                  isExternal
                  w="max-content"
                >
                  View Inbox
                </OutlinedButton>
              </WhiteSurface>
            </Box>

            <Box>
              <Text fontSize={22} lineHeight="32px" mb={2}>
                Quickmail
              </Text>
              <WhiteSurface br={4} p={4}>
                <Text color="muted" fontSize={14}>
                  Not Connected
                </Text>
              </WhiteSurface>
            </Box>
          </Stack>

          <Stack gap={10}>
            <Box>
              <Text fontSize={22} lineHeight="32px" mb={2}>
                Glock Tests
              </Text>
              <TableLayout
                columns={testsColumns}
                isLoading={testsLoading}
                br={4}
                header={
                  <OutlinedButton icon={faPlus} isLoading={isGlocking} onClick={createGlockTest}>
                    New Test
                  </OutlinedButton>
                }
              >
                {glockTests?.results?.length === 0 ? (
                  <Tr>
                    <Td>
                      <Text color="muted">No results found...</Text>
                    </Td>
                  </Tr>
                ) : (
                  glockTests?.results?.map((test) => (
                    <Tr key={test?.id}>
                      <Td>
                        <Link
                          href={test.glock_link}
                          color="#3182CE"
                          isExternal
                          textDecoration="underline"
                          textUnderlineOffset={3}
                        >
                          {new Date(test.date_created).toLocaleDateString('en-US', {
                            day: '2-digit',
                            month: 'long',
                            year: 'numeric',
                          })}
                        </Link>
                      </Td>
                      <Td>
                        <Text color="muted">{`${test?.inbox_rate ?? 0}%`}</Text>
                      </Td>
                      <Td>
                        <Text color="muted">{test.subject}</Text>
                      </Td>
                    </Tr>
                  ))
                )}
              </TableLayout>
            </Box>

            <CustomEmailForm isImap={false} data={inboxData} />
            <CustomEmailForm isImap={true} data={inboxData} />
          </Stack>
        </Grid>
      </Stack>
    </>
  )
}

const MailFlowComponent = ({ accountId, inboxId, mailFlowLink }) => {
  const [account, setAccount] = useState(accountId ?? '')
  const [inbox, setInbox] = useState(inboxId ?? '')

  const { updateInbox, isLoading } = useUpdateInboxSettings()

  const onSubmit = (e) => {
    e.preventDefault()
    updateInbox({ mailflow_account_id: account, mailflow_inbox_id: inbox })
  }

  return (
    <Box>
      <Text fontSize={22} lineHeight="32px" mb={2}>
        Mail Flow
      </Text>
      <WhiteSurface br={4} p={4}>
        <form onSubmit={onSubmit}>
          <Flex gap={3}>
            <Stack gap={0} w="50%">
              <FormLabel>Account ID</FormLabel>
              <Input value={account} onChange={(e) => setAccount(e.target.value)} placeholder="1234" />
            </Stack>
            <Stack gap={0} w="50%">
              <FormLabel>Inbox ID</FormLabel>
              <Input value={inbox} onChange={(e) => setInbox(e.target.value)} placeholder="5678" />
            </Stack>
          </Flex>

          <HStack w="100%" justify="space-between">
            <OutlinedButton mt={4} type="submit" isLoading={isLoading}>
              Save
            </OutlinedButton>
            <OutlinedButton
              mt={4}
              icon={faEye}
              as={Link}
              href={mailFlowLink}
              isDisabled={!mailFlowLink}
              isExternal
            >
              View Results
            </OutlinedButton>
          </HStack>
        </form>
      </WhiteSurface>
    </Box>
  )
}

const QuickMailComponent = ({ quickmailId }) => {
  const { account } = useAccount()
  const [quickmail, setQuickmail] = useState(quickmailId ?? '')

  const { updateInbox, isLoading } = useUpdateInboxSettings()

  let quickmailLink =
    account?.quickmail_account_id && quickmailId
      ? `https://next.quickmail.com/account/${account.quickmail_account_id}/settings/inboxes/${quickmailId}/general`
      : null

  const onSubmit = (e) => {
    e.preventDefault()
    updateInbox({ quickmail_id: quickmail })
  }

  return (
    <Box>
      <Text fontSize={22} lineHeight="32px" mb={2}>
        QuickMail
      </Text>
      <WhiteSurface br={4} p={4}>
        <form onSubmit={onSubmit}>
          <Stack gap={0} w="100%">
            <FormLabel>QuickMail Inbox ID</FormLabel>
            <Input value={quickmail} onChange={(e) => setQuickmail(e.target.value)} placeholder="1234" />
          </Stack>
          <HStack w="100%" justify="space-between">
            <OutlinedButton mt={4} type="submit" isLoading={isLoading}>
              Save
            </OutlinedButton>
            {quickmailLink}
            <OutlinedButton
              mt={4}
              icon={faEye}
              as={Link}
              href={quickmailLink}
              isDisabled={!quickmailLink || !account.quickmail_account_id}
              isExternal
            >
              View Results
            </OutlinedButton>
          </HStack>
        </form>
      </WhiteSurface>
    </Box>
  )
}

const PasswordComponent = ({ password }) => {
  const [newPassword, setNewPassword] = useState(password ?? '')

  const { updateInbox, isLoading } = useUpdateInboxSettings()

  const onSubmit = (e) => {
    e.preventDefault()
    updateInbox({ password: newPassword })
  }

  return (
    <Box>
      <Text fontSize={22} lineHeight="32px" mb={2}>
        Password
      </Text>
      <WhiteSurface br={4} p={4}>
        <form onSubmit={onSubmit}>
          <FormLabel>Inbox Password</FormLabel>
          <Input
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="password1234"
          />

          <OutlinedButton mt={4} type="submit" isLoading={isLoading}>
            Update
          </OutlinedButton>
        </form>
      </WhiteSurface>
    </Box>
  )
}

const CustomEmailForm = ({ isImap, data }) => {
  if (data.type !== 'custom') return null

  const hostKey = isImap ? 'imap_host' : 'smtp_host'
  const usernameKey = isImap ? 'imap_username' : 'smtp_username'
  const passwordKey = isImap ? 'imap_password' : 'smtp_password'
  const portKey = isImap ? 'imap_port' : 'smtp_port'

  const [state, setState] = useState({
    host: data?.[hostKey] ?? '',
    username: data?.[usernameKey] ?? '',
    password: data?.[passwordKey] ?? '',
    port: data?.[portKey] ?? '',
  })

  const { updateInbox, isLoading } = useUpdateInboxSettings()

  const onChange = (e) => setState({ ...state, [e.target.name]: e.target.value })

  const onSubmit = (e) => {
    e.preventDefault()
    updateInbox({
      [hostKey]: state.host,
      [usernameKey]: state.username,
      [passwordKey]: state.password,
      [portKey]: Number(state.port),
    })
  }

  return (
    <Box>
      <Text fontSize={22} lineHeight="32px" mb={2}>
        {isImap ? 'IMAP (Incoming)' : 'SMTP (Outgoing)'}
      </Text>
      <WhiteSurface br={4} p={4}>
        <form onSubmit={onSubmit}>
          <FormLabel>Host</FormLabel>
          <Input name="host" value={state.host} onChange={onChange} mb={2} />

          <FormLabel>Username</FormLabel>
          <Input name="username" value={state.username} onChange={onChange} mb={2} />

          <Flex gap={3}>
            <Stack gap={0} w="50%">
              <FormLabel>Password</FormLabel>
              <Input name="password" value={state.password} onChange={onChange} />
            </Stack>
            <Stack gap={0} w="50%">
              <FormLabel>Port</FormLabel>
              <Input name="port" value={state.port} onChange={onChange} type="number" />
            </Stack>
          </Flex>

          <OutlinedButton mt={4} type="submit" isLoading={isLoading}>
            Update
          </OutlinedButton>
        </form>
      </WhiteSurface>
    </Box>
  )
}

export default InboxDetails
