import { Box, Flex, Text } from '@chakra-ui/react'
import stringify from 'utils/stringify'

const background = {
  green: '#51BA72',
  yellow: '#F8C73DE5',
  gray: '#ADADADE5',
  red: '#EA6565E5',
  blue: '#337ab7',
}

const ColorBox = ({ color, value, ...props }) => (
  <Flex {...props}>
    <Box width="max-content" minW={8} padding="2px 9px" borderRadius={5} bg={background[color]}>
      <Text color="white" fontSize={14} fontWeight={700} textAlign="center">
        {stringify(value)}
      </Text>
    </Box>
  </Flex>
)

export default ColorBox
