import { useSignal } from 'context/signal-context'
import useUpdateSignal from 'features/signal/hooks/use-update-signal'
import { useCallback, useEffect, useState } from 'react'
import Storage from 'utils/storage'
import uid from 'utils/uid'
import { isNumberType } from '../utils'

const useHandleBuildQuery = ({ type, shouldNotSave }) => {
  const { signal } = useSignal() ?? {}

  const { updateSignal } = useUpdateSignal()

  const [query, setQuery] = useState(() => Storage.get(`query-builder-${type}`) ?? { base: [] })

  const [triggerSearch, setTriggerSearch] = useState(false)

  useEffect(() => {
    if (shouldNotSave) return
    if (signal) {
      setQuery({
        base: signal?.[type]?.base ?? [],
        traits: signal?.[type]?.traits ?? [],
      })
    }
  }, [signal, type])

  const updateQuery = (query) => {
    setQuery(query)
    if (shouldNotSave) return
    if (signal) {
      return updateSignal({ [type]: query })
    }
    Storage.set(`query-builder-${type}`, query)
  }

  const handleBaseOrTrait = (callback, traitIndex) => {
    if (traitIndex != null) {
      return {
        ...query,
        traits: query.traits.map((trait, i) => (i === traitIndex ? callback(trait) : trait)),
      }
    } else {
      return {
        ...query,
        base: callback(query.base),
      }
    }
  }

  const mapBaseOrTrait = (callback, traitIndex, fieldIndex) =>
    handleBaseOrTrait(
      (list) => list?.map((item, idx) => (idx === fieldIndex ? callback(item) : item)),
      traitIndex,
    )

  const addFilter = useCallback(
    (filter, traitIndex) => {
      const isNumber = isNumberType(filter.type)
      const newFilter = {
        ...filter,
        id: uid(),
        resource: filter.resource,
        action: isNumber ? 'greater_than' : 'includes_any',
        value: filter?.value || (isNumber ? '' : []),
      }
      let newQuery
      if (traitIndex === 'newTrait') {
        newQuery = { ...query, traits: [...query.traits, [newFilter]] }
      } else {
        newQuery = handleBaseOrTrait((list) => [...list, newFilter], traitIndex)
      }
      updateQuery(newQuery)
    },
    [query],
  )

  const removeFilter = useCallback(
    (traitIndex, fieldIndex) => {
      const newQuery = handleBaseOrTrait((list) => list.filter((_, idx) => idx !== fieldIndex), traitIndex)
      updateQuery({
        ...newQuery,
        traits: newQuery?.traits?.filter((trait) => trait.length > 0),
      })
      if (query.base[fieldIndex].value.length > 0) {
        setTriggerSearch((val) => !val)
      }
    },
    [query],
  )

  const addFilterValue = useCallback(
    (filter, value, traitIndex, fieldIndex) => {
      if (filter.value.includes(value)) return
      const isNumber = isNumberType(filter.type)
      updateQuery(
        mapBaseOrTrait(
          (query) => ({
            ...query,
            value: isNumber ? value : [...query.value, value],
          }),
          traitIndex,
          fieldIndex,
        ),
      )
      setTriggerSearch((val) => !val)
    },
    [query],
  )

  const removeFilterValue = useCallback(
    (filter, valueToRemove, traitIndex, fieldIndex) => {
      const isNumber = isNumberType(filter.type)
      updateQuery(
        mapBaseOrTrait(
          (query) => ({
            ...query,
            value: isNumber ? '' : query.value.filter((v) => v !== valueToRemove),
          }),
          traitIndex,
          fieldIndex,
        ),
      )
      setTriggerSearch((val) => !val)
    },
    [query],
  )

  const selectAction = useCallback(
    (action, traitIndex, fieldIndex) => {
      updateQuery(mapBaseOrTrait((query) => ({ ...query, action: action.value }), traitIndex, fieldIndex))
      setTriggerSearch((val) => !val)
    },
    [query],
  )

  const addKeywordValue = (e, filter, value, traitIndex, fieldIndex) => {
    e.preventDefault()
    value && addFilterValue(filter, value.toLowerCase(), traitIndex, fieldIndex)
  }

  return {
    query,
    addFilter,
    removeFilter,
    selectAction,
    addFilterValue,
    removeFilterValue,
    addKeywordValue,
    updateQuery,
    triggerSearch,
  }
}

export default useHandleBuildQuery
