import { Stack, Text } from '@chakra-ui/react'
import OutlinedButton from 'components/buttons/outlined-button'
import WhiteSurface from 'components/white-surface'
import useNotification from 'hooks/use-notification'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Api from 'utils/api'

const CreateTemplate = () => {
  const navigate = useNavigate()
  const { signalId } = useParams()
  const { showSuccessMessage } = useNotification()

  const { mutate: createTemplate, isLoading } = useMutation(
    () => Api.post('signal/template-create', { signal_id: signalId }),
    {
      onSuccess: () => {
        showSuccessMessage('Template created successfully')
        navigate(`/templates`)
      },
    },
  )

  const onSubmit = (e) => {
    e.preventDefault()
    createTemplate()
  }

  return (
    <Stack spacing={3}>
      <Text fontSize={22} lineHeight="32px">
        Create Template
      </Text>
      <WhiteSurface br={4} p={4}>
        <Text fontSize={14} color="gray.600">
          Create a new template from this signal&apos;s configuration and queries.
        </Text>
        <OutlinedButton mt={4} onClick={onSubmit} isLoading={isLoading} w="max-content">
          Create Template
        </OutlinedButton>
      </WhiteSurface>
    </Stack>
  )
}

export default CreateTemplate
