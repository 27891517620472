import { Avatar, Grid, HStack, Heading, Icon, Td, Text, Tr } from '@chakra-ui/react'
import { faCheck, faClose, faPencil } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OutlinedButton from 'components/buttons/outlined-button'
import GridBox from 'components/grid-box'
import TableLayout from 'components/layouts/table-layout'
import LoadingSpinner from 'components/loading/loading-spinner'
import MetaData from 'components/meta-data'
import { useAccount } from 'context/account-context'
import DomainAuthIcon from 'features/domains/components/domain-auth-icons'
import InboxesTable from 'features/inboxes/components/inboxes-table'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import capitalize from 'utils/capitalize'
import stringify from 'utils/stringify'
import uid from 'utils/uid'
import DomainDelete from './domain-delete'

const inboxesColumns = [
  { id: uid(), title: 'Inbox' },
  { id: uid(), title: 'Mode' },
  { id: uid(), title: 'Connections' },
  { id: uid(), title: 'Age' },
  { id: uid(), title: 'Reply Rate' },
  { id: uid(), title: 'Open Rate' },
  { id: uid(), title: 'Glock Score' },
]

const dnsColumns = [
  { id: uid(), title: 'Type' },
  { id: uid(), title: 'Name' },
  { id: uid(), title: 'Value' },
]

const googleDomainUsersColumns = [
  { id: uid(), title: 'Email' },
  { id: uid(), title: 'Full Name' },
  { id: uid(), title: 'Mailbox Setup' },
  { id: uid(), title: '2 Factor' },
  { id: uid(), title: 'Suspended' },
  { id: uid(), title: 'Archived' },
  { id: uid(), title: 'Creation Time' },
]

const DomainDetails = () => {
  const { domainId } = useParams()
  const { accountId } = useParams()

  const { account } = useAccount()

  useQuery(['domain-sync', domainId], async () => {
    await Api.post('inbox-domains-sync', { id: domainId })
  })

  const { data, isLoading } = useQuery(
    ['inbox-domain-detail', domainId],
    async () => await Api.get(`inbox-domains/${domainId}`),
  )

  const { data: googleDomainUsers, isLoading: googleLoading } = useQuery(
    ['google-domain-users', domainId],
    async () =>
      await Api.get('google-directory/fetch-domain-users', {
        account_id: accountId,
        domain: data?.domain,
      }),
    {
      enabled: !!account?.google_directory_access_token && !!data && data.mx_provider === 'google',
      cacheTime: 0,
    },
  )

  if (isLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <MetaData title={data?.domain} />

      <Heading fontSize={30} fontWeight={500} lineHeight="38px">
        {data?.domain}
      </Heading>

      <Grid gap="18px" my="38px" gridTemplateColumns={{ base: '1fr', sm: '1fr 1fr', lg: 'repeat(4, 1fr);' }}>
        <GridBox title="SPF / DMARC / DKIM">
          <HStack gap={1}>
            <DomainAuthIcon value={data?.spf_valid} size={10} />
            <DomainAuthIcon value={data?.dmarc_valid} size={10} />
            <DomainAuthIcon value={data?.dkim_valid} size={10} />
          </HStack>
        </GridBox>
        <GridBox title="MX Provider" value={capitalize(data?.mx_provider)} />

        <GridBox title="Age (Days)" value={stringify(data?.age)} />
        <GridBox title="Category" value={data?.category} />
        <GridBox title="Reply Rate" value={`${data?.reply_rate ?? 0}%`} />
        <GridBox title="Open Rate" value={`${data?.open_rate ?? 0}%`} />
        <GridBox title="Glock Score" value={`${data?.glock_score ?? 0}%`} />
        <GridBox title="Nameservers" value={''} />
      </Grid>

      <Text fontSize={22} lineHeight="32px" mb={2}>
        Inboxes
      </Text>
      <InboxesTable data={data?.inboxes} columns={inboxesColumns} mb="38px" />

      <Text fontSize={22} lineHeight="32px" mb={2}>
        DNS Records
      </Text>
      <TableLayout
        mb="38px"
        columns={dnsColumns}
        header={
          <OutlinedButton icon={faPencil} isDisabled>
            Edit
          </OutlinedButton>
        }
      >
        {data?.dns_records?.length === 0 ? (
          <Tr>
            <Td>
              <Text color="muted">No results found...</Text>
            </Td>
          </Tr>
        ) : (
          data?.dns_records?.map((data, idx) => (
            <Tr key={idx}>
              <Td>
                <Text color="muted">{data?.type}</Text>
              </Td>
              <Td>
                <Text color="muted">{data?.name}</Text>
              </Td>
              <Td maxW={200}>
                <Text color="muted" whiteSpace="pre-line">
                  {data?.value}
                </Text>
              </Td>
            </Tr>
          ))
        )}
      </TableLayout>

      {data?.mx_provider === 'google' && (
        <>
          <Text fontSize={22} lineHeight="32px" mb={2}>
            Google Users
          </Text>
          <TableLayout mb="38px" columns={googleDomainUsersColumns} isLoading={googleLoading}>
            {googleDomainUsers?.results?.length === 0 ? (
              <Tr>
                <Td>
                  <Text color="muted">No results found...</Text>
                </Td>
              </Tr>
            ) : (
              googleDomainUsers?.results.map((user, idx) => (
                <Tr key={idx}>
                  <Td>
                    <HStack align="center">
                      <Avatar src={user.thumbnailPhotoUrl} alt="Profile Image" boxSize="25px" />
                      <Text color="muted">{user.primaryEmail}</Text>
                    </HStack>
                  </Td>
                  <Td>
                    <Text color="muted">{user.fullName}</Text>
                  </Td>
                  <Td>
                    {user.isMailboxSetup && <Icon icon={faCheck} color={'#51BA72'} as={FontAwesomeIcon} />}
                  </Td>
                  <Td>
                    {user.isEnrolledIn2Sv && <Icon icon={faCheck} color={'#51BA72'} as={FontAwesomeIcon} />}
                  </Td>
                  <Td>{user.suspended && <Icon icon={faClose} color={'#F35D52'} as={FontAwesomeIcon} />}</Td>
                  <Td>{user.archived && <Icon icon={faCheck} color={'#F35D52'} as={FontAwesomeIcon} />}</Td>
                  <Td>
                    <Text color="muted">{new Date(user.creationTime).toLocaleString()}</Text>
                  </Td>
                </Tr>
              ))
            )}
          </TableLayout>
        </>
      )}

      <DomainDelete />
    </>
  )
}

export default DomainDetails
