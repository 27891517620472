import PageHeader from 'components/page-header'

const SignalsBlacklist = () => {
  return (
    <>
      <PageHeader title="Blacklist" />
    </>
  )
}

export default SignalsBlacklist
