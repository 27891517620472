import { Box, Grid, HStack, Text, VStack } from '@chakra-ui/react'
import ModalLayout from 'components/layouts/modal-layout'
import LoadingSpinner from 'components/loading/loading-spinner'
import SelectMenu from 'components/select-menu'
import WhiteSurface from 'components/white-surface'
import useChannelOptions from 'hooks/dropdown-filters/use-channel-options'
import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'
import stringify from 'utils/stringify'

const timeRangeOptions = [
  { title: 'All Time', value: null },
  { value: 'Divider' },
  { title: 'Last 7 Days', value: 7 },
  { title: 'Last 30 Days', value: 30 },
  { title: 'Last 3 Months', value: 90 },
  { title: 'Last 6 Months', value: 180 },
  { title: 'Last Year', value: 365 },
]

const displayOptions = [
  { title: 'Count', value: 'count' },
  { title: 'Percentage', value: 'percentage' },
]

const commonMetrics = [
  { label: 'Replied', data: 'replied', rate: 'reply_rate', metric: 'date_replied' },
  { label: 'Warm', data: 'warm', rate: 'warm_rate', metric: 'warm' },
  { label: 'Meetings', data: 'meetings', rate: 'meeting_rate', metric: 'date_meeting' },
]

const graphDisplay = {
  all: [
    { label: 'Sent', data: 'contacted', rate: null, metric: 'date_last_contact' },
    { label: 'New', data: 'new', rate: null, metric: 'date_first_contact' },
    ...commonMetrics,
  ],
  email: [
    { label: 'Sent', data: 'last_email', metric: 'date_last_email' },
    { label: 'First Email', data: 'first_email', metric: 'date_first_email' },
    ...commonMetrics,
  ],
  linkedin: [
    { label: 'Accepted', data: 'accepted', metric: 'date_linkedin_connection_accept' },
    { label: 'Requested', data: 'requested', metric: 'date_linkedin_connection_request' },
    ...commonMetrics,
  ],
}
const AnalyzeCampaignModal = ({ user, onClose }) => {
  const { accountId } = useParams()
  const [timeRange, setTimeRange] = useState(timeRangeOptions[3])
  const [displayOption, setDisplayOption] = useState(displayOptions[0])

  const { channel, channelOptions, selectChannel } = useChannelOptions({})

  const currentGraphDisplay = graphDisplay[channel?.value ?? 'all']

  const performanceEndpoint = channel?.value ?? 'overall'

  const { data: overAllData, isLoading: overallLoading } = useQuery(
    [`campaign-metrics/${performanceEndpoint}-performance`, accountId, timeRange, user],
    () =>
      Api.get(`campaign-metrics/${performanceEndpoint}-performance`, {
        account_id: accountId,
        day_range: timeRange?.value,
        user_id: user?.userId,
      }),
  )

  const selectTimeRange = useCallback((range) => setTimeRange(range), [])
  const selectDisplayOption = useCallback((value) => setDisplayOption(value), [])

  return (
    <ModalLayout header="Analyze" isOpen={true} onClose={onClose} size="6xl">
      <WhiteSurface border>
        <HStack padding="16px 20px" justify="space-between" borderBottomWidth={1}>
          <HStack>
            <SelectMenu
              selected={channel}
              options={channelOptions}
              handleSelect={selectChannel}
              type="icon"
            />
          </HStack>
          <HStack>
            <SelectMenu
              selected={displayOption}
              options={displayOptions}
              handleSelect={selectDisplayOption}
            />
            <SelectMenu selected={timeRange} options={timeRangeOptions} handleSelect={selectTimeRange} />
          </HStack>
        </HStack>

        <HStack padding="16px 20px" h={250}>
          <Grid templateColumns="repeat(5, 1fr)" w="100%" gap="40px">
            {currentGraphDisplay.map((item, idx) => (
              <Box key={idx} h="100%" p={4}>
                <VStack gap={2} w="100%">
                  <Text fontSize={12} lineHeight="20px" color="muted">
                    {item.label}
                  </Text>
                  {overallLoading ? (
                    <LoadingSpinner size="md" m={0} py="11px" px="0 !important" />
                  ) : (
                    <Text fontSize={38} fontWeight={600} lineHeight="46px">
                      {stringify(
                        overAllData?.[
                          displayOption.value === 'percentage' && item.rate ? item.rate : item.data
                        ],
                      )}
                      {displayOption.value === 'percentage' && item.rate && '%'}
                    </Text>
                  )}
                </VStack>
              </Box>
            ))}
          </Grid>
        </HStack>
      </WhiteSurface>
    </ModalLayout>
  )
}

export default AnalyzeCampaignModal
