import PageHeader from 'components/page-header'

const Agencies = () => {
  return (
    <>
      <PageHeader title="Agencies" />
    </>
  )
}

export default Agencies
