import { Divider, Flex, HStack, Icon, Link, Stack, Text } from '@chakra-ui/react'
import { faHubspot, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faUserTie } from '@fortawesome/pro-duotone-svg-icons'
import { faBan, faCheck, faClock, faEnvelope, faLocationDot } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DeclineButton from 'components/buttons/decline-button'
import SelectMenu from 'components/select-menu'
import { useAuth } from 'context/auth-context'
import { format, parseISO } from 'date-fns'
import { useCallback } from 'react'
import { useMutation } from 'react-query'
import { Link as ReactLink } from 'react-router-dom'
import Api from 'utils/api'
import useLeadActions from '../hooks/use-lead-actions'
import { RenderData, RenderLink } from '../lead-single-view'

const leadActions = [
  { title: 'View in Hubspot', value: 'view_in_hubspot', icon: faHubspot, color: '#959AA4' },
  { value: 'Divider' },
  { title: 'Do not Contact', value: 'do_not_contact', icon: faBan, color: '#959AA4' },
]

const SinglePersonData = ({ lead, stage, onClose, isLeadPage = false }) => {
  const { user } = useAuth()
  const showData = lead?.person_id || isLeadPage
  const leadId = lead?.id

  const fullName = isLeadPage ? lead?.full_name : lead?.person?.full_name
  const position = isLeadPage ? lead?.position : lead?.position?.position
  const linkedinLink = isLeadPage ? lead?.person_linkedin : lead?.person?.linkedin_link
  const email = isLeadPage ? lead?.email : lead?.email?.email
  const location = isLeadPage ? lead?.person_location : lead?.person?.location
  const monthsInPosition = isLeadPage ? lead?.months_in_position : lead?.position?.months_in_position
  const dateVerified = isLeadPage ? lead?.date_verified : lead?.position?.date_verified

  const { declineContact, isDecliningContact } = useLeadActions()

  const { mutate: connectToHubspot } = useMutation(
    () => Api.get('hubspot/login-link', { user_id: user.id }),
    { onSuccess: (data) => window.open(data.url, '_blank') },
  )

  if (!showData) return null

  const handleSelectAction = useCallback((option) => {
    if (option.value === 'view_in_hubspot') {
      connectToHubspot()
    }
  }, [])

  return (
    <Stack spacing={7}>
      <Flex justify="space-between">
        <HStack gap={2}>
          <Icon icon={faUserTie} as={FontAwesomeIcon} width={12} height={12} />
          <Stack gap={1}>
            <Link as={ReactLink} fontSize={28} lineHeight="30px">
              {fullName}
            </Link>
            <Text fontSize={18} fontWeight={500} color="muted" lineHeight="20px">
              {position}
            </Text>
          </Stack>
        </HStack>

        {['review', 'complete', 'archived'].includes(stage) && (
          <DeclineButton
            isLoading={isDecliningContact}
            onClick={() => declineContact(leadId, { onSuccess: onClose && onClose() })}
            minW={114}
          >
            Decline
          </DeclineButton>
        )}

        {isLeadPage && (
          <SelectMenu
            label="Actions"
            options={leadActions}
            handleSelect={handleSelectAction}
            type="icon"
            placement="bottom-end"
            keepLabel
          />
        )}
      </Flex>

      <Stack spacing={2}>
        <RenderLink link={linkedinLink} icon={faLinkedin} />
        <RenderData data={stage === 'review' ? 'Approve to Reveal' : email} icon={faEnvelope} />
        <RenderData data={location} icon={faLocationDot} />
        <RenderData data={monthsInPosition} icon={faClock} text={`${monthsInPosition} Months in Position`} />
        {dateVerified && (
          <RenderData
            data={dateVerified}
            icon={faCheck}
            text={`Verified on ${format(parseISO(dateVerified), 'MMM d, yyyy')}`}
          />
        )}
      </Stack>

      {!isLeadPage && <Divider />}
    </Stack>
  )
}

export default SinglePersonData
