import { Box, Button, FormControl, FormLabel, Input } from '@chakra-ui/react'
import { faPlus } from '@fortawesome/pro-regular-svg-icons'
import StandardButton from 'components/buttons/standard-button'
import ModalLayout from 'components/layouts/modal-layout'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import Api from 'utils/api'

const CreateTemplateButtonModal = () => {
  const [IsOpen, setIsOpen] = useState(false)
  const [signalName, setSignalName] = useState('')
  const navigate = useNavigate()

  const { mutateAsync: createSignal, isLoading } = useMutation(
    () => Api.post('signals-create-training', { name: signalName }),
    { onSuccess: (data) => navigate(`/signal/${data?.id}/review`) },
  )

  const onSubmit = (e) => {
    e.preventDefault()
    createSignal()
  }

  return (
    <>
      <StandardButton icon={faPlus} onClick={() => setIsOpen(true)}>
        New Trainign Signal
      </StandardButton>

      {IsOpen && (
        <ModalLayout
          header="Create New Training Signals"
          isOpen={IsOpen}
          onClose={() => setIsOpen(false)}
          width={620}
        >
          <Box borderRadius="lg" as="form" onSubmit={onSubmit}>
            <FormControl isRequired>
              <FormLabel htmlFor="signalName">Signal Name</FormLabel>
              <Input
                id="signalName"
                placeholder="Enter Signal Name"
                value={signalName}
                onChange={(e) => setSignalName(e.target.value)}
                required
              />
            </FormControl>
            <Button mt={4} colorScheme="blue" isLoading={isLoading} type="submit">
              Submit
            </Button>
          </Box>
        </ModalLayout>
      )}
    </>
  )
}

export default CreateTemplateButtonModal
