import { Button, Spinner, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useMutation } from 'react-query'
import Api from 'utils/api'

const ResendTableCell = ({ account }) => {
  const [inviteResent, setInviteResent] = useState(false)

  const { mutate: resendInvite, isLoading } = useMutation(
    () => Api.post('authenticate/resend-invite', { user_id: account?.id }),
    { onSuccess: () => setInviteResent(true) },
  )

  if (isLoading) {
    return <Spinner color="blue.500" size="sm" />
  }

  if (inviteResent) {
    return (
      <Text color="blue.500" fontWeight={600}>
        Sent
      </Text>
    )
  }

  return (
    <Button variant="link" fontSize={14} _hover={{ textDecoration: 'underline' }} onClick={resendInvite}>
      Resend Invite
    </Button>
  )
}

export default ResendTableCell
