import useNotification from 'hooks/use-notification'
import { useMutation } from 'react-query'
import Api from 'utils/api'

const useImportDomains = () => {
  const { showSuccessMessage } = useNotification()

  const { mutateAsync: importDomains, isLoading } = useMutation(
    ({ signal_id, text }) => Api.post('signal/import-domains', { signal_id, text }),
    { onSuccess: () => showSuccessMessage('Domains imported successfully') },
  )

  return { importDomains, isLoading }
}

export default useImportDomains
