import { Flex, Stack, Text } from '@chakra-ui/react'

import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'
import StandardButton from 'components/buttons/standard-button'
import WhiteSurface from 'components/white-surface'
import { useMemo } from 'react'
import AddFilterTraitButton from './components/add-filter-trait-button'
import RenderFilterRow from './components/render-filter-row'
import useHandleBuildQuery from './hooks/use-handle-build-query'

const QueryBuilder = ({
  type,
  isLoading,
  handleSearchQuery,
  filterFields,
  buttonText,
  showSearchButton = true,
}) => {
  const { query, addFilter, removeFilter, selectAction, addFilterValue, removeFilterValue, addKeywordValue } =
    useHandleBuildQuery({ type })

  const isSignal = useMemo(() => type.startsWith('query_'), [type])

  return (
    <WhiteSurface p={5} mb={4} border>
      <Flex direction="column" gap="21px">
        {query?.base?.map((filter, idx) => (
          <RenderFilterRow
            key={idx}
            fieldIndex={idx}
            filter={filter}
            removeFilter={removeFilter}
            addKeywordValue={addKeywordValue}
            addFilterValue={addFilterValue}
            removeFilterValue={removeFilterValue}
            selectAction={selectAction}
          />
        ))}

        {query?.traits?.length > 0 && (
          <AddFilterTraitButton filterFields={filterFields} addFilter={addFilter} />
        )}

        {query?.traits?.map(
          (traits, index) =>
            traits.length > 0 && (
              <Stack key={index} spacing="21px">
                <Flex gap={4} align="center" borderTop="1px" borderColor="gray.200" pt="21px">
                  <Text fontWeight={600} color="gray.400">
                    OR
                  </Text>
                  <Stack>
                    {traits.map((filter, idx) => (
                      <RenderFilterRow
                        key={idx}
                        fieldIndex={idx}
                        traitIndex={index}
                        filter={filter}
                        removeFilter={removeFilter}
                        selectAction={selectAction}
                        addKeywordValue={addKeywordValue}
                        addFilterValue={addFilterValue}
                        removeFilterValue={removeFilterValue}
                      />
                    ))}

                    <AddFilterTraitButton
                      filterFields={filterFields}
                      addFilter={(filter) => addFilter(filter, index)}
                      data-testid="add-filter-trait-button"
                    />
                  </Stack>
                </Flex>
              </Stack>
            ),
        )}

        <Flex gap="10px">
          {showSearchButton && (
            <StandardButton
              icon={faMagnifyingGlass}
              onClick={handleSearchQuery}
              isDisabled={isLoading || (!query?.base?.length && !query?.traits?.length)}
              isLoading={isLoading}
            >
              {buttonText ?? 'Search'}
            </StandardButton>
          )}

          {!query?.traits?.length && (
            <AddFilterTraitButton
              filterFields={filterFields}
              addFilter={addFilter}
              data-testid="add-filter-trait-button"
            />
          )}

          {isSignal && (
            <AddFilterTraitButton
              isTrait
              filterFields={filterFields}
              addFilter={(filter) => addFilter(filter, 'newTrait')}
            />
          )}
        </Flex>
      </Flex>
    </WhiteSurface>
  )
}

export default QueryBuilder
