import { setFilterType } from 'components/query-builder/utils'
import { useCallback, useEffect, useState } from 'react'
import Storage from 'utils/storage'
import uid from 'utils/uid'
import { intialColumns } from '../constants/initial-columns'

const useTableColumns = (type) => {
  const [activeColumns, setActiveColumns] = useState(null)

  useEffect(() => {
    setActiveColumns(Storage.get(`table-columns-${type}`) ?? intialColumns[type])
  }, [type])

  const updateActiveColumns = (columns) => {
    Storage.set(`table-columns-${type}`, columns)
    setActiveColumns(columns)
  }

  const addColumn = useCallback(
    (col) => {
      const columnExist = activeColumns.some(
        ({ title, display, resource }) =>
          title === col.title && display === col.display && (resource ? resource === col.resource : true),
      )

      if (columnExist) return
      updateActiveColumns([
        ...activeColumns,
        {
          id: uid(),
          filterType: setFilterType(col.resource, type),
          ...col,
        },
      ])
    },
    [activeColumns],
  )

  const removeColumn = useCallback(
    (col) => {
      if (col.isFixed) return
      updateActiveColumns(activeColumns.filter(({ id }) => id !== col.id))
    },
    [activeColumns],
  )

  const dragColumn = useCallback(
    ({ destination, source }) => {
      if (!destination) return
      const destinationIndex = destination.index === 0 ? 1 : destination.index
      const resorderedColumns = [...activeColumns]
      const [removed] = resorderedColumns.splice(source.index, 1)
      resorderedColumns.splice(destinationIndex, 0, removed)
      updateActiveColumns(resorderedColumns)
    },
    [activeColumns],
  )

  return { activeColumns, addColumn, removeColumn, dragColumn }
}

export default useTableColumns
