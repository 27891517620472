import { Button, Stack, Text } from '@chakra-ui/react'
import WhiteSurface from 'components/white-surface'
import { useSignal } from 'context/signal-context'
import useNotification from 'hooks/use-notification'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import Api from 'utils/api'
import DeleteSignalModal from './delete-modal'

const DeleteSignal = () => {
  const navigate = useNavigate()
  const { signal } = useSignal()
  const { accountId } = useParams()

  const { showSuccessMessage } = useNotification()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const { mutate: deleteSignal, isLoading } = useMutation(() => Api.delete(`signals/${signal?.id}`), {
    onSuccess: () => {
      showSuccessMessage('Signal deleted')
      navigate(accountId ? `/account/${accountId}/signals/overview` : '/templates')
    },
  })

  const approveChange = () => {
    setDeleteModalOpen(false)
    deleteSignal()
  }

  const dismissChange = () => {
    setDeleteModalOpen(false)
  }

  return (
    <>
      <Stack spacing={3}>
        <Text fontSize={22} lineHeight="32px">
          Delete
        </Text>
        <WhiteSurface br={4} p={4}>
          <Text fontSize={14} color="gray.600">
            Permanently delete this signal and all of it’s leads.
          </Text>
          <Button
            mt={4}
            bg="#EA6565"
            color="white"
            _hover={{ bg: 'red.500' }}
            onClick={() => setDeleteModalOpen(true)}
            isLoading={isLoading}
            w="max-content"
          >
            Delete
          </Button>
        </WhiteSurface>
      </Stack>

      {deleteModalOpen && (
        <DeleteSignalModal
          isOpen={deleteModalOpen}
          dismissChange={dismissChange}
          approveChange={approveChange}
        />
      )}
    </>
  )
}

export default DeleteSignal
