import { Flex, HStack } from '@chakra-ui/react'
import {
  faChartMixed,
  faGear,
  faPaperPlane,
  faSignalStrong,
  faUsers,
} from '@fortawesome/pro-duotone-svg-icons'
import NavLink from 'components/links/nav-link'
import Logo from 'components/logo'
import LogoutComponent from 'components/logout-component'
import WhiteSurface from 'components/white-surface'
import { Link, useParams } from 'react-router-dom'

const NavBar = () => {
  const { accountId } = useParams()

  if (!accountId) return null

  const basePath = `/account/${accountId}`

  const navLinks = [
    {
      label: 'Dashboard',
      icon: faChartMixed,
      path: `${basePath}/dashboard`,
      isActive: (path) => path.includes('dashboard'),
    },
    {
      label: 'Campaigns',
      icon: faPaperPlane,
      path: `${basePath}/campaigns`,
      isActive: (path) => path.includes('campaigns'),
    },
    {
      label: 'Leads',
      icon: faUsers,
      path: `${basePath}/leads/warm`,
      isActive: (path) => path.includes('lead') && !path.includes('campaigns'),
    },
    {
      label: 'Signals',
      icon: faSignalStrong,
      path: `${basePath}/signals/overview`,
      isActive: (path) => path.includes('signal'),
    },
    {
      label: 'Settings',
      icon: faGear,
      path: `${basePath}/settings/user`,
      isActive: (path) => path.includes('settings') && !path.includes('signal'),
    },
  ]

  return (
    <WhiteSurface as="nav" br={0} overflow="auto">
      <Flex justify="space-between" align="center" sx={{ p: '16px 32px' }}>
        <HStack spacing={7}>
          <Link to="/">
            <Logo height={47} width={43} />
          </Link>

          <Flex sx={{ gap: 1 }}>
            {navLinks.map((link, idx) => (
              <NavLink key={idx} {...link}>
                {link.label}
              </NavLink>
            ))}
          </Flex>
        </HStack>
        <LogoutComponent />
      </Flex>
    </WhiteSurface>
  )
}

export default NavBar
