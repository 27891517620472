import { Grid, GridItem, Image } from '@chakra-ui/react'
import ApproveButton from 'components/buttons/approve-button'
import ModalLayout from 'components/layouts/modal-layout'
import LoadingSpinner from 'components/loading/loading-spinner'
import useLeadActions from '../hooks/use-lead-actions'
import useLeadWebsiteData from '../hooks/use-lead-website-data'
import SingleCompanyData from './single-company-data'
import SinglePersonData from './single-person-data'

const LeadDetailsModal = ({ stage, lead, isOpen, onClose }) => {
  const company = lead?.company ?? lead

  const domain = company?.domain

  const { screenshot, screenshotLoading } = useLeadWebsiteData(domain)

  const { approveLeads, isApproving } = useLeadActions()

  return (
    <ModalLayout
      isOpen={isOpen}
      onClose={onClose}
      size={{ lg: '3xl', xl: '6xl' }}
      header={
        stage === 'review' && (
          <ApproveButton
            onClick={() => approveLeads([lead?.id], { onSucess: onClose() })}
            isLoading={isApproving}
          >
            Approve
          </ApproveButton>
        )
      }
    >
      <Grid gap="25px" gridTemplateColumns={{ lg: '1fr', xl: '1.3fr 1fr' }}>
        <GridItem>
          <SingleCompanyData lead={lead} stage={stage} onClose={onClose} />
        </GridItem>

        <GridItem>
          {screenshotLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <SinglePersonData lead={lead} stage={stage} onClose={onClose} />

              <Image
                src={screenshot ?? 'https://placehold.co/600x400@2x.png?text=No+Image+Available'}
                fallbackStrategy="onError"
                alt="Company website screenshot"
              />
            </>
          )}
        </GridItem>
      </Grid>
    </ModalLayout>
  )
}

export default LeadDetailsModal
