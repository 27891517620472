import { Box, Flex, Text } from '@chakra-ui/react'
import QueryBuilder from 'components/query-builder/query-builder-component'
import WhiteSurface from 'components/white-surface'
import { useColumnFields } from 'context/column-fields-context'
import { useSignal } from 'context/signal-context'

const SignalQueryCompany = () => {
  const type = 'query_training'

  const fields = useColumnFields(type)

  const { signal } = useSignal()

  return (
    <Flex direction={{ base: 'column', md: 'row' }} gap={6}>
      <Box flex={1}>
        <QueryBuilder type={type} filterFields={fields} buttonText="Preview" />
      </Box>
      <Box flex={1}>
        <WhiteSurface p={5} mb={4} border>
          {signal.training_report && (
            <Box>
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                Training Report
              </Text>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <Text fontSize="md">Accuracy:</Text>
                    </td>
                    <td>
                      <Text fontSize="md">{signal.training_report.accuracy}</Text>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Text fontSize="md">Precision:</Text>
                    </td>
                    <td>
                      <Text fontSize="md">{signal.training_report.precision}</Text>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          )}
        </WhiteSurface>
      </Box>
    </Flex>
  )
}

export default SignalQueryCompany
