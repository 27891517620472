import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react'
import StandardButton from 'components/buttons/standard-button'
import CustomLink from 'components/links/custom-link'
import useNotification from 'hooks/use-notification'
import { useState } from 'react'
import { useMutation } from 'react-query'
import Api from 'utils/api'
import AuthPageLayout from '../layout/auth-page-layout'

const ResetPassword = () => {
  const [email, setEmail] = useState('')
  const { showSuccessMessage } = useNotification()

  const { mutate, isLoading } = useMutation(() => Api.post('authenticate/reset-password', { email }), {
    onSuccess: () => {
      showSuccessMessage('Instructions to reset your password were sent to your email.')
    },
  })

  const onSubmit = (e) => {
    e.preventDefault()
    mutate()
  }

  return (
    <AuthPageLayout onSubmit={onSubmit} title="Reset password">
      <FormControl id="email" isRequired>
        <FormLabel htmlFor="email">Email</FormLabel>
        <Input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="email@example.com"
        />
      </FormControl>

      <StandardButton type="submit" isLoading={isLoading}>
        Send Email
      </StandardButton>

      <Flex justifyContent="center">
        <CustomLink path="/login" text="Back to login" />
      </Flex>
    </AuthPageLayout>
  )
}

export default ResetPassword
