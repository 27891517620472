import ColumnTable from 'components/column-table/column-table-component'
import Pagination from 'components/pagination'
import useHandleSearchQuery from 'components/query-builder/hooks/use-handle-search-query'
import QueryBuilder from 'components/query-builder/query-builder-component'
import { useColumnFields } from 'context/column-fields-context'

const SignalQueryPeople = () => {
  const type = 'query_persons'

  const fields = useColumnFields(type)

  const { isLoading, searchResults, handleSearchQuery, paginationData } = useHandleSearchQuery({
    type,
    queryType: 'positions',
  })

  return (
    <>
      <QueryBuilder
        type={type}
        isLoading={isLoading}
        handleSearchQuery={handleSearchQuery}
        filterFields={fields}
        buttonText="Preview"
      />

      <ColumnTable
        type={type}
        isLoading={isLoading}
        data={searchResults}
        columnFields={fields}
        header={<Pagination data={paginationData} />}
      />
    </>
  )
}

export default SignalQueryPeople
