import { Stack, Text } from '@chakra-ui/react'
import SelectMenu from 'components/select-menu'
import WhiteSurface from 'components/white-surface'
import { useSignal } from 'context/signal-context'
import useUpdateSignal from 'features/signal/hooks/use-update-signal'
import { useCallback, useEffect, useState } from 'react'

const signalTypeOptions = [
  { title: 'Senior', value: 'senior' },
  { title: 'Custom', value: 'custom' },
  { title: 'CoreSignal Auto', value: 'coresignal_auto' },
  { title: 'Top 50', value: 'top_50' },
]

const ChangeProspectingStrategy = () => {
  const { signal } = useSignal()
  const { updateSignal } = useUpdateSignal()

  const [selectedType, setSelectedType] = useState(null)

  const signalCurrentType = signalTypeOptions?.find(({ value }) => value === signal?.prospecting_strategy)

  useEffect(() => {
    setSelectedType(signalCurrentType)
  }, [signal])

  const handleChangeType = useCallback(
    (item) => {
      if (item.value === signal?.prospecting_strategy) return
      setSelectedType(item)
      updateSignal({ prospecting_strategy: item.value, query_signal: null })
    },
    [signal],
  )

  return (
    <>
      <Stack spacing={3}>
        <Text fontSize={22} lineHeight="32px">
          Prospecting Strategy
        </Text>
        <WhiteSurface br={4} p={4}>
          <Text fontSize={14} color="gray.600">
            Download strategy to download from coresignals
          </Text>

          <SelectMenu
            type="icon"
            selected={selectedType}
            options={signalTypeOptions}
            handleSelect={handleChangeType}
            bg="transparent"
            border="gray.300"
            mt={4}
            w="max-content"
          />
        </WhiteSurface>
      </Stack>
    </>
  )
}

export default ChangeProspectingStrategy
