import { createContext, useContext } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Api from 'utils/api'

const AccountContext = createContext()
export const useAccount = () => useContext(AccountContext)

export const AccountProvider = ({ children }) => {
  const { accountId } = useParams()

  const { data: account } = useQuery(['account', accountId], () => Api.get(`accounts/${accountId}`), {
    enabled: !!Number(accountId),
  })

  const { data: accountUsers } = useQuery(['users', accountId], () =>
    Api.get('users', { account_id: accountId, is_outreach: true, sort: 'date_created', order: 'ASC' }),
  )

  return <AccountContext.Provider value={{ account, accountUsers }}>{children}</AccountContext.Provider>
}
