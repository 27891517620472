import {
  AbsoluteCenter,
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Icon,
  Image,
  Link,
  Text,
} from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ApproveButton from 'components/buttons/approve-button'
import LoadingSpinner from 'components/loading/loading-spinner'
import Pagination from 'components/pagination'
import WhiteSurface from 'components/white-surface'
import { useSignalLeadsProps } from 'context/signal-leads-context'
import stringify from 'utils/stringify'
import FiltersDropdown from './components/filters-dropdown'
import LeadActionsDropdown from './components/lead-actions-dropdown'
import QueryFilterComponent from './components/query-filter-component'
import SingleCompanyData from './components/single-company-data'
import SinglePersonData from './components/single-person-data'
import ToggleViewButtons from './components/toggle-view-buttons'
import useLeadActions from './hooks/use-lead-actions'
import useLeadWebsiteData from './hooks/use-lead-website-data'

const LeadSingleView = () => {
  const { stage, lead, paginationData, queryBuilder } = useSignalLeadsProps()

  const { approveLeads, isApproving } = useLeadActions()

  const domain = lead?.company?.domain

  const { screenshot, screenshotLoading } = useLeadWebsiteData(domain)

  return (
    <WhiteSurface p="16px 20px">
      <Flex justify="space-between">
        <Flex gap={3} align="center">
          <ApproveButton isLoading={isApproving} onClick={() => approveLeads([lead?.id])}>
            Approve
          </ApproveButton>
          <LeadActionsDropdown addQueryFilter={queryBuilder?.addFilter} />
          <FiltersDropdown />
        </Flex>
        <Flex gap={3} align="center">
          <Pagination data={paginationData} onlyButton />
          <ToggleViewButtons />
        </Flex>
      </Flex>

      {queryBuilder?.query?.base.length > 0 && (
        <HStack pt={5} pb={1}>
          <QueryFilterComponent queryBuilder={queryBuilder} filterKey="signal-leads" />
        </HStack>
      )}

      <Box
        position="relative"
        mx={-5}
        my={4}
        w="calc(100% + 40px)"
        py="23px"
        bg="gray.50"
        border="1px"
        borderColor="gray.100"
      >
        <Divider />
        <AbsoluteCenter bg="gray.50" fontSize={12} fontWeight={500} px={4} color="gray.400">
          {paginationData.page + 1} of {stringify(paginationData.totalCount)}{' '}
          {`• Score ${stringify(lead?.score)}`}
        </AbsoluteCenter>
      </Box>

      <Grid gap="25px" gridTemplateColumns={{ lg: '1fr', xl: '1fr 1fr' }}>
        <GridItem>
          <SingleCompanyData lead={lead} stage={stage} />
        </GridItem>

        <GridItem>
          {screenshotLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <SinglePersonData lead={lead} stage={stage} />

              {lead && (
                <Image
                  src={screenshot ?? 'https://placehold.co/600x400@2x.png?text=No+Image+Available'}
                  fallbackStrategy="onError"
                  alt="Company website screenshot"
                />
              )}
            </>
          )}
        </GridItem>
      </Grid>
    </WhiteSurface>
  )
}

export const RenderData = ({ data, icon, text }) => {
  if (!data) return null
  return (
    <HStack>
      <Icon icon={icon} as={FontAwesomeIcon} w={5} h={5} color="gray.500" />
      <Text fontSize={14} color="muted">
        {text ?? data}
      </Text>
    </HStack>
  )
}

export const RenderLink = ({ name, link, icon }) => {
  if (!link) return null
  return (
    <HStack>
      <Icon icon={icon} as={FontAwesomeIcon} w={5} h={5} color="gray.500" />
      <Link href={link} color="#3182CE" isExternal fontSize={14} textDecoration="underline">
        {name ?? link}
      </Link>
    </HStack>
  )
}

export default LeadSingleView
