import { useSignal } from 'context/signal-context'
import useInvalidateQuery from 'hooks/use-invalidate-query'
import { useMutation } from 'react-query'
import Api from 'utils/api'

const useUpdateSignal = () => {
  const { signal } = useSignal() ?? {}
  const invalidate = useInvalidateQuery()

  const { mutate: updateSignal, isLoading } = useMutation(
    (payload) => Api.post(`signals/${signal.id}`, payload),
    { onSuccess: () => invalidate('signal') },
  )

  return { updateSignal, isLoading }
}

export default useUpdateSignal
